/*const Client = [
	{
		id: '63ff5a0c43f82fe93544ec5b',
		label: 'Alex Sedano',
		value: 'Alex_Sedano',
		agrobusiness: [
			{
				id: '63ff55716ada16f93b35b798',
				label: 'Agricola Chicureo',
				value: 'Agricola_Chicureo',
			},
			{
				id: '63ff580843f82fe93544ec52',
				label: 'Agricola Ceresur Ltda',
				value: 'Agricola_Ceresur_Ltda',
			},
		],
	},
	{
		id: '63ff5a0c43f82fe935455555',
		label: 'Garces Fruit',
		value: 'Graces_Fruit',
		agrobusiness: [
			{
				id: '63ff579843f82fe93544ec4c',
				label: 'HGE SPA',
				value: 'HGE_SPA',
			},
			{
				id: '63ff57be43f82fe93544ec4f',
				label: 'Agricola Garces',
				value: 'Agricola_Garces',
			},
		],
	},
];

const Orchards = [
	{
		id: '6516b5cf9e703d215b1d2381',
		cc: [
			{
				label: '2 Kordia',
				value: '2_Kordia',
				agroBusinessValue: 'Agricola_Pacifico_sur',
				agroBusiness: 'Agrícola Pacífico sur',
				specie: 'Cerezo',
				specieValue: 'Cerezo',
				ndviBounds: null,
				ndvi: null,
				quarter: [
					{
						label: '2',
						value: '2',
						variety: [
							{
								label: 'Kordia',
								value: 'Kordia',
								plantsHa: 1667,
								hectare: 8.38,
								rootStock: 'Gisela 6',
								rootStockValue: 'Gisela_6',
							},
						],
					},
				],
			},
			{
				label: '1 Regina',
				value: '1_Regina',
				agroBusinessValue: 'Agricola_Pacifico_sur',
				agroBusiness: 'Agrícola Pacífico sur',
				specie: 'Cerezo',
				specieValue: 'Cerezo',
				ndviBounds: null,
				ndvi: null,
				quarter: [
					{
						label: '1',
						value: '1',
						variety: [
							{
								label: 'Kordia',
								value: 'Kordia',
								plantsHa: 1667,
								hectare: 8.12,
								rootStock: 'Gisela 6',
								rootStockValue: 'Gisela_6',
							},
						],
					},
				],
			},
		],
		icon: 'https://firebasestorage.googleapis.com/v0/b/qr-analytics-96663.appspot.com/o/icon%2FAPS_Miraflores.png?alt=media&token=61531d7e-efb6-4f6c-bc59-bf000361825c&_gl=1*diawrc*_ga*MTIwMzc4MTcwMy4xNjc0MTYwNTgz*_ga_CW55HF8NVT*MTY5NzYzOTU4MS4yMS4xLjE2OTc2NDAwMDQuMTguMC4w',
		value: 'Santa_Margarita',
		label: 'Santa Margarita',
		client: 'Alex Sedano',
		clientValue: 'Alex_Sedano',
		agroBusiness: 'Agricola Chicureo',
		agroBusinessValue: 'Agricola_Chicureo',
		season: ['2023'],
		location: {
			lat: -35.928596,
			lng: -71.593643,
		},
		stationId: '6975',
	},
	{
		id: '6516b5cf9e703d215b1d2222',
		cc: [
			{
				label: '11',
				value: '11',
				agroBusinessValue: 'San_Clemente',
				agroBusiness: 'San Clemente',
				specie: 'Cerezo',
				specieValue: 'Cerezo',
				ndviBounds: null,
				ndvi: null,
				quarter: [
					{
						label: '11-Rainier ',
						value: '11-Rainier',
						variety: [
							{
								label: 'Rainier ',
								value: 'Rainier',
								plantsHa: 1250,
								hectare: 5.07,
								rootStock: 'Maxma 14',
								rootStockValue: 'Maxma_14',
							},
						],
					},
				],
			},
			{
				label: '4',
				value: '4',
				agroBusinessValue: 'San_Clemente',
				agroBusiness: 'San Clemente',
				specie: 'Cerezo',
				specieValue: 'Cerezo',
				ndviBounds: null,
				ndvi: null,
				quarter: [
					{
						label: '4-Lapins ',
						value: '4-Lapins',
						variety: [
							{
								label: 'Lapins',
								value: 'Lapins',
								plantsHa: 775,
								hectare: 4.23,
								rootStock: 'Colt',
								rootStockValue: 'Colt',
							},
						],
					},
				],
			},
			{
				label: '10',
				value: '10',
				agroBusinessValue: 'San_Clemente',
				agroBusiness: 'San Clemente',
				specie: 'Cerezo',
				specieValue: 'Cerezo',
				ndviBounds: null,
				ndvi: null,
				quarter: [
					{
						label: '10-Frisco ',
						value: '10-Frisco',
						variety: [
							{
								label: 'Frisco ',
								value: 'Frisco',
								plantsHa: 1250,
								hectare: 3.3,
								rootStock: 'Maxma 14',
								rootStockValue: 'Maxma_14',
							},
						],
					},
				],
			},
			{
				label: '2',
				value: '2',
				agroBusinessValue: 'San_Clemente',
				agroBusiness: 'San Clemente',
				specie: 'Cerezo',
				specieValue: 'Cerezo',
				ndviBounds: null,
				ndvi: null,
				quarter: [
					{
						label: '2-Lapins ',
						value: '2-Lapins',
						variety: [
							{
								label: 'Lapins',
								value: 'Lapins',
								plantsHa: 775,
								hectare: 4.67,
								rootStock: 'Colt',
								rootStockValue: 'Colt',
							},
						],
					},
				],
			},
		],
		icon: 'https://firebasestorage.googleapis.com/v0/b/qr-analytics-96663.appspot.com/o/icon%2FAPS_Miraflores.png?alt=media&token=61531d7e-efb6-4f6c-bc59-bf000361825c&_gl=1*diawrc*_ga*MTIwMzc4MTcwMy4xNjc0MTYwNTgz*_ga_CW55HF8NVT*MTY5NzYzOTU4MS4yMS4xLjE2OTc2NDAwMDQuMTguMC4w',
		value: 'Rauquen',
		label: 'Rauquen',
		client: 'Graces Fruit',
		clientValue: 'Graces_Fruit',
		agroBusiness: 'Agricola Garces',
		agroBusinessValue: 'Agricola_Garces',
		season: ['2023'],
		location: {
			lat: -35.928596,
			lng: -71.593643,
		},
		stationId: '6975',
	},
];

const Specie = [
	{
		label: 'Cerezo',
		value: 'Cerezo',
		variety: [
			{
				label: 'Santina',
				value: 'Santina',
				rootStock: [{
					label:'Colt',
					value:'Colt'
				}
				]
			},
			{
				label: 'Bing',
				value: 'Bing',
				rootStock: [{
					label:'Colt',
					value:'Colt'
				}
				]
			},
			{
				label: 'Kordia',
				value: 'Kordia',
				rootStock: [{
					label:'Gisela',
					value:'Gisela'
				}
				]
			},
			{
				label: 'Lapins',
				value: 'Lapins',
				rootStock: [{
					label:'Colt',
					value:'Colt'
				}
				]
				
			},
			{
				label: 'Prim 2.5',
				value: 'Prim_2.5',
				rootStock: [{
					label:'Gisela 12',
					value:'Gisela_12'
				}
				]
			},
		],
	},
	{
		label: 'Durazno',
		value: 'Durazno',
		variety: [
			{
				label: 'Brightstar',
				value: 'Brightstar',
				rootStock: [{
					label:'Rotundil',
					value:'Rotundil'
				}
				]
			},
		],
	},
];

export { Client, Orchards, Specie };*/
/*const clientData = [
	{
	  label: "Garces Fruit",
	  value: "Garces_Fruit",
	  agroBusinessData: [
		{
		  label: "HGE SPA",
		  value: "HGE_SPA",
		  orchardData: [
			{
			  label: "Almahue",
			  value: "Almahue",
			  ccData: [
				{
				  label: "S 34",
				  value: "S_34"
				}
			  ]
			}
		  ]
		}
	  ]
	}
  ];
  
  const specieData = [
	{
	  label: "Durazno",
	  value: "Durazno",
	  varietyData: [
		{
		  label: "Cherry Plums",
		  value: "Cherry_Plums",
		  rootStockData: [
			{
			  label: "Colt",
			  value: "Colt"
			}
		  ]
		}
	  ]
	}
  ];

  export { clientData, specieData };*/

const clientData = [
    {
      client: "Garces Fruit",
      clientValue: "Garces_Fruit",
      agroBusinessData: [
        {
          agroBusiness: "HGE SPA",
          agroBusinessValue: "HGE_SPA",
          orchardData: [
            {
              orchard: "Almahue",
              orchardValue: "Almahue",
              cc: [
                {
                  cc: "S 34",
                  ccValue: "S_34"
                }
              ]
            }
          ]
        }
      ]
    }
  ];
  
  const specieData = [
    {
      specie: "Durazno",
      specieValue: "Durazno",
      varietyData: [
        {
          variety: "Cherry Plums",
          varietyValue: "Cherry_Plums",
          rootStockData: [
            {
              rootStock: "Colt",
              rootStockValue: "Colt"
            }
          ]
        }
      ]
    }
  ];

export { clientData, specieData };

  
