import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
	SaveAction,
	CardHeaderWidget,
	TextFieldWidgetText,
	TextFieldWidgetSelectControllerHorizontal,
} from '../../../components';
import { Card, CardContent, Divider, TextField } from '@material-ui/core';
import { BsRow, BsCol } from '../../../layouts/components';
import { CloseButtonEditionArea } from '../../../components/EditionAreaWidget';
import {alterArrayGetFields} from '../../../helpers';
import '../../../assets/scss/form.scss';
import viewsTexts from '../../../common/viewsTexts.json';
import { AxiosContext } from '../../../context/AxiosContext';

const AmEdit = (props) => {

	const require = true;

	console.log(props.rowEdit, ' <----llega row a edit');
	const {
		name,
		label,
		id,
		client,
	} = props.rowEdit;

	const { editFunc, closeFunc } = props;
	const [clientDataState, setClientDataState] = useState([]);
	const [agroBusinessDataState, setAgroBusinessDataState] = useState([]);
	const [loadingDataState, setLoadingDataState] = useState(false);
	// Asume que quieres el rootStock de la primera variedad de la primera especie

	const axiosContext = useContext(AxiosContext);

	const [loadingQueryState, setLoadingQueryState] = useState(false);
	const [successQueryState, setSuccessQueryState] = useState(false);

	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
		
	} = useForm();

	useEffect(() => {
		reset({

			client:client,
			agroBusiness:label,
			id:id,

		});		
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	
	}, [client,label,id, reset]);



	const onSubmit = async (values) => {
		setLoadingQueryState(true);

		const query = {
			id: values.id,
			name: values.agroBusiness,	
		};

		try {
			const response = await axiosContext.authAxios.post(
				'/v1/agrobusiness/update-agrobusiness',
				query
			);

			console.log(response, '<---respuesta de query edit user');

			if (response.status === 200) {
				// alert('listo');
				editFunc(response.data);
				setSuccessQueryState(true);
			}

			setLoadingQueryState(false);
		} catch (err) {
			setLoadingQueryState(false);
		}
	};

	const getClientsAsync = async () => {
		setLoadingDataState(true);
		try {
			let response = await axiosContext.authAxios.post(
				'v1/client/list-clients'
			);

			console.log(response, '<<----respuesta profiles');

			const { data, status } = response;

			if (status === 200) {
				setClientDataState(response.data);
				setLoadingDataState(false);
				
			}
		} catch (error) {
			console.log(error);
			setLoadingDataState(false);
			// setLoadingVarietyFilterState(false);
		}
	};

	// endpoints
	useEffect(() => {
		getClientsAsync();
	}, []);


	console.log(errors, ' <----errors');

	return (
		<Card elevation={0}>
			<CardHeaderWidget
				title={
					viewsTexts.titles.agrobusiness.agrobusinessTextEdit +
					' ' +
					client +
					' ' +
					label
				}
			/>
			<CloseButtonEditionArea closeFunc={props.closeFunc} />
			<Divider />
			<CardContent>
				<BsRow>
					<BsCol class="col-md-12">
						<form
							autoComplete="on"
							onSubmit={handleSubmit(onSubmit)}
						>
							<BsRow>
								<BsCol class="col-md-6">
								<TextFieldWidgetText
										InputLabelProps={{
											shrink: true,
										}}
										name="client"
										//labeltext={viewsTexts.forms.orchard}
										labeltext="Cliente"
										req={false}
										control={control}
										errors={errors.client}
										defaultValue=""
										disabled={true}
									/>
								</BsCol>
								<BsCol class="col-md-6">
								<TextFieldWidgetText
										InputLabelProps={{
											shrink: true,
										}}
										name="agroBusiness"
										labeltext="Agricola"
										req
										control={control}
										errors={errors.agroBusiness}
										defaultValue=""
										disabled={false}
									/>
								</BsCol>
							</BsRow>
								<BsRow class="mt-5">
								<BsCol class="col-md-12 text-center">
									<SaveAction
										title="Guardar"
										color="secondary"
										size="large"
										className="roundedButton ml-md-2"
										variant="contained"
										disabled={loadingQueryState}
										messageType="edit"
										actionClose={closeFunc}
										mutationLoading={loadingQueryState}
										success={successQueryState}
									/>
								</BsCol>
							</BsRow>
						</form>
					</BsCol>
				</BsRow>
			</CardContent>
		</Card>
	);
};

export default AmEdit;
