import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import classes from './Profile.module.scss';
import { Context } from '../../../../../../context/Context';

const Profile = (props) => {
	const { className, open, ...rest } = props;
	const context = useContext(Context);
	// console.log(context, '<<<<<<');
	const user = {
		name: context.name,
		bio: context.userProfile,
	};

	return (
		<div {...rest} className={clsx(classes.root, className)}>
			{/* <Typography className={classes.name} variant="h5">
				{user.name}
			</Typography> */}
			{/* {open && <Typography>{user.bio} dfgfd gdfg</Typography>} */}
		</div>
	);
};

Profile.propTypes = {
	className: PropTypes.string,
};

export default Profile;
