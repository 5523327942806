const Region = [
    {
      "label": "Arica y Parinacota",
      "value": "Arica_y_Parinacota",
      "id": "",
      "region_number": "XV",
      "region_iso_3166_2": "CL-AP",
      "provincias": [
        {
          "label": "Arica",
          "value": "Arica",
          "id": "",
          "comunas": [
            {
              "label": "Arica",
              "value": "Arica",
              "id": "15101"
            },
            {
              "label": "Camarones",
              "value": "Camarones",
              "id": "15102"
            }
          ]
        },
        {
          "label": "Parinacota",
          "value": "Parinacota",          "id": "",
          "comunas": [
            {
              "label": "Putre",
              "value": "Putre",
              "id": "15201"
            },
            {
              "label": "General Lagos",
              "value": "General_Lagos",
              "id": "15202"
            }
          ]
        }
      ]
    },
    {
      "label": "Tarapacá",
      "value": "Tarapaca",
      "id": "",
      "region_number": "I",
      "region_iso_3166_2": "CL-TA",
      "provincias": [
        {
          "label": "Iquique",
          "value": "Iquique",
          "id": "",
          "comunas": [
            {
              "label": "Iquique",
              "value": "Iquique",
              "id": "01101"
            },
            {
              "label": "Alto Hospicio",
              "value": "Alto_Hospicio",
              "id": "01107"
            }
          ]
        },
        {
          "label": "Tamarugal",
          "value": "Tamarugal",
          "id": "",
          "comunas": [
            {
              "label": "Pozo Almonte",
              "value": "Pozo_Almonte",
              "id": "01401"
            },
            {
              "label": "Camiña",
              "value": "Camina",
              "id": "01402"
            },
            {
              "label": "Colchane",
              "value": "Colchane",
              "id": "01403"
            },
            {
              "label": "Huara",
              "value": "Huara",
              "id": "01404"
            },
            {
              "label": "Pica",
              "value": "Pica",
              "id": "01405"
            }
          ]
        }
      ]
    },
    {
      "label": "Antofagasta",
      "value": "Antofagasta",
      "id": "",
      "region_number": "II",
      "region_iso_3166_2": "CL-AN",
      "provincias": [
        {
          "label": "Antofagasta",
          "value": "Antofagasta",
          "id": "",
          "comunas": [
            {
              "label": "Antofagasta",
              "value": "Antofagasta",
              "id": "02101"
            },
            {
              "label": "Mejillones",
              "value": "Mejillones",
              "id": "02102"
            },
            {
              "label": "Sierra Gorda",
              "value": "Sierra_Gorda",
              "id": "02103"
            },
            {
              "label": "Taltal",
              "value": "Taltal",
              "id": "02104"
            }
          ]
        },
        {
          "label": "El Loa",
          "value": "El_Loa",
          "id": "",
          "comunas": [
            {
              "label": "Calama",
              "value": "Calama",
              "id": "02201"
            },
            {
              "label": "Ollagüe",
              "value": "Ollagüe",
              "id": "02202"
            },
            {
              "label": "San Pedro de Atacama",
              "value": "San_Pedro_de_Atacama",
              "id": "02203"
            }
          ]
        },
        {
          "label": "Tocopilla",
          "value": "Tocopilla",
          "id": "",
          "comunas": [
            {
              "label": "Tocopilla",
              "value": "Tocopilla",
              "id": "02301"
            },
            {
              "label": "María Elena",
              "value": "María Elena",
              "id": "02302"
            }
          ]
        }
      ]
    },
    {
      "label": "Atacama",
      "value": "Atacama",
      "id": "",
      "region_number": "III",
      "region_iso_3166_2": "CL-AT",
      "provincias": [
        {
          "label": "Copiapó",
          "value": "Copiapo",
          "id": "",
          "comunas": [
            {
              "label": "Copiapó",
              "value": "Copiapo",
              "id": "03101"
            },
            {
              "label": "Caldera",
              "value": "Caldera",
              "id": "03102"
            },
            {
              "label": "Tierra Amarilla",
              "value": "Tierra_Amarilla",
              "id": "03103"
            }
          ]
        },
        {
          "label": "Chañaral",
          "value": "Chanaral",
          "id": "",
          "comunas": [
            {
              "label": "Chañaral",
              "value": "Chanaral",
              "id": "03201"
            },
            {
              "label": "Diego de Almagro",
              "value": "Diego_de_Almagro",
              "id": "03202"
            }
          ]
        },
        {
          "label": "Huasco",
          "value": "Huasco",
          "id": "",
          "comunas": [
            {
              "label": "Vallenar",
              "value": "Vallenar",
              "id": "03301"
            },
            {
              "label": "Alto del Carmen",
              "value": "Alto_del_Carmen",
              "id": "03302"
            },
            {
              "label": "Freirina",
              "value": "Freirina",
              "id": "03303"
            },
            {
              "label": "Huasco",
              "value": "Huasco",
              "id": "03304"
            }
          ]
        }
      ]
    },
    {
      "label": "Coquimbo",
      "value": "Coquimbo",
      "id": "",
      "region_number": "IV",
      "region_iso_3166_2": "CL-CO",
      "provincias": [
        {
          "label": "Elqui",
          "value": "Elqui",
          "id": "",
          "comunas": [
            {
              "label": "La Serena",
              "value": "La_Serena",
              "id": "04101"
            },
            {
              "label": "Coquimbo",
              "value": "Coquimbo",
              "id": "04102"
            },
            {
              "label": "Andacollo",
              "value": "Andacollo",
              "id": "04103"
            },
            {
              "label": "La Higuera",
              "value": "La_Higuera",
              "id": "04104"
            },
            {
              "label": "Paiguano",
              "value": "Paiguano",
              "id": "04105"
            },
            {
              "label": "Vicuña",
              "value": "Vicuna",
              "id": "04106"
            }
          ]
        },
        {
          "label": "Choapa",
          "value": "Choapa",
          "id": "",
          "comunas": [
            {
              "label": "Illapel",
              "value": "Illapel",
              "id": "04201"
            },
            {
              "label": "Canela",
              "value": "Canela",
              "id": "04202"
            },
            {
              "label": "Los Vilos",
              "value": "Los_Vilos",
              "id": "04203"
            },
            {
              "label": "Salamanca",
              "value": "Salamanca",
              "id": "04204"
            }
          ]
        },
        {
          "label": "Limarí",
          "value": "Limari",
          "id": "",
          "comunas": [
            {
              "label": "Ovalle",
              "value": "Ovalle",
              "id": "04301"
            },
            {
              "label": "Combarbalá",
              "value": "Combarbala",
              "id": "04302"
            },
            {
              "label": "Monte Patria",
              "value": "Monte_Patria",
              "id": "04303"
            },
            {
              "label": "Punitaqui",
              "value": "Punitaqui",
              "id": "04304"
            },
            {
              "label": "Río Hurtado",
              "value": "Río_Hurtado",
              "id": "04305"
            }
          ]
        }
      ]
    },
    {
      "label": "Valparaíso",
      "value": "Valparaiso",
      "id": "",
      "region_number": "V",
      "region_iso_3166_2": "CL-VS",
      "provincias": [
        {
          "label": "Valparaíso",
          "value": "Valparaiso",
          "id": "",
          "comunas": [
            {
              "label": "Valparaíso",
              "value": "Valparaiso",
              "id": "05101"
            },
            {
              "label": "Casablanca",
              "value": "Casablanca",
              "id": "05102"
            },
            {
              "label": "Concón",
              "value": "Concon",
              "id": "05103"
            },
            {
              "label": "Juan Fernández",
              "value": "Juan_Fernandez",
              "id": "05104"
            },
            {
              "label": "Puchuncaví",
              "value": "Puchuncavi",
              "id": "05105"
            },
            {
              "label": "Quintero",
              "value": "Quintero",
              "id": "05107"
            },
            {
              "label": "Viña del Mar",
              "value": "Vina_del_Mar",
              "id": "05109"
            }
          ]
        },
        {
          "label": "Isla de Pascua",
          "value": "Isla de Pascua",
          "id": "",
          "comunas": [
            {
              "label": "Isla de Pascua",
              "value": "Isla de Pascua",
              "id": "05201"
            }
          ]
        },
        {
          "label": "Los Andes",
          "value": "Los Andes",
          "id": "",
          "comunas": [
            {
              "label": "Los Andes",
              "value": "Los Andes",
              "id": "05301"
            },
            {
              "label": "Calle Larga",
              "value": "Calle Larga",
              "id": "05302"
            },
            {
              "label": "Riconada",
              "value": "Riconada",
              "id": "05303"
            },
            {
              "label": "San Esteban",
              "value": "San Esteban",
              "id": "05304"
            }
          ]
        },
        {
          "label": "Petorca",
          "value": "Petorca",
          "id": "",
          "comunas": [
            {
              "label": "La Ligua",
              "value": "La Ligua",
              "id": "05401"
            },
            {
              "label": "Cabildo",
              "value": "Cabildo",
              "id": "05402"
            },
            {
              "label": "Papudo",
              "value": "Papudo",
              "id": "05403"
            },
            {
              "label": "Petorca",
              "value": "Petorca",
              "id": "05404"
            },
            {
              "label": "Zapallar",
              "value": "Zapallar",
              "id": "05405"
            }
          ]
        },
        {
          "label": "Quillota",
          "value": "Quillota",
          "id": "",
          "comunas": [
            {
              "label": "Quillota",
              "value": "Quillota",
              "id": "05501"
            },
            {
              "label": "La Calera",
              "value": "La Calera",
              "id": "05502"
            },
            {
              "label": "Hijuelas",
              "value": "Hijuelas",
              "id": "05503"
            },
            {
              "label": "La Cruz",
              "value": "La Cruz",
              "id": "05504"
            },
            {
              "label": "Nogales",
              "value": "Nogales",
              "id": "05506"
            }
          ]
        },
        {
          "label": "San Antonio",
          "value": "San Antonio",
          "id": "",
          "comunas": [
            {
              "label": "San Antonio",
              "value": "San Antonio",
              "id": "05601"
            },
            {
              "label": "Algarrobo",
              "value": "Algarrobo",
              "id": "05602"
            },
            {
              "label": "Cartagena",
              "value": "Cartagena",
              "id": "05603"
            },
            {
              "label": "El Quisco",
              "value": "El Quisco",
              "id": "05604"
            },
            {
              "label": "El Tabo",
              "value": "El Tabo",
              "id": "05605"
            },
            {
              "label": "Santo Domingo",
              "value": "Santo Domingo",
              "id": "05606"
            }
          ]
        },
        {
          "label": "San Felipe de Aconcagua",
          "value": "San Felipe de Aconcagua",
          "id": "",
          "comunas": [
            {
              "label": "San Felipe",
              "value": "San Felipe",
              "id": "05701"
            },
            {
              "label": "Catemu",
              "value": "Catemu",
              "id": "05702"
            },
            {
              "label": "Llaillay",
              "value": "Llaillay",
              "id": "05703"
            },
            {
              "label": "Panquehue",
              "value": "Panquehue",
              "id": "05704"
            },
            {
              "label": "Putaendo",
              "value": "Putaendo",
              "id": "05705"
            },
            {
              "label": "Santa María",
              "value": "Santa María",
              "id": "05706"
            }
          ]
        },
        {
          "label": "Marga Marga",
          "value": "Marga Marga",
          "id": "",
          "comunas": [
            {
              "label": "Quilpué",
              "value": "Quilpué",
              "id": "05801"
            },
            {
              "label": "Limache",
              "value": "Limache",
              "id": "05802"
            },
            {
              "label": "Olmué",
              "value": "Olmué",
              "id": "05803"
            },
            {
              "label": "Villa Alemana",
              "value": "Villa Alemana",
              "id": "05804"
            }
          ]
        }
      ]
    },
    {
      "label": "Región del Libertador Gral. Bernardo O'Higgins",
      "value": "Region_del_Libertador_Gral_Bernardo_O_Higgins",
      "id": "",
      "region_number": "VI",
      "region_iso_3166_2": "CL-LI",
      "provincias": [
        {
          "label": "Cachapoal",
          "value": "Cachapoal",
          "id": "",
          "comunas": [
            {
              "label": "Rancagua",
              "value": "Rancagua",
              "id": "06101"
            },
            {
              "label": "Codegua",
              "value": "Codegua",
              "id": "06102"
            },
            {
              "label": "Coinco",
              "value": "Coinco",
              "id": "06103"
            },
            {
              "label": "Coltauco",
              "value": "Coltauco",
              "id": "06104"
            },
            {
              "label": "Doñihue",
              "value": "Donihue",
              "id": "06105"
            },
            {
              "label": "Graneros",
              "value": "Graneros",
              "id": "06106"
            },
            {
              "label": "Las Cabras",
              "value": "Las_Cabras",
              "id": "06107"
            },
            {
              "label": "Machalí",
              "value": "Machali",
              "id": "06108"
            },
            {
              "label": "Malloa",
              "value": "Malloa",
              "id": "06109"
            },
            {
              "label": "Mostazal",
              "value": "Mostazal",
              "id": "06110"
            },
            {
              "label": "Olivar",
              "value": "Olivar",
              "id": "06111"
            },
            {
              "label": "Peumo",
              "value": "Peumo",
              "id": "06112"
            },
            {
              "label": "Pichidegua",
              "value": "Pichidegua",
              "id": "06113"
            },
            {
              "label": "Quinta de Tilcoco",
              "value": "Quinta_de_Tilcoco",
              "id": "06114"
            },
            {
              "label": "Rengo",
              "value": "Rengo",
              "id": "06115"
            },
            {
              "label": "Requínoa",
              "value": "Requinoa",
              "id": "06116"
            },
            {
              "label": "San Vicente",
              "value": "San_Vicente",
              "id": "06117"
            }
          ]
        },
        {
          "label": "Cardenal Caro",
          "value": "Cardenal Caro",
          "id": "",
          "comunas": [
            {
              "label": "Pichilemu",
              "value": "Pichilemu",
              "id": "06201"
            },
            {
              "label": "La Estrella",
              "value": "La_Estrella",
              "id": "06202"
            },
            {
              "label": "Litueche",
              "value": "Litueche",
              "id": "06203"
            },
            {
              "label": "Marichihue",
              "value": "Marichihue",
              "id": "06204"
            },
            {
              "label": "Navidad",
              "value": "Navidad",
              "id": "06205"
            },
            {
              "label": "Paredones",
              "value": "Paredones",
              "id": "06206"
            }
          ]
        },
        {
          "label": "Colchagua",
          "value": "Colchagua",
          "id": "",
          "comunas": [
            {
              "label": "San Fernando",
              "value": "San_Fernando",
              "id": "06301"
            },
            {
              "label": "Chépica",
              "value": "Chépica",
              "id": "06302"
            },
            {
              "label": "Chimbarongo",
              "value": "Chimbarongo",
              "id": "06303"
            },
            {
              "label": "Lolol",
              "value": "Lolol",
              "id": "06304"
            },
            {
              "label": "Nancagua",
              "value": "Nancagua",
              "id": "06305"
            },
            {
              "label": "Palmilla",
              "value": "Palmilla",
              "id": "06306"
            },
            {
              "label": "Peralillo",
              "value": "Peralillo",
              "id": "06307"
            },
            {
              "label": "Placilla",
              "value": "Placilla",
              "id": "06308"
            },
            {
              "label": "Pumanque",
              "value": "Pumanque",
              "id": "06309"
            },
            {
              "label": "Santa Cruz",
              "value": "Santa_Cruz",
              "id": "06310"
            }
          ]
        }
      ]
    },
    {
      "label": "Región del Maule",
      "value": "Region_del_Maule",
      "id": "",
      "region_number": "VII",
      "region_iso_3166_2": "CL-ML",
      "provincias": [
        {
          "label": "Talca",
          "value": "Talca",
          "id": "",
          "comunas": [
            {
              "label": "Talca",
              "value": "Talca",
              "id": "07101"
            },
            {
              "label": "Constitución",
              "value": "Constitucion",
              "id": "07102"
            },
            {
              "label": "Curepto",
              "value": "Curepto",
              "id": "07103"
            },
            {
              "label": "Empedrado",
              "value": "Empedrado",
              "id": "07104"
            },
            {
              "label": "Maule",
              "value": "Maule",
              "id": "07105"
            },
            {
              "label": "Pelarco",
              "value": "Pelarco",
              "id": "07106"
            },
            {
              "label": "Pencahue",
              "value": "Pencahue",
              "id": "07107"
            },
            {
              "label": "Río Claro",
              "value": "Rio_Claro",
              "id": "07108"
            },
            {
              "label": "San Clemente",
              "value": "San_Clemente",
              "id": "07109"
            },
            {
              "label": "San Rafael",
              "value": "San_Rafael",
              "id": "07110"
            }
          ]
        },
        {
          "label": "Cauquenes",
          "value": "Cauquenes",
          "id": "",
          "comunas": [
            {
              "label": "Cauquenes",
              "value": "Cauquenes",
              "id": "07201"
            },
            {
              "label": "Chanco",
              "value": "Chanco",
              "id": "07202"
            },
            {
              "label": "Pelluhue",
              "value": "Pelluhue",
              "id": "07203"
            }
          ]
        },
        {
          "label": "Curicó",
          "value": "Curico",
          "id": "",
          "comunas": [
            {
              "label": "Curicó",
              "value": "Curico",
              "id": "07301"
            },
            {
              "label": "Hualañé",
              "value": "Hualane",
              "id": "07302"
            },
            {
              "label": "Licantén",
              "value": "Licanten",
              "id": "07303"
            },
            {
              "label": "Molina",
              "value": "Molina",
              "id": "07304"
            },
            {
              "label": "Rauco",
              "value": "Rauco",
              "id": "07305"
            },
            {
              "label": "Romeral",
              "value": "Romeral",
              "id": "07306"
            },
            {
              "label": "Sagrada Familia",
              "value": "Sagrada_Familia",
              "id": "07307"
            },
            {
              "label": "Teno",
              "value": "Teno",
              "id": "07308"
            },
            {
              "label": "Vichuquén",
              "value": "Vichuquen",
              "id": "07309"
            }
          ]
        },
        {
          "label": "Linares",
          "value": "Linares",
          "id": "",
          "comunas": [
            {
              "label": "Linares",
              "value": "Linares",
              "id": "07401"
            },
            {
              "label": "Colbún",
              "value": "Colbun",
              "id": "07402"
            },
            {
              "label": "Longaví",
              "value": "Longavi",
              "id": "07403"
            },
            {
              "label": "Parral",
              "value": "Parral",
              "id": "07404"
            },
            {
              "label": "Retiro",
              "value": "Retiro",
              "id": "07405"
            },
            {
              "label": "San Javier",
              "value": "San_Javier",
              "id": "07406"
            },
            {
              "label": "Villa Alegre",
              "value": "Villa_Alegre",
              "id": "07407"
            },
            {
              "label": "Yerbas Buenas",
              "value": "Yerbas_Buenas",
              "id": "07408"
            }
          ]
        }
      ]
    },
    {
      "label": "Región de Ñuble",
      "value": "Region_de_Nuble",
      "id": "",
      "region_number": "XVI",
      "region_iso_3166_2": "CL-NB",
      "provincias": [
        {
          "label": "Diguillín",
          "value": "Diguillin",
          "id": "",
          "comunas": [
            {
              "label": "Chillán",
              "value": "Chillan",
              "id": "16101"
            },
            {
              "label": "Chillán Viejo",
              "value": "Chillan_Viejo",
              "id": "16102"
            },
            {
              "label": "Quillón",
              "value": "Quillon",
              "id": "16103"
            },
            {
              "label": "Bulnes",
              "value": "Bulnes",
              "id": "16104"
            },
            {
              "label": "San Ignacio",
              "value": "San_Ignacio",
              "id": "16105"
            },
            {
              "label": "El Carmen",
              "value": "El_Carmen",
              "id": "16106"
            },
            {
              "label": "Pinto",
              "value": "Pinto",
              "id": "16107"
            },
            {
              "label": "Pemuco",
              "value": "Pemuco",
              "id": "16108"
            },
            {
              "label": "Yungay",
              "value": "Yungay",
              "id": "16109"
            }
          ]
        },
        {
          "label": "Itata",
          "value": "Itata",
          "id": "",
          "comunas": [
            {
              "label": "Quirihue",
              "value": "Quirihue",
              "id": "16201"
            },
            {
              "label": "Cobquecura",
              "value": "Cobquecura",
              "id": "16202"
            },
            {
              "label": "Ninhue",
              "value": "Ninhue",
              "id": "16203"
            },
            {
              "label": "Treguaco",
              "value": "Treguaco",
              "id": "16204"
            },
            {
              "label": "Coelemu",
              "value": "Coelemu",
              "id": "16205"
            },
            {
              "label": "Portezuelo",
              "value": "Portezuelo",
              "id": "16206"
            },
            {
              "label": "Ránqui",
              "value": "Ranqui",
              "id": "16207"
            }
          ]
        },
        {
          "label": "Punilla",
          "value": "Punilla",
          "id": "",
          "comunas": [
            {
              "label": "San Carlos",
              "value": "San_Carlos",
              "id": "16301"
            },
            {
              "label": "Ñiquén",
              "value": "Niquen",
              "id": "16302"
            },
            {
              "label": "Coihueco",
              "value": "Coihueco",
              "id": "16303"
            },
            {
              "label": "San Fabián",
              "value": "San_Fabian",
              "id": "16304"
            },
            {
              "label": "San Nico",
              "value": "San_Nico",
              "id": "16305"
            }
          ]
        }
      ]
    },
    {
      "label": "Región del Biobío",
      "value": "Region_del_Bioboo",
      "id": "",
      "region_number": "VIII",
      "region_iso_3166_2": "CL-BI",
      "provincias": [
        {
          "label": "Concepción",
          "value": "Concepción",
          "id": "",
          "comunas": [
            {
              "label": "Concepción",
              "value": "Concepción",
              "id": "08101"
            },
            {
              "label": "Coronel",
              "value": "Coronel",
              "id": "08102"
            },
            {
              "label": "Chiguayante",
              "value": "Chiguayante",
              "id": "08103"
            },
            {
              "label": "Florida",
              "value": "Florida",
              "id": "08104"
            },
            {
              "label": "Hualqui",
              "value": "Hualqui",
              "id": "08105"
            },
            {
              "label": "Lota",
              "value": "Lota",
              "id": "08106"
            },
            {
              "label": "Penco",
              "value": "Penco",
              "id": "08107"
            },
            {
              "label": "San Pedro de la Paz",
              "value": "San Pedro de la Paz",
              "id": "08108"
            },
            {
              "label": "Santa Juana",
              "value": "Santa Juana",
              "id": "08109"
            },
            {
              "label": "Talcahuano",
              "value": "Talcahuano",
              "id": "08110"
            },
            {
              "label": "Tomé",
              "value": "Tomé",
              "id": "08111"
            },
            {
              "label": "Hualpén",
              "value": "Hualpén",
              "id": "08112"
            }
          ]
        },
        {
          "label": "Arauco",
          "value": "Arauco",
          "id": "",
          "comunas": [
            {
              "label": "Lebu",
              "value": "Lebu",
              "id": "08201"
            },
            {
              "label": "Arauco",
              "value": "Arauco",
              "id": "08202"
            },
            {
              "label": "Cañete",
              "value": "Cañete",
              "id": "08203"
            },
            {
              "label": "Contulmo",
              "value": "Contulmo",
              "id": "08204"
            },
            {
              "label": "Curanilahue",
              "value": "Curanilahue",
              "id": "08205"
            },
            {
              "label": "Los Álamos",
              "value": "Los Álamos",
              "id": "08206"
            },
            {
              "label": "Tirúa",
              "value": "Tirúa",
              "id": "08207"
            }
          ]
        },
        {
          "label": "Biobío",
          "value": "Biobío",
          "id": "",
          "comunas": [
            {
              "label": "Los Ángeles",
              "value": "Los Ángeles",
              "id": "08301"
            },
            {
              "label": "Antuco",
              "value": "Antuco",
              "id": "08302"
            },
            {
              "label": "Cabrero",
              "value": "Cabrero",
              "id": "08303"
            },
            {
              "label": "Laja",
              "value": "Laja",
              "id": "08304"
            },
            {
              "label": "Mulchén",
              "value": "Mulchén",
              "id": "08305"
            },
            {
              "label": "Nacimiento",
              "value": "Nacimiento",
              "id": "08306"
            },
            {
              "label": "Negrete",
              "value": "Negrete",
              "id": "08307"
            },
            {
              "label": "Quilaco",
              "value": "Quilaco",
              "id": "08308"
            },
            {
              "label": "Quilleco",
              "value": "Quilleco",
              "id": "08309"
            },
            {
              "label": "San Rosendo",
              "value": "San Rosendo",
              "id": "08310"
            },
            {
              "label": "Santa Bárbara",
              "value": "Santa Bárbara",
              "id": "08311"
            },
            {
              "label": "Tucapel",
              "value": "Tucapel",
              "id": "08312"
            },
            {
              "label": "Yumbel",
              "value": "Yumbel",
              "id": "08313"
            },
            {
              "label": "Alto Biobío",
              "value": "Alto Biobío",
              "id": "08314"
            }
          ]
        }
      ]
    },
    {
      "label": "Región de la Araucanía",
      "value": "Region_de_la_Araucania",
      "id": "",
      "region_number": "IX",
      "region_iso_3166_2": "CL-AR",
      "provincias": [
        {
          "label": "Cautín",
          "value": "Cautín",
          "id": "",
          "comunas": [
            {
              "label": "Temuco",
              "value": "Temuco",
              "id": "09101"
            },
            {
              "label": "Carahue",
              "value": "Carahue",
              "id": "09102"
            },
            {
              "label": "Cunco",
              "value": "Cunco",
              "id": "09103"
            },
            {
              "label": "Curarrehue",
              "value": "Curarrehue",
              "id": "09104"
            },
            {
              "label": "Freire",
              "value": "Freire",
              "id": "09105"
            },
            {
              "label": "Galvarino",
              "value": "Galvarino",
              "id": "09106"
            },
            {
              "label": "Gorbea",
              "value": "Gorbea",
              "id": "09107"
            },
            {
              "label": "Lautaro",
              "value": "Lautaro",
              "id": "09108"
            },
            {
              "label": "Loncoche",
              "value": "Loncoche",
              "id": "09109"
            },
            {
              "label": "Melipeuco",
              "value": "Melipeuco",
              "id": "09110"
            },
            {
              "label": "Nueva Imperial",
              "value": "Nueva Imperial",
              "id": "09111"
            },
            {
              "label": "Padre Las Casas",
              "value": "Padre Las Casas",
              "id": "09112"
            },
            {
              "label": "Perquenco",
              "value": "Perquenco",
              "id": "09113"
            },
            {
              "label": "Pitrufquén",
              "value": "Pitrufquén",
              "id": "09114"
            },
            {
              "label": "Pucón",
              "value": "Pucón",
              "id": "09115"
            },
            {
              "label": "Saavedra",
              "value": "Saavedra",
              "id": "09116"
            },
            {
              "label": "Teodoro Schmidt",
              "value": "Teodoro Schmidt",
              "id": "09117"
            },
            {
              "label": "Toltén",
              "value": "Toltén",
              "id": "09118"
            },
            {
              "label": "Vilcún",
              "value": "Vilcún",
              "id": "09119"
            },
            {
              "label": "Villarrica",
              "value": "Villarrica",
              "id": "09120"
            },
            {
              "label": "Cholchol",
              "value": "Cholchol",
              "id": "09121"
            }
          ]
        },
        {
          "label": "Malleco",
          "value": "Malleco",
          "id": "",
          "comunas": [
            {
              "label": "Angol",
              "value": "Angol",
              "id": "09201"
            },
            {
              "label": "Collipulli",
              "value": "Collipulli",
              "id": "09202"
            },
            {
              "label": "Curacautín",
              "value": "Curacautín",
              "id": "09203"
            },
            {
              "label": "Ercilla",
              "value": "Ercilla",
              "id": "09204"
            },
            {
              "label": "Lonquimay",
              "value": "Lonquimay",
              "id": "09205"
            },
            {
              "label": "Los Sauces",
              "value": "Los Sauces",
              "id": "09206"
            },
            {
              "label": "Lumaco",
              "value": "Lumaco",
              "id": "09207"
            },
            {
              "label": "Purén",
              "value": "Purén",
              "id": "09208"
            },
            {
              "label": "Renaico",
              "value": "Renaico",
              "id": "09209"
            },
            {
              "label": "Traiguén",
              "value": "Traiguén",
              "id": "09210"
            },
            {
              "label": "Victoria",
              "value": "Victoria",
              "id": "09211"
            }
          ]
        }
      ]
    },
    {
      "label": "Región de los Ríos",
      "value": "Region_de_los_Rios",
      "id": "",
      "region_number": "XIV",
      "region_iso_3166_2": "CL-LR",
      "provincias": [
        {
          "label": "Valdivia",
          "value": "Valdivia",
          "id": "",
          "comunas": [
            {
              "label": "Valdivia",
              "value": "Valdivia",
              "id": "14101"
            },
            {
              "label": "Corral",
              "value": "Corral",
              "id": "14102"
            },
            {
              "label": "Lanco",
              "value": "Lanco",
              "id": "14103"
            },
            {
              "label": "Los Lagos",
              "value": "Los Lagos",
              "id": "14104"
            },
            {
              "label": "Máfil",
              "value": "Máfil",
              "id": "14105"
            },
            {
              "label": "Mariquina",
              "value": "Mariquina",
              "id": "14106"
            },
            {
              "label": "Paillaco",
              "value": "Paillaco",
              "id": "14107"
            },
            {
              "label": "Panguipulli",
              "value": "Panguipulli",
              "id": "14108"
            }
          ]
        },
        {
          "label": "Ranco",
          "value": "Ranco",
          "id": "",
          "comunas": [
            {
              "label": "La Unión",
              "value": "La Unión",
              "id": "14201"
            },
            {
              "label": "Futrono",
              "value": "Futrono",
              "id": "14202"
            },
            {
              "label": "Lago Ranco",
              "value": "Lago Ranco",
              "id": "14203"
            },
            {
              "label": "Río Bueno",
              "value": "Río Bueno",
              "id": "14204"
            }
          ]
        }
      ]
    },
    {
      "label": "Región de los Lagos",
      "value": "Region_de_los_Lagos",
      "id": "",
      "region_number": "X",
      "region_iso_3166_2": "CL-LL",
      "provincias": [
        {
          "label": "Llanquihue",
          "value": "Llanquihue",
          "id": "",
          "comunas": [
            {
              "label": "Puerto Montt",
              "value": "Puerto Montt",
              "id": "10101"
            },
            {
              "label": "Calbuco",
              "value": "Calbuco",
              "id": "10102"
            },
            {
              "label": "Cochamó",
              "value": "Cochamó",
              "id": "10103"
            },
            {
              "label": "Fresia",
              "value": "Fresia",
              "id": "10104"
            },
            {
              "label": "Frutillar",
              "value": "Frutillar",
              "id": "10105"
            },
            {
              "label": "Los Muermos",
              "value": "Los Muermos",
              "id": "10106"
            },
            {
              "label": "Llanquihue",
              "value": "Llanquihue",
              "id": "10107"
            },
            {
              "label": "Maullín",
              "value": "Maullín",
              "id": "10108"
            },
            {
              "label": "Puerto Varas",
              "value": "Puerto Varas",
              "id": "10109"
            }
          ]
        },
        {
          "label": "Chiloé",
          "value": "Chiloé",
          "id": "",
          "comunas": [
            {
              "label": "Castro",
              "value": "Castro",
              "id": "10201"
            },
            {
              "label": "Ancud",
              "value": "Ancud",
              "id": "10202"
            },
            {
              "label": "Chonchi",
              "value": "Chonchi",
              "id": "10203"
            },
            {
              "label": "Curaco de Vélez",
              "value": "Curaco de Vélez",
              "id": "10204"
            },
            {
              "label": "Dalcahue",
              "value": "Dalcahue",
              "id": "10205"
            },
            {
              "label": "Puqueldón",
              "value": "Puqueldón",
              "id": "10206"
            },
            {
              "label": "Queilén",
              "value": "Queilén",
              "id": "10207"
            },
            {
              "label": "Quellón",
              "value": "Quellón",
              "id": "10208"
            },
            {
              "label": "Quemchi",
              "value": "Quemchi",
              "id": "10209"
            },
            {
              "label": "Quinchao",
              "value": "Quinchao",
              "id": "10210"
            }
          ]
        },
        {
          "label": "Osorno",
          "value": "Osorno",
          "id": "",
          "comunas": [
            {
              "label": "Osorno",
              "value": "Osorno",
              "id": "10301"
            },
            {
              "label": "Puerto Octay",
              "value": "Puerto Octay",
              "id": "10302"
            },
            {
              "label": "Purranque",
              "value": "Purranque",
              "id": "10303"
            },
            {
              "label": "Puyehue",
              "value": "Puyehue",
              "id": "10304"
            },
            {
              "label": "Río Negro",
              "value": "Río Negro",
              "id": "10305"
            },
            {
              "label": "San Juan de la Costa",
              "value": "San Juan de la Costa",
              "id": "10306"
            },
            {
              "label": "San Pablo",
              "value": "San Pablo",
              "id": "10307"
            }
          ]
        },
        {
          "label": "Palena",
          "value": "Palena",
          "id": "",
          "comunas": [
            {
              "label": "Chaitén",
              "value": "Chaitén",
              "id": "10401"
            },
            {
              "label": "Futaleufú",
              "value": "Futaleufú",
              "id": "10402"
            },
            {
              "label": "Hualaihué",
              "value": "Hualaihué",
              "id": "10403"
            },
            {
              "label": "Palena",
              "value": "Palena",
              "id": "10404"
            }
          ]
        }
      ]
    },
    {
      "label": "Región Aisén del Gral. Carlos Ibañez del Campo",
      "value": "Region_Aisen_del_Gral_Carlos_Ibañez_del_Campo",
      "id": "",
      "region_number": "XI",
      "region_iso_3166_2": "CL-AI",
      "provincias": [
        {
          "label": "Coyhaique",
          "value": "Coyhaique",
          "id": "",
          "comunas": [
            {
              "label": "Coihaique",
              "value": "Coihaique",
              "id": "11101"
            },
            {
              "label": "Lago Verde",
              "value": "Lago Verde",
              "id": "11102"
            }
          ]
        },
        {
          "label": "Aisén",
          "value": "Aisén",
          "id": "",
          "comunas": [
            {
              "label": "Aisén",
              "value": "Aisén",
              "id": "11201"
            },
            {
              "label": "Cisnes",
              "value": "Cisnes",
              "id": "11202"
            },
            {
              "label": "Guaitecas",
              "value": "Guaitecas",
              "id": "11203"
            }
          ]
        },
        {
          "label": "Capitán Pratt",
          "value": "Capitán Pratt",
          "id": "",
          "comunas": [
            {
              "label": "Cochrane",
              "value": "Cochrane",
              "id": "11301"
            },
            {
              "label": "O'Higgins",
              "value": "O'Higgins",
              "id": "11302"
            },
            {
              "label": "Tortel",
              "value": "Tortel",
              "id": "11303"
            }
          ]
        },
        {
          "label": "General Carrera",
          "value": "General Carrera",
          "id": "",
          "comunas": [
            {
              "label": "Chile Chico",
              "value": "Chile Chico",
              "id": "11401"
            },
            {
              "label": "Río Ibáñez",
              "value": "Río Ibáñez",
              "id": "11402"
            }
          ]
        }
      ]
    },
    {
      "label": "Región de Magallanes y de la Antártica Chilena",
      "value": "Region_de_Magallanes_y_de_la_Antártica_Chilena",
      "id": "",
      "region_number": "XII",
      "region_iso_3166_2": "CL-MA",
      "provincias": [
        {
          "label": "Magallanes",
          "value": "Magallanes",
          "id": "",
          "comunas": [
            {
              "label": "Punta Arenas",
              "value": "Punta Arenas",
              "id": "12101"
            },
            {
              "label": "Laguna Blanca",
              "value": "Laguna Blanca",
              "id": "12102"
            },
            {
              "label": "Río Verde",
              "value": "Río Verde",
              "id": "12103"
            },
            {
              "label": "San Gregorio",
              "value": "San Gregorio",
              "id": "12104"
            }
          ]
        },
        {
          "label": "Antártica Chilena",
          "value": "Antártica Chilena",
          "id": "",
          "comunas": [
            {
              "label": "Cabo de Hornos",
              "value": "Cabo de Hornos",
              "id": "12201"
            },
            {
              "label": "Antártica",
              "value": "Antártica",
              "id": "12202"
            }
          ]
        },
        {
          "label": "Tierra del Fuego",
          "value": "Tierra del Fuego",
          "id": "",
          "comunas": [
            {
              "label": "Porvenir",
              "value": "Porvenir",
              "id": "12301"
            },
            {
              "label": "Primavera",
              "value": "Primavera",
              "id": "12302"
            },
            {
              "label": "Timaukel",
              "value": "Timaukel",
              "id": "12303"
            }
          ]
        },
        {
          "label": "Última Esperanza",
          "value": "Última Esperanza",
          "id": "",
          "comunas": [
            {
              "label": "Natales",
              "value": "Natales",
              "id": "12401"
            },
            {
              "label": "Torres del Paine",
              "value": "Torres del Paine",
              "id": "12402"
            }
          ]
        }
      ]
    },
    {
      "label": "Región Metropolitana de Santiago",
      "value": "Region_Metropolitana_de_Santiago",
      "id": "",
      "region_number": "XIII",
      "region_iso_3166_2": "CL-RM",
      "provincias": [
        {
          "label": "Santiago",
          "value": "Santiago",
          "id": "",
          "comunas": [
            {
              "label": "Santiago",
              "value": "Santiago",
              "id": "13101"
            },
            {
              "label": "Cerrillos",
              "value": "Cerrillos",
              "id": "13102"
            },
            {
              "label": "Cerro Navia",
              "value": "Cerro Navia",
              "id": "13103"
            },
            {
              "label": "Conchalí",
              "value": "Conchalí",
              "id": "13104"
            },
            {
              "label": "El Bosque",
              "value": "El Bosque",
              "id": "13105"
            },
            {
              "label": "Estación Central",
              "value": "Estación Central",
              "id": "13106"
            },
            {
              "label": "Huechuraba",
              "value": "Huechuraba",
              "id": "13107"
            },
            {
              "label": "Independencia",
              "value": "Independencia",
              "id": "13108"
            },
            {
              "label": "La Cisterna",
              "value": "La Cisterna",
              "id": "13109"
            },
            {
              "label": "La Florida",
              "value": "La Florida",
              "id": "13110"
            },
            {
              "label": "La Granja",
              "value": "La Granja",
              "id": "13111"
            },
            {
              "label": "La Pintana",
              "value": "La Pintana",
              "id": "13112"
            },
            {
              "label": "La Reina",
              "value": "La Reina",
              "id": "13113"
            },
            {
              "label": "Las Condes",
              "value": "Las Condes",
              "id": "13114"
            },
            {
              "label": "Lo Barnechea",
              "value": "Lo Barnechea",
              "id": "13115"
            },
            {
              "label": "Lo Espejo",
              "value": "Lo Espejo",
              "id": "13116"
            },
            {
              "label": "Lo Prado",
              "value": "Lo Prado",
              "id": "13117"
            },
            {
              "label": "Macul",
              "value": "Macul",
              "id": "13118"
            },
            {
              "label": "Maipú",
              "value": "Maipú",
              "id": "13119"
            },
            {
              "label": "Ñuñoa",
              "value": "Ñuñoa",
              "id": "13120"
            },
            {
              "label": "Pedro Aguirre Cerda",
              "value": "Pedro Aguirre Cerda",
              "id": "13121"
            },
            {
              "label": "Peñalolén",
              "value": "Peñalolén",
              "id": "13122"
            },
            {
              "label": "Providencia",
              "value": "Providencia",
              "id": "13123"
            },
            {
              "label": "Pudahuel",
              "value": "Pudahuel",
              "id": "13124"
            },
            {
              "label": "Quilicura",
              "value": "Quilicura",
              "id": "13125"
            },
            {
              "label": "Quinta Normal",
              "value": "Quinta Normal",
              "id": "13126"
            },
            {
              "label": "Recoleta",
              "value": "Recoleta",
              "id": "13127"
            },
            {
              "label": "Renca",
              "value": "Renca",
              "id": "13128"
            },
            {
              "label": "San Joaquín",
              "value": "San Joaquín",
              "id": "13129"
            },
            {
              "label": "San Miguel",
              "value": "San Miguel",
              "id": "13130"
            },
            {
              "label": "San Ramón",
              "value": "San Ramón",
              "id": "13131"
            },
            {
              "label": "Vitacura",
              "value": "Vitacura",
              "id": "13132"
            }
          ]
        },
        {
          "label": "Cordillera",
          "value": "Cordillera",
          "id": "",
          "comunas": [
            {
              "label": "Puente Alto",
              "value": "Puente Alto",
              "id": "13201"
            },
            {
              "label": "Pirque",
              "value": "Pirque",
              "id": "13202"
            },
            {
              "label": "San José de Maipo",
              "value": "San José de Maipo",
              "id": "13203"
            }
          ]
        },
        {
          "label": "Chacabuco",
          "value": "Chacabuco",
          "id": "",
          "comunas": [
            {
              "label": "Colina",
              "value": "Colina",
              "id": "13301"
            },
            {
              "label": "Lampa",
              "value": "Lampa",
              "id": "13302"
            },
            {
              "label": "Tiltil",
              "value": "Tiltil",
              "id": "13303"
            }
          ]
        },
        {
          "label": "Maipo",
          "value": "Maipo",
          "id": "",
          "comunas": [
            {
              "label": "San Bernardo",
              "value": "San Bernardo",
              "id": "13401"
            },
            {
              "label": "Buin",
              "value": "Buin",
              "id": "13402"
            },
            {
              "label": "Calera de Tango",
              "value": "Calera de Tango",
              "id": "13403"
            },
            {
              "label": "Paine",
              "value": "Paine",
              "id": "13404"
            }
          ]
        },
        {
          "label": "Mellipilla",
          "value": "Mellipilla",
          "id": "",
          "comunas": [
            {
              "label": "Melipilla",
              "value": "Melipilla",
              "id": "13501"
            },
            {
              "label": "Alhué",
              "value": "Alhué",
              "id": "13502"
            },
            {
              "label": "Curacaví",
              "value": "Curacaví",
              "id": "13503"
            },
            {
              "label": "María Pinto",
              "value": "María Pinto",
              "id": "13504"
            },
            {
              "label": "San Pedro",
              "value": "San Pedro",
              "id": "13505"
            }
          ]
        },
        {
          "label": "Talagante",
          "value": "Talagante",
          "id": "",
          "comunas": [
            {
              "label": "Talagante",
              "value": "Talagante",
              "id": "13601"
            },
            {
              "label": "El Monte",
              "value": "El Monte",
              "id": "13602"
            },
            {
              "label": "Isla de Maipo",
              "value": "Isla de Maipo",
              "id": "13603"
            },
            {
              "label": "Padre Hurtado",
              "value": "Padre Hurtado",
              "id": "13604"
            },
            {
              "label": "Peñaflor",
              "value": "Peñaflor",
              "id": "13605"
            }
          ]
        }
      ]
    }
  ]

export {Region}