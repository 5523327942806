import React from 'react';
import CommentIcon from '@material-ui/icons/Comment';
import {
  IconButton,
  Tooltip,
} from '@material-ui/core';
import '../style.scss';
const IconActionTableDetail = (props) => {
  const { handleActionDetail } = props;
  //<IconButton aria-label="delete" onClick={() => handleActionDetail(row)}>
  return (
    <Tooltip title="Detalle" arrow className="buttonIconAction">
      <IconButton 
        aria-label="detail"
        onClick={handleActionDetail} 
        classes={{
          label: "buttonContainer buttonPrimary",
          root: 'buttonRoot'
        }}
      >
        <CommentIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}

export default IconActionTableDetail;