import React from 'react';
import CircularProgressComponent from '../../Progress/CircularProgressComponent';
import classes from './FilterAction.module.scss';
import { CardActions, Button } from '@material-ui/core';

const FilterAction = props => {
  const { mutationLoading } = props;

  return (
    <CardActions className={classes.cardActions}>
      <div className={classes.wrapper}>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={mutationLoading}>
          Filtrar
        </Button>
        {mutationLoading && <CircularProgressComponent />}
      </div>
    </CardActions>
  );
};

export default FilterAction;
