export const FILTER = [
	{
		label: 'Santa Margarita',
		value: 'Santa_Margarita',
		cc: [
			{
				label: 'ccsm1',
				value: 'ccsm1',
				quarter: [
					{
						label: '10110',
						value: '10110',
						variety: [
							{
								label: 'Regina-a',
								value: 'Regina-a',
							},
							{
								label: 'Regina-b',
								value: 'Regina-b',
							},
						],
					},
					{
						label: '10112',
						value: '10112',
						variety: [
							{
								label: 'Regina-c',
								value: 'Regina-c',
								graft: [
									{
										label: 'Injerto',
										value: 'Injerto',
									},
								],
							},
							{
								label: 'Regina-d',
								value: 'Regina-d',
							},
						],
					},
				],
			},
			{
				label: 'ccsm2',
				value: 'ccsm2',
				quarter: [
					{
						label: '10111',
						value: '10111',
						variety: [
							{
								label: 'Flora',
								value: 'Flora',
							},
						],
					},
				],
			},
		],
	},
	{
		label: 'Santa Laura',
		value: 'Santa_Laura',
		cc: [
			{
				label: 'ccSL',
				value: 'ccSL',
				quarter: [
					{
						label: '10000',
						value: '10000',
						variety: [
							{
								label: 'Regina',
								value: 'Regina',
							},
						],
					},
					{
						label: '10001',
						value: '10001',
						variety: [
							{
								label: 'Regina2',
								value: 'Regina2',
							},
							{
								label: 'Regina3',
								value: 'Regina3',
							},
						],
					},
				],
			},
		],
	},
];

export const INFO = [
	{
		information: {
			variety: 'Regina',
			pattern: 'Patrón',
			dart: 800,
			dartFlower: 200,
			budDart: 200,
			twig: 200,
			flowerTwig: 200,
			flowerHectare: 200,
		},
		stimated: {
			fruitSet: 11234.456,
			weight: 123.56,
			performance: 234567.567,
		},
		graphicFruitSet: {
			name: 'Cuaja',
			year: [2012, 2013, 2014, 2015],
			data: [34, 304, 203, 456],
		},
		graphicFlower: {
			name: 'Flores',
			year: [2012, 2013, 2014, 2015, 2022],
			data: [34, 100, 67, 34567.34, 56],
		},
		graphicWeight: {
			name: 'Calibre',
			year: [2012, 2013, 2014, 2015, 2022],
			data: [34, 1000.56, 67, 34, 56],
		},
		graphicPerformance: {
			name: 'Rendimiento',
			year: [2012, 2013, 2014, 2015, 2016],
			data: [34, 100345.445, 67, 34, 56],
		},
	},
];

export const INFO2022 = [
	{
		information: {
			variety: 'Regina',
			pattern: 'Patrón',
			dart: 800,
			dartFlower: 200,
			budDart: 200,
			twig: 200,
			flowerTwig: 200,
			flowerHectare: 200,
		},
		stimated: {
			fruitSet: 10,
			weight: 10,
			performance: 10000,
		},
		graphicFruitSet: {
			name: 'Cuaja',
			year: [2012, 2013, 2014, 2022],
			data: [34, 304, 203, 456],
		},
		graphicFlower: {
			name: 'Flores',
			year: [2012, 2013, 2014, 2015, 2022],
			data: [34, 100, 67, 34, 56],
		},
		graphicWeight: {
			name: 'Calibre',
			year: [2012, 2013, 2014, 2015, 2022],
			data: [34, 100, 67, 34, 56],
		},
		graphicPerformance: {
			name: 'Rendimiento',
			year: [2012, 2013, 2014, 2015, 2022],
			data: [34, 100, 67, 34, 56],
		},
	},
];

export const INFONULL = [
	{
		information: {
			variety: null,
			pattern: null,
			dart: null,
			dartFlower: null,
			budDart: null,
			twig: null,
			flowerTwig: null,
			flowerHectare: null,
		},
		stimated: {
			fruitSet: null,
			weight: null,
			performance: null,
		},
		graphicFruitSet: {
			name: 'Cuaja',
			year: [],
			data: [],
		},
		graphicFlower: {
			name: 'Flores',
			year: [],
			data: [],
		},
		graphicWeight: {
			name: 'Calibre',
			year: [],
			data: [],
		},
		graphicPerformance: {
			name: 'Rendimiento',
			year: [],
			data: [],
		},
	},
];

export const RESPONSEFORM = [
	{
		dart: 800,
		twig: 200,
		flower: 200,
		fruitSet: 500,
		weight: 80,
		performance: 500,
	},
];

export const WEEKDAYS = [
	{ id: 1, name: 'Lun' },
	{ id: 2, name: 'Mar' },
	{ id: 3, name: 'Mie' },
	{ id: 4, name: 'Jue' },
	{ id: 5, name: 'Vie' },
	{ id: 6, name: 'Sab' },
	{ id: 0, name: 'Dom' },
];

export const YEARS = [
	{ id: 1, name: '2020' },
	{ id: 2, name: '2021' },
	{ id: 3, name: '2022' },
];

export const DATATABLE = [
	{
		id: 1,
		orchard: 'Santa Margarita',
		cc: '10012-1',
		quarter: '2001',
		variety: 'Bing',
		season: '2020',
		dart: 6800,
		twig: 31,
		flowerDart: 11.8,
		budDart: 4.91,
		flowerTwig: 15.4,
		flowerHa: 7549243.2,
		fruitSet: 10.4555,
		caliber: 10.15,
		date: '12-12-2020',
		performanceReal: 8159,
		estimatedFruitSet: 12,
		estimatedCaliber: 10,
		estimatedPerformance: 9323.567,
	},
	{
		id: 2,
		orchard: 'Santa Margarita',
		cc: '10012-2',
		quarter: '2002',
		variety: 'Bing',
		season: '2021',
		dart: 680,
		twig: 31,
		flowerDart: 11.8,
		budDart: 4.91,
		flowerTwig: 15.4,
		flowerHa: 7549243.2,
		fruitSet: 10.4555,
		caliber: 10.15,
		date: '12-12-2021',
		performanceReal: 8159,
		estimatedFruitSet: 12,
		estimatedCaliber: 10,
		estimatedPerformance: 9323.567,
	},
];

export const DATAMARKERS = [
	{
		name: 'Almahue | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -34.455944,
			lng: -71.291627,
		},
	},
	{
		name: 'Bodega | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -35.81092314,
			lng: -71.80201494,
		},
	},
	{
		name: 'Ceresur | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -39.370582,
			lng: -72.505756,
		},
	},
	{
		name: 'Chumpirro | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -37.77290943,
			lng: -72.5843856,
		},
	},
	{
		name: 'El Condor | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -34.88096199,
			lng: -71.01961733,
		},
	},
	{
		name: 'El Descanso | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -35.01339994,
			lng: -71.36233773,
		},
	},
	{
		name: 'El Espino | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -35.69074401,
			lng: -71.4423072,
		},
	},
	{
		name: 'El Parque | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -33.99713384,
			lng: -70.70206349,
		},
	},
	{
		name: 'El Recurso | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -34.00599633,
			lng: -70.71435889,
		},
	},
	{
		name: 'El Retiro | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -34.34474335,
			lng: -70.84801793,
		},
	},
	{
		name: 'El Rosario | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -35.91422153,
			lng: -71.49394827,
		},
	},
	{
		name: 'Entre Rios | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -34.7391402,
			lng: -70.80270549,
		},
	},
	{
		name: 'La Esperanza | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -34.31931041,
			lng: -71.26687654,
		},
	},
	{
		name: 'La Morera | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -34.10190105,
			lng: -70.67467837,
		},
	},
	{
		name: 'La Robleria | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -33.87383225,
			lng: -70.92042349,
		},
	},
	{
		name: 'Las Cabras | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -34.27657419,
			lng: -71.34238647,
		},
	},
	{
		name: 'Los Encinos | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968dba',
		location: {
			lat: -33.71408143,
			lng: -71.23883086,
		},
	},
	{
		name: 'Los Maitenes | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -34.21266843,
			lng: -71.25820505,
		},
	},
	{
		name: 'Los Toldos | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -37.85833076,
			lng: -73.00752786,
		},
	},
	{
		name: 'Mallorca | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -35.10284322,
			lng: -71.30962134,
		},
	},
	{
		name: 'Puente Negro | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -34.71333829,
			lng: -70.84152263,
		},
	},
	{
		name: 'Rio Claro | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -34.69819235,
			lng: -70.85646477,
		},
	},
	{
		name: 'San Hernán | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -34.059298,
			lng: -70.739569,
		},
	},
	{
		name: 'Santa Margarita | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -33.9963821,
			lng: -70.69268534,
		},
	},
	{
		name: 'Santa Mónica | Garces Fruit',
		id: '62ae66c1b2ebc5dd2b968d00',
		location: {
			lat: -34.117905,
			lng: -70.792457,
		},
	},
];

export const GANTT = [
	{
		name: 'Almahue',
		id: '62ae66c1b2ebc5dd2b968d00',
		categories: [
			'GP2',
			'Santina',
			'Kordia',
			'Rainier',
			'Bing',
			'Lapins',
			'Skeena',
		],
		data: [
			{
				myData: 'GP2',
				x2: Date.parse('2022-11-08'),
				x: Date.parse('2022-11-01'),
				t: 19.28,
				degreeDays: 288.42601500000006,
				median: '287.755',
				y: 0,
			},
			{
				myData: 'Santina',
				x2: Date.parse('2022-11-23'),
				x: Date.parse('2022-11-16'),
				t: 573.74,
				degreeDays: 417.5561849999999,
				median: '410.61',
				y: 1,
			},
			{
				myData: 'Kordia',
				x2: Date.parse('2022-11-27'),
				x: Date.parse('2022-11-20'),
				t: 0,
				degreeDays: 454.83668499999993,
				median: '445.96',
				y: 2,
			},
			{
				myData: 'Rainier',
				x2: Date.parse('2022-12-01'),
				x: Date.parse('2022-11-24'),
				t: 45.53,
				degreeDays: 488.7611849999999,
				median: '483.3875',
				y: 3,
			},
			{
				myData: 'Bing',
				x2: Date.parse('2022-12-05'),
				x: Date.parse('2022-11-28'),
				t: 0,
				degreeDays: 524.7901849999998,
				median: '518.3934',
				y: 4,
			},
			{
				myData: 'Lapins',
				x2: Date.parse('2022-12-07'),
				x: Date.parse('2022-11-30'),
				t: 1037.25,
				degreeDays: 543.7151849999998,
				median: '539.2034',
				y: 5,
			},
			{
				myData: 'Skeena',
				x2: Date.parse('2022-12-10'),
				x: Date.parse('2022-12-03'),
				t: 0,
				degreeDays: 573.3446849999998,
				median: '572.8725',
				y: 6,
			},
		],
	},
	{
		name: 'Bodega',
		id: '62ae66c1b2ebc5dd2b968d01',
		categories: ['Santina', 'Bing', 'Regina', 'Lapins', 'Skeena'],
		data: [
			{
				myData: 'Santina',
				x2: Date.parse('2022-11-27'),
				x: Date.parse('2022-11-20'),
				t: 515.03,
				degreeDays: 414.3240500000001,
				median: '410.61',
				y: 0,
			},
			{
				myData: 'Bing',
				x2: Date.parse('2022-12-09'),
				x: Date.parse('2022-12-02'),
				t: 62.55,
				degreeDays: 521.55555,
				median: '518.3934',
				y: 1,
			},
			{
				myData: 'Regina',
				x2: Date.parse('2022-12-11'),
				x: Date.parse('2022-12-04'),
				t: 617.05,
				degreeDays: 540.46905,
				median: '537.505',
				y: 2,
			},
			{
				myData: 'Lapins',
				x2: Date.parse('2022-12-11'),
				x: Date.parse('2022-12-04'),
				t: 25.47,
				degreeDays: 540.46905,
				median: '539.2034',
				y: 3,
			},
			{
				myData: 'Skeena',
				x2: Date.parse('2022-12-15'),
				x: Date.parse('2022-12-08'),
				t: 0,
				degreeDays: 577.14255,
				median: '572.8725',
				y: 4,
			},
		],
	},
	{
		name: 'El_Recurso',
		id: '62ae66c1b2ebc5dd2b968d02',
		categories: [
			'Santina',
			'Rainier',
			'Bing',
			'Sweetheart',
			'Lapins',
			'Skeena',
		],
		data: [
			{
				myData: 'Santina',
				x2: Date.parse('2022-11-16'),
				x: Date.parse('2022-11-09'),
				t: 28.53,
				degreeDays: 413.92117333333346,
				median: '410.61',
				y: 0,
			},
			{
				myData: 'Rainier',
				x2: Date.parse('2022-11-24'),
				x: Date.parse('2022-11-17'),
				t: 7.42,
				degreeDays: 493.5741733333335,
				median: '483.3875',
				y: 1,
			},
			{
				myData: 'Bing',
				x2: Date.parse('2022-11-27'),
				x: Date.parse('2022-11-20'),
				t: 0,
				degreeDays: 525.6959233333334,
				median: '518.3934',
				y: 2,
			},
			{
				myData: 'Sweetheart',
				x2: Date.parse('2022-11-28'),
				x: Date.parse('2022-11-21'),
				t: 0,
				degreeDays: 536.3529233333335,
				median: '527.6862',
				y: 3,
			},
			{
				myData: 'Lapins',
				x2: Date.parse('2022-11-29'),
				x: Date.parse('2022-11-22'),
				t: 93.07,
				degreeDays: 546.3909233333335,
				median: '539.2034',
				y: 4,
			},
			{
				myData: 'Skeena',
				x2: Date.parse('2022-12-02'),
				x: Date.parse('2022-11-25'),
				t: 60.62,
				degreeDays: 576.4159233333335,
				median: '572.8725',
				y: 5,
			},
		],
	},
	{
		name: 'Rio Claro',
		id: '62ae66c1b2ebc5dd2b968d0z',
		categories: ['Bing', 'Regina', 'Lapins'],
		data: [
			{
				myData: 'Bing',
				x2: Date.parse('2022-12-29'),
				x: Date.parse('2022-12-22'),
				t: 79.03,
				degreeDays: 526.5981400000001,
				median: '518.3934',
				y: 0,
			},
			{
				myData: 'Regina',
				x2: Date.parse('2022-12-31'),
				x: Date.parse('2022-12-24'),
				t: 497.35,
				degreeDays: 545.2311400000001,
				median: '537.505',
				y: 1,
			},
			{
				myData: 'Lapins',
				x2: Date.parse('2022-12-31'),
				x: Date.parse('2022-12-24'),
				t: 148.53,
				degreeDays: 545.2311400000001,
				median: '539.2034',
				y: 2,
			},
		],
	},
	{
		name: 'Los_Encinos',
		id: '62ae66c1b2ebc5dd2b968d04',
		categories: ['Santina', 'Lapins'],
		data: [
			{
				myData: 'Santina',
				x2: Date.parse('2022-11-23'),
				x: Date.parse('2022-11-16'),
				t: 0,
				degreeDays: 415.2413400000001,
				median: '410.61',
				y: 0,
			},
			{
				myData: 'Lapins',
				x2: Date.parse('2022-12-09'),
				x: Date.parse('2022-12-02'),
				t: 80.9,
				degreeDays: 544.59034,
				median: '539.2034',
				y: 1,
			},
		],
	},
	{
		name: 'El Retiro',
		id: '62ae66c1b2ebc5dd2b968d05',
		categories: ['Santina', 'Bing', 'Lapins'],
		data: [
			{
				myData: 'Santina',
				x2: Date.parse('2022-11-29'),
				x: Date.parse('2022-11-22'),
				t: 0,
				degreeDays: 414.174135,
				median: '410.61',
				y: 0,
			},
			{
				myData: 'Bing',
				x2: Date.parse('2022-12-11'),
				x: Date.parse('2022-12-04'),
				t: 0,
				degreeDays: 518.986805,
				median: '518.3934',
				y: 1,
			},
			{
				myData: 'Lapins',
				x2: Date.parse('2022-12-14'),
				x: Date.parse('2022-12-07'),
				t: 41.78,
				degreeDays: 545.231305,
				median: '539.2034',
				y: 2,
			},
		],
	},
	{
		name: 'La_Esperanza',
		id: '62ae66c1b2ebc5dd2b968d06',
		categories: ['Santina', 'Lapins', 'Regina'],
		data: [
			{
				myData: 'Santina',
				x2: Date.parse('2022-11-18'),
				x: Date.parse('2022-11-11'),
				t: 96.01,
				degreeDays: 419.0108333333333,
				median: '410.61',
				y: 0,
			},
			{
				myData: 'Lapins',
				x2: Date.parse('2022-12-02'),
				x: Date.parse('2022-11-25'),
				t: 0,
				degreeDays: 544.8858333333333,
				median: '539.2034',
				y: 1,
			},
			{
				myData: 'Regina',
				x2: Date.parse('2022-12-02'),
				x: Date.parse('2022-11-25'),
				t: 0,
				degreeDays: 544.8858333333333,
				median: '537.505',
				y: 2,
			},
		],
	},
	{
		name: 'Los Toldos',
		id: '62ae66c1b2ebc5dd2b968d07',
		categories: ['Regina', 'Sweetheart'],
		data: [
			{
				x: Date.parse('2023-01-15'),
				x2: Date.parse('2023-01-21'),
				y: 0,
				t: 0,
				myData: 'Regina',
				color: '#afcb51',
			},
			{
				x: Date.parse('2023-01-19'),
				x2: Date.parse('2023-01-25'),
				y: 1,
				t: 0,
				myData: 'Sweetheart',
				color: '#bd0823',
			},
		],
	},
];

export const SEASONS_MOCK = [
	{ label: '2015', value: '2015' },
	{ label: '2016', value: '2016' },
	{ label: '2017', value: '2017' },
	{ label: '2018', value: '2018' },
	{ label: '2019', value: '2019' },
	{ label: '2020', value: '2020' },
	{ label: '2021', value: '2021' },
	{ label: '2022', value: '2022' },
];

export const PROFILES = [
    {
        "id": 1,
		"value": 1,
        "name": "Admin",
        "__typename": "UserProfile"
    },
    {
        "id": 4,
		"value": 4,
        "name": "Alumno",
        "__typename": "UserProfile"
    },
    {
        "id": 6,
		"value": 6,
        "name": "Comisión",
        "__typename": "UserProfile"
    },
    {
        "id": 3,
		"value": 3,
        "name": "Postulante",
        "__typename": "UserProfile"
    },
    {
        "id": 5,
        "name": "Psicólogo",
        "__typename": "UserProfile"
    },
    {
        "id": 2,
		"value": 2,
        "name": "Secretaria",
        "__typename": "UserProfile"
    },
    {
        "id": 7,
		"value": 7,
        "name": "Tutor",
        "__typename": "UserProfile"
    }
]

export const USERS = [
    {
        "id": 53,
		"value": 53,
        "email": "lesalinas@ucn.cl",
        "rut": "15.052.730-9",
        "name": "Laura Eliana",
        "paternalLastname": "Salinas",
        "maternalLastname": "Díaz",
        "fullname": "Laura Eliana Salinas Díaz",
        "label": "Laura Eliana Salinas Díaz",
        "phone": "512206806",
        "profile": {
            "id": 5,
            "name": "Psicólogo",
            "__typename": "UserProfile"
        },
        "state": {
            "id": 1,
            "name": "Activo",
            "__typename": "UserState"
        },
        "specialty": [],
        "moduleUser": [
            {
                "id": 1,
                "name": "Usuario",
                "assigned": false,
                "process": [
                    {
                        "id": 1,
                        "name": "Agregar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 2,
                        "name": "Listar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 3,
                        "name": "Privilegios",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 2,
                "name": "Parámetros",
                "assigned": false,
                "process": [
                    {
                        "id": 4,
                        "name": "Modificar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 5,
                        "name": "Listar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 3,
                "name": "Formulario Postulación",
                "assigned": false,
                "process": [
                    {
                        "id": 6,
                        "name": "Formulario Postulación",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 4,
                "name": "Documentos Postulación",
                "assigned": false,
                "process": [
                    {
                        "id": 7,
                        "name": "Documentos Postulación",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 5,
                "name": "Cuenta",
                "assigned": true,
                "process": [
                    {
                        "id": 8,
                        "name": "Cuenta",
                        "assigned": true,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 6,
                "name": "Motivo Inasistencia",
                "assigned": false,
                "process": [
                    {
                        "id": 9,
                        "name": "Agregar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 10,
                        "name": "Listar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 7,
                "name": "Inasistencia",
                "assigned": false,
                "process": [
                    {
                        "id": 11,
                        "name": "Agregar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 12,
                        "name": "Listar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 8,
                "name": "Período Académico",
                "assigned": false,
                "process": [
                    {
                        "id": 13,
                        "name": "Agregar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 14,
                        "name": "Listar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 9,
                "name": "Especialidad",
                "assigned": false,
                "process": [
                    {
                        "id": 15,
                        "name": "Agregar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 16,
                        "name": "Listar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 10,
                "name": "Asignatura",
                "assigned": false,
                "process": [
                    {
                        "id": 17,
                        "name": "Agregar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 18,
                        "name": "Listar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 11,
                "name": "Malla Curricular",
                "assigned": false,
                "process": [
                    {
                        "id": 19,
                        "name": "Agregar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 20,
                        "name": "Listar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 12,
                "name": "Nota Final",
                "assigned": false,
                "process": [
                    {
                        "id": 21,
                        "name": "Agregar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 22,
                        "name": "Listar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 13,
                "name": "Concurso",
                "assigned": false,
                "process": [
                    {
                        "id": 23,
                        "name": "Agregar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 24,
                        "name": "Listar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            }
        ],
        "processUser": [
            {
                "id": 8,
                "name": "Cuenta",
                "module": {
                    "id": 5,
                    "name": "Cuenta",
                    "__typename": "UserModule"
                },
                "__typename": "UserProcess"
            }
        ],
        "password": null,
        "delete": true,
        "edit": true,
        "__typename": "User"
    },
    {
        "id": 5,
        "value": 5,
        "email": "psicologo@midominio.cl",
        "rut": "21.269.532-7",
        "name": "Psicólogo",
        "paternalLastname": "psicólogo paterno",
        "maternalLastname": "psicólogo materno",
        "fullname": "Psicólogo psicólogo paterno psicólogo materno",
        "label": "Psicólogo psicólogo paterno psicólogo materno",
        "phone": "944444444",
        "profile": {
            "id": 5,
            "name": "Psicólogo",
            "__typename": "UserProfile"
        },
        "state": {
            "id": 1,
            "name": "Activo",
            "__typename": "UserState"
        },
        "specialty": [],
        "moduleUser": [
            {
                "id": 1,
                "name": "Usuario",
                "assigned": false,
                "process": [
                    {
                        "id": 1,
                        "name": "Agregar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 2,
                        "name": "Listar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 3,
                        "name": "Privilegios",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 2,
                "name": "Parámetros",
                "assigned": false,
                "process": [
                    {
                        "id": 4,
                        "name": "Modificar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 5,
                        "name": "Listar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 3,
                "name": "Formulario Postulación",
                "assigned": false,
                "process": [
                    {
                        "id": 6,
                        "name": "Formulario Postulación",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 4,
                "name": "Documentos Postulación",
                "assigned": false,
                "process": [
                    {
                        "id": 7,
                        "name": "Documentos Postulación",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 5,
                "name": "Cuenta",
                "assigned": true,
                "process": [
                    {
                        "id": 8,
                        "name": "Cuenta",
                        "assigned": true,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 6,
                "name": "Motivo Inasistencia",
                "assigned": false,
                "process": [
                    {
                        "id": 9,
                        "name": "Agregar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 10,
                        "name": "Listar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 7,
                "name": "Inasistencia",
                "assigned": false,
                "process": [
                    {
                        "id": 11,
                        "name": "Agregar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 12,
                        "name": "Listar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 8,
                "name": "Período Académico",
                "assigned": false,
                "process": [
                    {
                        "id": 13,
                        "name": "Agregar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 14,
                        "name": "Listar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 9,
                "name": "Especialidad",
                "assigned": false,
                "process": [
                    {
                        "id": 15,
                        "name": "Agregar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 16,
                        "name": "Listar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 10,
                "name": "Asignatura",
                "assigned": false,
                "process": [
                    {
                        "id": 17,
                        "name": "Agregar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 18,
                        "name": "Listar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 11,
                "name": "Malla Curricular",
                "assigned": false,
                "process": [
                    {
                        "id": 19,
                        "name": "Agregar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 20,
                        "name": "Listar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 12,
                "name": "Nota Final",
                "assigned": false,
                "process": [
                    {
                        "id": 21,
                        "name": "Agregar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 22,
                        "name": "Listar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            },
            {
                "id": 13,
                "name": "Concurso",
                "assigned": false,
                "process": [
                    {
                        "id": 23,
                        "name": "Agregar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    },
                    {
                        "id": 24,
                        "name": "Listar",
                        "assigned": false,
                        "__typename": "UserProcessAssigned"
                    }
                ],
                "__typename": "UserModuleAssigned"
            }
        ],
        "processUser": [
            {
                "id": 8,
                "name": "Cuenta",
                "module": {
                    "id": 5,
                    "name": "Cuenta",
                    "__typename": "UserModule"
                },
                "__typename": "UserProcess"
            }
        ],
        "password": null,
        "delete": true,
        "edit": true,
        "__typename": "User"
    }
]

export const HUERTOS = [
	 {
			label: "Cliente 1",
			value: "cliente_1",
			orchard: [
				{label: "huerto 1", value: "huerto_1", id: "001"},
				{label: "huerto 2", value: "huerto_2", id: "002"}
			]
		}
]