import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
	// DataTableWidget,
	SaveAction,
	ButtonAction,
	Alert,
	// SelectMultipleWidget,
	// LoadingWidget,
	// TextFieldWidgetSelectController,
	TextFieldWidgetSelectControllerHorizontal,
	TextFieldWidgetText,
	CardHeaderWidget,
	BreadCrumbs,
	SnackBarsComponent,
	TextFieldWidgetUpload,
} from '../../../components';
import { Client, Orchards, Specie } from '../Options/OptionsPu';

import {
	// Accordion,
	// AccordionDetails,
	// AccordionSummary,
	// Divider,
	Card,
	CardActions,
	Button,
	CardContent,
	// Hidden,
	// Typography,
	useTheme,
	Snackbar,
} from '@material-ui/core';
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';
// import viewsTexts from '../../common/viewsTexts.json';
import { makeStyles } from '@material-ui/styles';
// import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { SimpleTabs, TabPanel } from 'components/TabPanel';
import viewsTexts from '../../../common/viewsTexts.json';
import {
	// numberFormat,
	// isNumber,
	// findActualYear,
	// alterArrayForSelectVariety,
	// getOrchardVarieties,
	// getOrchardVarietiesDeep,
	alterArrayAddLabels,
	alterArrayGetFields,
	formatRut,
} from '../../../helpers';
// import theme from 'theme';
import { FiltersContext } from '../../../context';
import { result } from 'lodash';
import { register } from 'serviceWorker';
import AlertMessages from 'components/Alert/Alert';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.tertiary.main,
		fontWeight: 500,
	},
	divider: {
		background: theme.palette.grey[400],
		marginTop: 10,
	},
	rootHelper: {
		fontSize: 17,
	},
	inputText: {
		fontSize: 26,
	},
}));

const MeExamine = (props) => {
	const { userId, userHuerto, userName, userOrchard } = useContext(Context);

	// console.log(userId, userHuerto, userName, "<<<<---context")
	const { state: filterState, addFilter } = useContext(FiltersContext);

	const {
		// orchard_filter,

		// cc_filter,
		// quarter_filter,
		// variety_filter,
		// season_filter,
		userOrchard_filter,
	} = filterState;

	// console.log('----', filterState);

	const axiosContext = useContext(AxiosContext);
	// axiosContext.authAxios //queries with token

	const [filterDataResponseState, setFilterDataResponseState] = useState(
		userOrchard_filter
	);

	const [loadingQueryState, setLoadingQueryState] = useState(false);
	const [successQueryState, setSuccessQueryState] = useState(false);

	const [profileDataState, setProfileDataState] = useState([]);
	const [loadingProfileDataState, setLoadingProfileDataState] = useState(
		false
	);
	const [seasonState, setSeasonState] = useState([]);
	const [rootStockDataState, setRootStockDataState] = useState([]);
	const [varietyDataState, setVarietyDataState] = useState([]);
	const [orchardsDataComboState, setOrchardsDataComboState] = useState([]);
	const [specieDataState, setSpecieDataState] = useState([]);
	const [clientDataState, setClientDataState] = useState([]);
	const [agroBusinessDataState, setAgroBusinessDataState] = useState([]);
	const [ccDataState, setCcDataState] = useState([]);
	const [alertOpen, setAlertOpen] = useState(false);

	const [errorState, setErrorState] = useState(false);

	const [allOrchardsDataState, setAllOrchardsDataState] = useState([]);

	const [showAlert, setShowAlert] = useState(false);

	const [filtersDataState, setFiltersDataState] = useState(null);
	const [loadingFiltersState, setLoadingFiltersState] = useState(false);

	const [showSelectController, setShowSelectController] = useState(false);

	const theme = useTheme();

	// console.log(theme)

	const classes = useStyles();

	const [valueTabState, setValueTabState] = useState(0);
	const [file, setFile] = useState(null);

	const handleChangeTab = (event, newValue) => {
		setValueTabState(newValue);
	};

	const {
		handleSubmit,
		control,
		watch,
		reset,
		// setValue,
		getValues,
		// setError,
		// clearErrors,
		formState: { errors },
	} = useForm();

	// const watchRut = watch('rut');
	const watchClient = watch('client');
	const watchAgroBusiness = watch('agroBusiness');
	const watchOrchard = watch('orchard');
	const watchSpecie = watch('specieValue');
	const watchVariety = watch('varietyValue');
	const watchRootStock = watch('rootStockValue');

	const onSubmit = async (values) => {
		console.log(values, '<<<--------');
		setLoadingQueryState(true);
		setAlertOpen(false);

		// saca los objetos
		let clientObj = clientDataState.filter(
			(ele) => ele.value === values.client
		);

		clientObj = alterArrayGetFields(clientObj, ['id', 'label', 'value']);

		let orchardObj = orchardsDataComboState.filter(
			(ele) => ele.value === values.orchard
		);

		orchardObj = alterArrayGetFields(orchardObj, ['id', 'label', 'value']);

		let agroBusinessObj = agroBusinessDataState.filter(
			(ele) => ele.value === values.agroBusiness
		);

		agroBusinessObj = alterArrayGetFields(agroBusinessObj, [
			'id',
			'label',
			'value',
		]);

		let specieObj = specieDataState.filter(
			(ele) => ele.value === values.specieObj
		);

		specieObj = alterArrayGetFields(specieObj, ['id', 'label', 'value']);

		const formData = new FormData();
		formData.append('file', file);

		// Agrega el resto de los datos del formulario a formData
		for (const key in values) {
			formData.append(key, values[key]);
		}

		console.log(formData, '<<----formData');

		const query = {
			// rut: formatRut(values.rut),
			client: clientObj[0].label,
			clientValue: values.client,
			orchard: orchardObj[0].label,
			orchardValue: values.orchard,
			username: userName,
			//confirmed: values.confirmed,

			// email: values.email,
			// maternalLastname: values.maternalLastname,
		};

		// console.log(query, 'envia a create');

		try {
			const response = await axiosContext.authAxios.post(
				'api-task/productive-unit/update-productive-unit',
				query,
				{
					timeout: 360000, // Aquí puedes ajustar el tiempo de espera en milisegundos (en este ejemplo, 5 segundos)
				}
			);
			const { data, status } = response;

			// 	// setProfileSelectedState('');
			// 	console.log(response, '<-----response');
			// 	// let response;
			console.log(response, '------response');
			//setAlertOpen(true);
			if (response.status === 200) {
				setAlertOpen(true);
				setSuccessQueryState(true);
			} else {
				setAlertOpen(false);
			}

			// setProfileSelectedState('');

			setLoadingQueryState(false);
		} catch (err) {
			// console.log('cae al error', err);
			// console.log('Error', err.message);
			console.log('Error', err.request.status);
			if (err.request.status === 500) {
				setErrorState(true);
			}
			setAlertOpen(false);
			setLoadingQueryState(false);
		}
		console.log(successQueryState, 'successQueryState');
		console.log(alertOpen, 'alertOpen');
	};

	const { password } = getValues();

	useEffect(() => {
		if (successQueryState) {
			setAlertOpen(true);
		} else {
			setAlertOpen(false);
		}
	}, [successQueryState]);

	// endpoints
	useEffect(() => {
		getDataFiltersAsync();
	}, []);

	useEffect(() => {
		if (watchClient) {
			const resultClient = clientDataState.find(
				(ele) => ele.value === watchClient
			);
			setAgroBusinessDataState(
				alterArrayAddLabels(resultClient.agroBusinessData, [
					'agroBusinessValue',
					'agroBusiness',
				])
			);
			console.log('resultado cliente', resultClient);
		}
	}, [watchClient]);

	useEffect(() => {
		console.log(
			watchClient,
			watchAgroBusiness,
			'-----watchClient, watchAgrobusiness'
		);
		if (watchClient && watchAgroBusiness) {
			const resultAgrobusiness = agroBusinessDataState.find(
				(ele) => ele.value === watchAgroBusiness
			);

			console.log(resultAgrobusiness, '<<----resultAgrobusiness');

			if (resultAgrobusiness) {
				setOrchardsDataComboState(
					alterArrayAddLabels(resultAgrobusiness.orchardData, [
						'orchardValue',
						'orchard',
					])
				);
			} else {
				setOrchardsDataComboState([]);
			}
		} else {
			setOrchardsDataComboState([]);
		}
	}, [watchAgroBusiness, agroBusinessDataState]);

	useEffect(() => {
		// Detectar cambios en el valor del radio button
		if (watch('credentialValue') === 'si') {
			setShowSelectController(true);
		} else {
			setShowSelectController(false);
		}
	}, [watch('credentialValue')]);

	// endpoints
	useEffect(() => {
		getDataFiltersAsync();
	}, []);

	const getDataFiltersAsync = async () => {
		setLoadingFiltersState(true);
		try {
			let response = await axiosContext.authAxios.post(
				'v1/unitProductive/filter-create'
			);

			const { data, status } = response;

			if (status === 200) {
				setFiltersDataState(data);
			}
		} catch (error) {
			console.log(error);
			// setLoadingVarietyFilterState(false);
		} finally {
			setLoadingFiltersState(false);
		}
	};

	console.log(filtersDataState, '<<----filtersDataState');

	useEffect(() => {
		if (filtersDataState) {
			setClientDataState(
				alterArrayAddLabels(filtersDataState.clientData, [
					'clientValue',
					'client',
				])
			);
		}
	}, [filtersDataState]);

	const handleCloseSnackBarsComponent = () => {
		setErrorState(false);
	};

	return (
		<div className="form-style">
			<BsContainer fluid class="px-0 px-md-3">
				<BsRow class="no-gutters">
					<BsCol class="col-md-12">
						<Card elevation={0}>
							<CardHeaderWidget
								title={viewsTexts.titles.massiveEdition.title}
								subheader={<BreadCrumbs subTitle="" />}
							/>
							{/* <Divider /> */}
							<CardContent>
								<BsRow>
									<BsCol class="col-md-12">
										<form
											autoComplete="on"
											onSubmit={handleSubmit(onSubmit)}
										>
											<BsRow>
												<BsCol class="col-md-6">
													<TextFieldWidgetSelectControllerHorizontal
														options={
															clientDataState
														}
														titleOption="label"
														control={control}
														name="client"
														labeltext="Cliente"
														errors={errors.client}
														defaultValue=""
														loading={false}
														req
														withDefault
														variant="outlined"
													/>
												</BsCol>
												<BsCol class="col-md-6">
													<TextFieldWidgetSelectControllerHorizontal
														options={
															agroBusinessDataState
														}
														titleOption="label"
														control={control}
														name="agroBusiness"
														labeltext="Agrícola"
														errors={
															errors.agroBusiness
														}
														multiple={false}
														defaultValue=""
														loading={false}
														req
														withDefault
														variant="outlined"
													/>
												</BsCol>
											</BsRow>
											<BsRow>
												<BsCol class="col-md-6">
													<TextFieldWidgetSelectControllerHorizontal
														options={
															orchardsDataComboState
														}
														titleOption="label"
														control={control}
														name="orchard"
														labeltext="Huerto"
														errors={errors.orchard}
														multiple={false}
														defaultValue=""
														loading={false}
														req
														withDefault
														variant="outlined"
													/>
												</BsCol>
											</BsRow>
											<BsRow class="mt-2 d-flex justify-content-center">
												<ButtonAction
													labelButton="Limpiar"
													color="tertiary"
													size="large"
													className="roundedButton ml-md-2"
													variant="contained"
													style={{
														border:
															'10px solid black !important',
													}}
													disabled={loadingQueryState}
													actionOnClick={() => {
														handleSubmit(onSubmit);
														reset();
													}}
												/>

												<SaveAction
													title="Desbloquear archivo"
													color="secondary"
													size="large"
													className="roundedButton ml-md-2"
													variant="contained"
													disabled={loadingQueryState}
													messageType="add"
													mutationLoading={
														loadingQueryState
													}
												/>
											</BsRow>
											<BsRow class="mt-5">
												<BsCol class="col-md-12"></BsCol>
											</BsRow>
										</form>
									</BsCol>
								</BsRow>

								{errorState && (
									<Snackbar
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'center',
										}}
										open={errorState}
										onClose={() => setErrorState(false)}
										autoHideDuration={4500}
										key={Math.random()}
									>
										<SnackBarsComponent
											variant="error"
											message="No hay archivo asociado a los datos ingresados"
											handleClose={
												handleCloseSnackBarsComponent
											}
										/>
									</Snackbar>
								)}
								{successQueryState && alertOpen && (
									<div>
										<AlertMessages
											severity="warning"
											alertTitle="warning_link"
											closeFunc={() =>
												setAlertOpen(false)
											}
										/>
										<BsRow class="mt-2 d-flex justify-content-center">
											<ButtonAction
												labelButton="Confirmar y guardar"
												action={() => {
													/* acción del primer botón */
												}}
												color="tertiary"
												size="large"
												className="roundedButton ml-md-2"
												variant="contained"
												style={{
													border: '1px solid black',
												}}
											/>
											<ButtonAction
												labelButton="Validar cambios"
												action={() => {
													/* acción del segundo botón */
												}}
												color="secondary"
												size="large"
												className="roundedButton ml-md-2"
												variant="contained"
											/>
										</BsRow>
									</div>
								)}
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};
export default MeExamine;
