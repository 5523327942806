import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import classes from './TreeViewWidget.module.scss';
import { IsDesktopHandler } from '../../helpers';
import errorMessage from '../../common/errorMessages.json';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    height: 216,
    flexGrow: 1,
    maxWidth: 400,
    marginTop: 30
    // marginBottom: -170
  }
});

const TreeViewWidget = props => {
  const classes = useStyles();
  const { register, errors } = props;
  const [stateMessage, setStateMessage] = useState('');
  const [stateError, setStateError] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  useEffect(() => {
    if (errors) {
      switch (errors.type) {
        case 'required':
          setStateMessage(
            errorMessage.message[errors.ref.name].required
              ? errorMessage.message[errors.ref.name].required
              : errorMessage.message[errors.ref.name]
          );
          break;
        case 'sizeFileValidate':
          setStateMessage('error en el doc, de peso');
          break;
        case 'typeFileValidate':
          setStateMessage('error en el doc, de tipo');
          break;
        default:
          break;
      }
      setStateError(true);
    } else {
      setStateMessage('');
      setStateError(false);
    }
  }, [errors]);

  //   validaciones
  let pattern = null;
  let validate = null;
  if (
    props.name === 'CNIsimpleCopy' ||
    props.name === 'updatedCurriculumVitae' ||
    props.name === 'presentationLetter' ||
    props.name === 'documentNationalMedicalRating' ||
    props.name === 'nationalMedicalExam' ||
    props.name === 'eunacomAproved' ||
    props.name === 'certificateOfTitle' ||
    props.name === 'superintendencyRegistrationCertificate' ||
    props.name === 'hepatitisCertificate' ||
    props.name === 'secureAffiliationCertificate'
  ) {
    validate = {
      sizeFileValidate(value) {
        if (!value[0]) {
          return true;
        } else {
          return value[0].size < 3145728;
        }
      },
      typeFileValidate(value) {
        if (!value[0]) {
          return true;
        } else {
          return (
            value[0].type === 'application/pdf' ||
            value[0].type === 'image/jpeg' ||
            value[0].type === 'image/png'
          );
        }
      }
    };
  }

  if (
    props.name === 'pediatricInternship' ||
    props.name === 'gynecologyInternship' ||
    props.name === 'surgeryInternship' ||
    props.name === 'nationalMedicalRating' ||
    props.name === 'punctuationEunacom'
  ) {
    pattern = /^(10|\d([.,]\d{1,2})?)$/;
  }

  if (props.name === 'password2' && props.equalto) {
    validate = {
      matchesPreviousPassword: value => props.equalto === value
    };
  }

  let labelText = null;
  const isDesktop = IsDesktopHandler('sm');
  if (!isDesktop) {
    labelText = props.labeltext;
  } else {
    labelText = null;
  }

  return (
    <div className={classes.inputContainerStyle}>
      <div className={classes.labelDesktopStyle}>
        {props.labeltext} {props.download}
      </div>
      {props.loading && <p>cargando</p>}
      {/* // <div> */}
      {/* <TextField
        variant="outlined"
        {...others}
        margin="dense"
        fullWidth
        label={labelText}
        disabled={disabled}
        onChange={props.onChange}
        inputRef={register({
          required: props.req,
          maxLength: props.maxLength,
          minLength: props.minLength,
          pattern: pattern,
          validate: validate
        })}
        error={stateError}
        helperText={stateMessage}
      /> */}
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        inputRef={register({
          required: props.req
        })}
        error={stateError}
        helperText={stateMessage}>
        <TreeItem nodeId="1" label="Application">
          <TreeItem nodeId="2" label="Calendar" />
          <TreeItem nodeId="3" label="Chrome" />
          <TreeItem nodeId="4" label="Webstorm" />
        </TreeItem>
        <TreeItem nodeId="5" label="Documents">
          <TreeItem nodeId="6" label="Material-UI">
            <TreeItem nodeId="7" label="src">
              <TreeItem nodeId="8" label="index.js" />
              <TreeItem nodeId="9" label="tree-view.js" />
            </TreeItem>
          </TreeItem>
        </TreeItem>
      </TreeView>
    </div>
  );
};

TreeViewWidget.propTypes = {
  name: PropTypes.string.isRequired,
  req: PropTypes.bool,
  labeltext: PropTypes.string.isRequired,
  loading: PropTypes.bool
};

export default TreeViewWidget;
