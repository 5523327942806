import React, {useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
	// DataTableWidget,
	SaveAction,
	// SelectMultipleWidget,
	// LoadingWidget,
	// TextFieldWidgetSelectController,
	TextFieldWidgetSelectControllerHorizontal,
	TextFieldWidgetText,
	CardHeaderWidget,
	BreadCrumbs,
	SnackBarsComponent,
	RadioGroupWidget,
} from '../../../components';




import {
	// Accordion,
	// AccordionDetails,
	// AccordionSummary,
	// Divider,
	Card,
	CardContent,
	// Hidden,
	// Typography,
	useTheme,
	Snackbar,
	Radio,
	FormControlLabel,
} from '@material-ui/core';
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';
// import viewsTexts from '../../common/viewsTexts.json';
import { makeStyles } from '@material-ui/styles';
// import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { SimpleTabs, TabPanel } from 'components/TabPanel';
import viewsTexts from '../../../common/viewsTexts.json';
import {
	// numberFormat,
	// isNumber,
	// findActualYear,
	// alterArrayForSelectVariety,
	// getOrchardVarieties,
	// getOrchardVarietiesDeep,
	alterArrayGetFields,
	formatRut,
} from '../../../helpers';
// import theme from 'theme';
import { FiltersContext } from '../../../context';
import { result } from 'lodash';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.tertiary.main,
		fontWeight: 500,
	},
	divider: {
		background: theme.palette.grey[400],
		marginTop: 10,
	},
	rootHelper: {
		fontSize: 17,
	},
	inputText: {
		fontSize: 26,
	},
}));

const CmAdd = (props) => {
	const { userId, userHuerto, userName, userOrchard } = useContext(Context);

	// console.log(userId, userHuerto, userName, "<<<<---context")
	const { state: filterState, addFilter } = useContext(FiltersContext);

	const {
		// orchard_filter,
		// cc_filter,
		// quarter_filter,
		// variety_filter,
		// season_filter,
		userOrchard_filter,
	} = filterState;

	// console.log('----', filterState);

	const axiosContext = useContext(AxiosContext);
	// axiosContext.authAxios //queries with token

	const [filterDataResponseState, setFilterDataResponseState] = useState(
		userOrchard_filter
	);

	const [loadingQueryState, setLoadingQueryState] = useState(false);
	const [successQueryState, setSuccessQueryState] = useState(false);

	const [profileDataState, setProfileDataState] = useState([]);
	const [loadingProfileDataState, setLoadingProfileDataState] = useState(
		false
	);
	const [seasonState, setSeasonState] = useState([]);
	const [rootStockDataState, setRootStockDataState] = useState([]);
	const [varietyDataState, setVarietyDataState] = useState([]);
	const [orchardsDataComboState, setOrchardsDataComboState] = useState([]);
	const [specieDataState, setSpecieDataState] = useState([]);
	const [clientDataState, setClientDataState] = useState([]);
	const [ccDataState, setCcDataState] = useState([]);

	const [errorState, setErrorState] = useState(false);

	const [allOrchardsDataState, setAllOrchardsDataState] = useState([]);


	const theme = useTheme();

	// console.log(theme)

	const classes = useStyles();

	const [valueTabState, setValueTabState] = useState(0);

	const handleChangeTab = (event, newValue) => {
		setValueTabState(newValue);
	};

	const {
		handleSubmit,
		control,
		watch,
		reset,
		// setValue,
		getValues,
		// setError,
		// clearErrors,
		formState: { errors },
	} = useForm();

	// const watchRut = watch('rut');
	const watchClient = watch('client');
	const watchOrchard = watch('orchardValue');
	const watchSpecie = watch('specieValue');
	const watchVariety = watch('varietyValue');
	const watchRootStock = watch('rootStockValue');

	const onSubmit = async (values) => {
		console.log(values, '<<<--------');
		setLoadingQueryState(true);

		// saca los objetos
		let clientObj = clientDataState.filter(
			(ele) => ele.value === values.client
		);

		const query = {
			// rut: formatRut(values.rut),
			client: values.client,
			// email: values.email,
			// maternalLastname: values.maternalLastname,
		};

		// console.log(query, 'envia a create');
		try {
			const response = await axiosContext.authAxios.post(
				'v1/client/create-client-platform',
				query
			);
			const bodyInfo = response.data;

			// 	// setProfileSelectedState('');
			// 	console.log(response, '<-----response');
			// 	// let response;
			
			console.log(response, '------response');

			if (response.status === 200) {
				// alert('listo');
				setSuccessQueryState(true);
				console.log(response.data, '------data');

				reset({

				});
			}

			// setProfileSelectedState('');

			setLoadingQueryState(false);
		} catch (err) {
			// console.log('cae al error', err);
			// console.log('Error', err.message);
			console.log('Error', err.request.status);
			if (err.request.status === 500) {
				setErrorState(true);
			}

			setLoadingQueryState(false);
		}
	};


	const { password } = getValues();


	const getClientsAsync = async () => {
		try {
			let response = await axiosContext.authAxios.post(
				'v1/client/list-clients'
			);


			const { data, status } = response;

			if (status === 200) {
				setClientDataState(data.data);

			}
		} catch (error) {
			console.log(error);
			// setLoadingVarietyFilterState(false);
		}
	};
	// endpoints
	useEffect(() => {
		getClientsAsync();

	}, []);




	const handleCloseSnackBarsComponent = () => {
		setErrorState(false);
	};

	return (
		<div className="form-style">
			<BsContainer fluid class="px-0 px-md-3">
				<BsRow class="no-gutters">
					<BsCol class="col-md-12">
						<Card elevation={0}>
							<CardHeaderWidget
								title={viewsTexts.titles.client.title}
								subheader={<BreadCrumbs subTitle="" />}
							/>
							{/* <Divider /> */}
							<CardContent>
								<BsRow>
									<BsCol class="col-md-12">
										<form
											autoComplete="on"
											onSubmit={handleSubmit(onSubmit)}
										>
											<BsRow>
												<BsCol class="col-md-6">
													<TextFieldWidgetText
														labeltext={
															viewsTexts.forms
																.client
														}
														InputLabelProps={{
															shrink: true,
														}}
														minLength={3}
														maxLength={100}
														control={control}
														name="client"
														errors={errors.client}
														defaultValue=""
														req
														autoComplete="cliente"
														variant="outlined"
													/>
												</BsCol>
												
											</BsRow>
											<BsRow class="mt-5">
												<BsCol class="col-md-12 text-center">
													<SaveAction
														title="Guardar"
														color="secondary"
														size="large"
														className="roundedButton ml-md-2"
														variant="contained"
														disabled={
															loadingQueryState
														}
														messageType="add"
														mutationLoading={
															loadingQueryState
														}
														success={
															successQueryState
														}
													/>
												</BsCol>
											</BsRow>							
										</form>
									</BsCol>
								</BsRow>
								{errorState && (
									<Snackbar
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'center',
										}}
										open={errorState}
										onClose={() => setErrorState(false)}
										autoHideDuration={4500}
										key={Math.random()}
									>
										<SnackBarsComponent
											variant="error"
											message="El nombre del cliente ya existe"
											handleClose={
												handleCloseSnackBarsComponent
											}
										/>
									</Snackbar>
								)}
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};
export default CmAdd;
