import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
	// DataTableWidget,
	SaveAction,
	SelectMultipleWidget,
	// LoadingWidget,
	// TextFieldWidgetSelectController,
	// TextFieldWidgetSelectControllerHorizontal,
	// TextFieldWidgetText,
	CardHeaderWidget,
	BreadCrumbs,
	SnackBarsComponent,
	CheckboxWidgetControlled,
	CheckboxWidgetUncontrolled,
} from '../../../components';
import {
	// Accordion,
	// AccordionDetails,
	// AccordionSummary,
	// Divider,
	Card,
	CardContent,
	Hidden,
	Typography,
	useTheme,
	Snackbar,
} from '@material-ui/core';
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';
// import viewsTexts from '../../common/viewsTexts.json';
import { makeStyles } from '@material-ui/styles';
// import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
// import { SimpleTabs, TabPanel } from 'components/TabPanel';
import viewsTexts from '../../../common/viewsTexts.json';
import {
	// numberFormat,
	// isNumber,
	// findActualYear,
	// alterArrayForSelectVariety,
	// getOrchardVarieties,
	// getOrchardVarietiesDeep,
	// alterArrayGetFields,
	// formatRut,
	// alterArrayForMultiUsers,
	// alterArrayForMulti,
	alterArrayAddAssigned,
} from '../../../helpers';
// import theme from 'theme';
import { FiltersContext } from '../../../context';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.tertiary.main,
		fontWeight: 500,
	},
	divider: {
		background: theme.palette.grey[400],
		marginTop: 10,
	},
	rootHelper: {
		fontSize: 17,
	},
	inputText: {
		fontSize: 26,
	},
	labels: {
		display: 'flex',
		marginTop: 10,
		minWidth: '20%',
		alignItems: 'center',
	},
}));

const DataAdd = (props) => {
	const { userId, userHuerto, userName, userOrchard } = useContext(Context);

	// console.log(userId, userHuerto, userName, "<<<<---context")
	const { state: filterState, addFilter } = useContext(FiltersContext);

	const {
		// orchard_filter,
		// cc_filter,
		// quarter_filter,
		// variety_filter,
		// season_filter,
		userOrchard_filter,
	} = filterState;

	// console.log('----', filterState);

	const axiosContext = useContext(AxiosContext);
	// axiosContext.authAxios //queries with token

	const [filterDataResponseState, setFilterDataResponseState] = useState(
		userOrchard_filter
	);

	const [loadingQueryState, setLoadingQueryState] = useState(false);
	const [successQueryState, setSuccessQueryState] = useState(false);

	const [orchardsDataComboState, setOrchardsDataComboState] = useState([]);
	const [loadingProfileDataState, setLoadingProfileDataState] = useState(
		false
	);
	const [clientDataState, setClientDataState] = useState([]);
	const [agroBusinessDataState, setAgroBusinessDataState] = useState([]);

	const [errorState, setErrorState] = useState(false);

	const theme = useTheme();

	// console.log(theme)

	const classes = useStyles();

	const [valueTabState, setValueTabState] = useState(0);

	const handleChangeTab = (event, newValue) => {
		setValueTabState(newValue);
	};

	const {
		handleSubmit,
		control,
		watch,
		reset,
		setValue,
		getValues,
		setError,
		clearErrors,
		formState: { errors },
	} = useForm();

	// const watchRut = watch('rut');
	const watchBdCollection = watch('bdCollection');
	const watchRecoMuestral = watch('recoMuestral');

	const onSubmit = async (values) => {
		console.log(values, '<<<--------');
		// setLoadingQueryState(true);

		// console.log(clientObj, agroBusinessArray, '----------');

		if (!values.bdCollection && !values.recoMuestral) {
			setError('neitherItemlist', {
				type: 'manual',
				message:
					'Debe seleccionar al menos Bd Collection o Reco Muestral',
			});
			return;
		}

		const query = {
			orchard: values.orchardcombo,
			bdCollection: values.bdCollection,
			recoMuestral: values.recoMuestral,
			userId: userId,
		};

		console.log(query, 'envia a create');
		// try {
		// 	const response = await axiosContext.authAxios.post(
		// 		'v1/user/create-user',
		// 		query
		// 	);
		// 	const bodyInfo = response.data;

		// 	if (response.status === 200) {
		// 		// alert('listo');
		// 		setSuccessQueryState(true);

		// 		reset({
		// 			orchardcombo: '',
		// 			bdCollection: '',
		// 			recoMuestral: '',
		// 		});
		// 	}

		// 	setLoadingQueryState(false);
		// } catch (err) {
		// 	// console.log('cae al error', err);
		// 	// console.log('Error', err.message);
		// 	console.log('Error', err.request.status);
		// 	if (err.request.status === 500) {
		// 		setErrorState(true);
		// 	}

		// 	setLoadingQueryState(false);
		// }
	};

	// useEffect(() => {
	// 	if (watchRut && watchRut.length > 3) {
	// 		setValue('rut', formatRut(watchRut));
	// 	}
	// }, [watchRut, setValue]);

	useEffect(() => {
		if (watchBdCollection || watchRecoMuestral) {
			clearErrors('neitherItemlist');
		}
	}, [watchBdCollection, watchRecoMuestral]);

	const { password } = getValues();

	// endpoints
	useEffect(() => {
		const arrayOrchardChecks = alterArrayAddAssigned(userOrchard_filter);

		setOrchardsDataComboState(arrayOrchardChecks);
	}, []);

	const handleCloseSnackBarsComponent = () => {
		setErrorState(false);
	};

	return (
		<div className="form-style">
			<BsContainer fluid class="px-0 px-md-3">
				<BsRow class="no-gutters">
					<BsCol class="col-md-12">
						<Card elevation={0}>
							<CardHeaderWidget
								title={viewsTexts.titles.data.title}
								subheader={<BreadCrumbs subTitle="" />}
							/>
							{/* <Divider /> */}
							<CardContent>
								<BsRow>
									<BsCol class="col-md-12">
										{errors.neitherItemlist && (
											<p
												style={{
													color:
														theme.palette.error
															.main,
												}}
											>
												{errors.neitherItemlist.message}
											</p>
										)}
										<form
											autoComplete="on"
											onSubmit={handleSubmit(onSubmit)}
										>
											<BsRow>
												<BsCol class="col-md-4">
													<SelectMultipleWidget
														errors={
															errors.orchardcombo
														}
														loading={false}
														control={control}
														menuPortalTarget={
															document.body
														}
														req
														closeMenuOnSelect={
															false
														}
														labeltext="Huerto/s"
														name="orchardcombo"
														isSearchable
														defaultValue=""
														options={
															orchardsDataComboState
														}
														placeholder="Seleccione Huerto"
													/>
												</BsCol>
												<BsCol class="col-md-4">
													<label
														key="bdCollection"
														className={
															classes.labels
														}
													>
														<CheckboxWidgetUncontrolled
															name="bdCollection"
															color="primary"
															control={control}
															errors={
																errors.bdCollection
															}
														/>
														<Typography
															// color="textSecondary"
															variant="body1"
														>
															Bd Collection
														</Typography>
													</label>
												</BsCol>
												<BsCol class="col-md-4">
													<label
														key="recoMuestral"
														className={
															classes.labels
														}
													>
														<CheckboxWidgetUncontrolled
															name="recoMuestral"
															color="primary"
															control={control}
															errors={
																errors.recoMuestral
															}
														/>
														<Typography
															// color="textSecondary"
															variant="body1"
														>
															Reco Muestral
														</Typography>
													</label>
												</BsCol>
											</BsRow>
											<BsRow class="mt-5">
												<BsCol class="col-md-12 text-center">
													<SaveAction
														title="Guardar"
														color="secondary"
														size="large"
														className="roundedButton ml-md-2"
														variant="contained"
														disabled={
															loadingQueryState
														}
														messageType="add"
														mutationLoading={
															loadingQueryState
														}
														success={
															successQueryState
														}
													/>
												</BsCol>
											</BsRow>
										</form>
									</BsCol>
								</BsRow>
								{errorState && (
									<Snackbar
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'center',
										}}
										open={errorState}
										onClose={() => setErrorState(false)}
										autoHideDuration={4500}
										key={Math.random()}
									>
										<SnackBarsComponent
											variant="error"
											message="El nombre de usuario ya existe"
											handleClose={
												handleCloseSnackBarsComponent
											}
										/>
									</Snackbar>
								)}
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};
export default DataAdd;
