import React, { useState, useEffect } from 'react';
import { Checkbox, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import classes from '../CheckboxWidget.module.scss';
import errorMessage from '../../../common/errorMessages.json';
import { Controller } from 'react-hook-form';

const CheckboxWidgetControlled = props => {
  const { errors, req, control, name, checked, onChangeHandler, value } = props;
  const [stateMessage, setStateMessage] = useState('');
  const [stateError, setStateError] = useState(false);
  useEffect(() => {
    // console.log(errors, 'errores');
    if (errors) {
      switch (errors.type) {
        case 'required':
          setStateMessage(
            errorMessage.message[errors.ref.name].required
              ? errorMessage.message[errors.ref.name].required
              : errorMessage.message[errors.ref.name]
          );
          break;
        default:
          break;
      }
      setStateError(true);
    } else {
      setStateMessage('');
      setStateError(false);
    }
  }, [errors]);

  return (
    <span>
      <Controller
        render={props => (
          <Checkbox
            // onChange={e => props.onChange(e.target.checked)}
            onChange={e => onChangeHandler(e)}
            checked={checked}
            value={value}
          />
        )}
        name={name}
        defaultValue={false}
        control={control}
        rules={{
          required: req
        }}
      />
      {stateError && (
        <FormHelperText className={classes.errorText} error>
          {stateMessage}
        </FormHelperText>
      )}
    </span>
  );
};

CheckboxWidgetControlled.propTypes = {
  name: PropTypes.string.isRequired,
  req: PropTypes.bool
};

export default CheckboxWidgetControlled;
