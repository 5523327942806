import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import alertMessagesFile from '../../common/alertMessagesFile.json';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function DescriptionAlerts() {
  const classes = useStyles();

  const alerts = alertMessagesFile;

  return (
    <div className={classes.root}>
      {alerts.map((alert, index) => (
        <Alert key={index} severity={alert.severity}>
          <AlertTitle>{alert.title}</AlertTitle>
          {alert.description}
        </Alert>
      ))}
    </div>
  );
}