import React, { useState, useContext, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
// import TagManager from 'react-gtm-module';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useForm } from 'react-hook-form';
import { Context } from '../../context/Context';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import clsx from 'clsx';
//import orchard_icon from '../../../../../../common/iconSVG.json';
import LogoSign from '../../assets/Logologo.png';
// import { signInPanel } from '../../graphql/queries/auth/auth';
// import { useActions } from 'store/actions';
// import { useLazyQuery } from '@apollo/react-hooks';
import {
	// Button,
	IconButton,
	InputLabel,
	Link,
	Typography,
} from '@material-ui/core';
import { SaveAction, TextFieldWidgetTextBig } from '../../components';
import classes from './SignIn.module.scss';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import viewsTexts from '../../common/viewsTexts.json';
import generalTexts from '../../common/generalTexts.json';
import { useTheme } from '@material-ui/styles';
import useAuth from '../../customHooks/useAuth';
import { FiltersContext } from 'context';
import { Alert } from '@material-ui/lab';
import { icon } from '../../common/iconSVG.json';
import axios from 'axios';

const SignIn = (props) => {
	const context = useContext(Context);

	// const { getMyOrchardsInfo } = useContext(FiltersContext);

	const [loadingState, setLoadingState] = useState(false);

	const [showPassword, setShowPassword] = useState(false);

	const [profileIncorrectState, setProfileIncorrectState] = useState(false);

	const { login, user, accessToken, error, busy } = useAuth();

	const theme = useTheme();

	// console.log(theme);

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();

	let modulesPanel = [
		{
			id: 1,
			name: 'Home',
			assigned: true,
			href: '/dashboard',
			subMenu: false,
			icon: 'home',
			menuOrder: 1,
			process: [
				{
					id: 11,
					name: 'Home',
					assigned: true,
					href: '/dashboard',
					__typename: 'UserProcessAssigned',
				},
			],
			__typename: 'UserModuleAssigned',
		},
		{
			id: 2,
			name: 'Usuario',
			menuOrder: 2,
			assigned: true,
			href: 'user',
			subMenu: true,
			icon: 'user',
			process: [
				{
					id: 21,
					name: 'Ingresar',
					assigned: true,
					href: 'user-add',
					iconType: 'material',
					icon: 'AddIcon',
					__typename: 'UserProcessAssigned',
				},
				{
					id: 22,
					name: 'Listar',
					assigned: true,
					href: 'user-list',
					iconType: 'material',
					icon: 'ListIcon',
					__typename: 'UserProcessAssigned',
				},
				{
					id: 23,
					name: 'Privilegios',
					assigned: true,
					href: 'user-privilege',
					iconType: 'material',
					icon: 'AssignmentTurnedInIcon',
					__typename: 'UserProcessAssigned',
				},
			],
			__typename: 'UserModuleAssigned',
		},
		// {
		// 	id: 3,
		// 	name: 'Carga Información',
		// 	assigned: true,
		// 	href: 'data',
		// 	subMenu: true,
		// 	iconType: 'material',
		// 	icon: 'BackupIcon',
		// 	process: [
		// 		{
		// 			id: 31,
		// 			name: 'Ingresar',
		// 			assigned: true,
		// 			href: 'data-add',
		// 			iconType: 'material',
		// 			icon: 'AddIcon',
		// 			__typename: 'DataProcessAssigned',
		// 		},
		// 		{
		// 			id: 32,
		// 			name: 'Listar',
		// 			assigned: true,
		// 			href: 'data-list',
		// 			iconType: 'material',
		// 			icon: 'ListIcon',
		// 			__typename: 'DataProcessAssigned',
		// 		},
		// 	],
		// 	__typename: 'DataModuleAssigned',
		// },
		// {
		// 	id: 3,
		// 	name: 'Unidad productiva',
		// 	assigned: true,
		// 	href: 'pu',
		// 	subMenu: true,
		// 	menuOrder: 7,
		// 	icon: 'productive_unit',
		// 	process: [
		// 		{
		// 			id: 31,
		// 			name: 'Ingresar',
		// 			assigned: true,
		// 			href: 'pu-add',
		// 			iconType: 'material',
		// 			icon: 'AddIcon',
		// 			__typename: 'UserProcessAssigned',
		// 		},
		// 		{
		// 			id: 32,
		// 			name: 'Listar',
		// 			assigned: true,
		// 			href: 'pu-list',
		// 			iconType: 'material',
		// 			icon: 'ListIcon',
		// 			__typename: 'UserProcessAssigned',
		// 		},
		// 	],
		// 	__typename: 'UserModuleAssigned',
		// },
		// {
		// 	id: 4,
		// 	name: 'Clima',
		// 	assigned: true,
		// 	href: 'hd',
		// 	subMenu: true,
		// 	icon: 'weather_icon',
		// 	process: [
		// 		{
		// 			id: 41,
		// 			name: 'Ingresar',
		// 			assigned: true,
		// 			href: 'hd-add',
		// 			iconType: 'material',
		// 			icon: 'AddIcon',
		// 			__typename: 'UserProcessAssigned',
		// 		},
		// 		{
		// 			id: 42,
		// 			name: 'Listar',
		// 			assigned: true,
		// 			href: 'hd-list',
		// 			iconType: 'material',
		// 			icon: 'ListIcon',
		// 			__typename: 'UserProcessAssigned',
		// 		},
		// 	],
		// 	__typename: 'UserModuleAssigned',
		// },
		{
			id: 5,
			name: 'Cliente',
			assigned: true,
			href: 'client',
			menuOrder: 3,
			subMenu: true,
			icon: 'client_icon',
			process: [
				{
					id: 51,
					name: 'Ingresar',
					assigned: true,
					href: 'client-add',
					iconType: 'material',
					icon: 'AddIcon',
					__typename: 'UserProcessAssigned',
				},
				{
					id: 52,
					name: 'Listar',
					assigned: true,
					href: 'client-list',
					iconType: 'material',
					icon: 'ListIcon',
					__typename: 'UserProcessAssigned',
				},
			],
			__typename: 'UserModuleAssigned',
		},
		{
			id: 6,
			name: 'Agrícola',
			assigned: true,
			href: 'am',
			menuOrder: 4,
			subMenu: true,
			icon: 'agrobusiness_icon',
			process: [
				{
					id: 61,
					name: 'Ingresar',
					assigned: true,
					href: 'am-add',
					iconType: 'material',
					icon: 'AddIcon',
					__typename: 'UserProcessAssigned',
				},
				{
					id: 62,
					name: 'Listar',
					assigned: true,
					href: 'am-list',
					iconType: 'material',
					icon: 'ListIcon',
					__typename: 'UserProcessAssigned',
				},
			],
		},
		{
			id: 7,
			name: 'Huerto',
			assigned: true,
			href: 'om',
			subMenu: true,
			menuOrder: 5,
			icon: 'orchard_icon',
			process: [
				{
					id: 71,
					name: 'Ingresar',
					assigned: true,
					href: 'om-add',
					iconType: 'material',
					icon: 'AddIcon',
					__typename: 'UserProcessAssigned',
				},
				{
					id: 72,
					name: 'Listar',
					assigned: true,
					href: 'om-list',
					iconType: 'material',
					icon: 'ListIcon',
					__typename: 'UserProcessAssigned',
				},
			],
		},
		// {
		// 	id: 8,
		// 	name: 'Cargar excel',
		// 	assigned: true,
		// 	menuOrder: 8,
		// 	href: 'load',
		// 	subMenu: true,
		// 	icon: 'excel',
		// 	process: [
		// 		{
		// 			id: 81,
		// 			name: 'Cargar excel',
		// 			assigned: true,
		// 			href: 'load-excel',
		// 			iconType: 'material',
		// 			icon:'AddIcon',
		// 			__typename: 'UserProcessAssigned',
		// 		},
		// 	],
		// },

		// {
		// 	id: 9,
		// 	name: 'Edicion Masiva',
		// 	assigned: true,
		// 	href: 'me',
		// 	subMenu: true,
		// 	icon: 'massive_edition',
		// 	process: [
		// 		{
		// 			id:91,
		// 			name: 'Edicion Masiva',
		// 			assigned: true,
		// 			href: 'me-examine',
		// 			iconType: 'material',
		// 			icon: 'AddIcon',
		// 			__typename: 'UserProcessAssigned',
		// 		},
		// 		{
		// 			id: 92,
		// 			name: 'Edicion Masiva Lista',
		// 			assigned: true,
		// 			href: 'me-list',
		// 			iconType: 'material',
		// 			icon: 'ListIcon',
		// 			__typename: 'UserProcessAssigned',
		// 		}

		// 	],

		// },
		{
			id: 10,
			name: 'Carga inicial',
			assigned: true,
			href: '/initial-upload',
			subMenu: false,
			menuOrder: 6,
			icon: 'initial_upload',
			process: [
				{
					id: 101,
					name: 'Carga inicial',
					assigned: true,
					href: '/initial-upload',
					__typename: 'UserProcessAssigned',
				},
			],
			__typename: 'UserModuleAssigned',
		},
		{
			id: 11,
			name: 'Reportes',
			assigned: true,
			href: '/report',
			subMenu: false,
			menuOrder: 9,
			iconType: 'material',
			icon: 'AssessmentIcon',
			process: [
				{
					id: 111,
					name: 'Reportes',
					assigned: true,
					href: '/report',
					__typename: 'UserProcessAssigned',
				},
			],
			__typename: 'UserModuleAssigned',
		},
	];

	let modulesPanelAdmin = [
		{
			id: 1,
			name: 'Home',
			assigned: true,
			href: '/dashboard',
			subMenu: false,
			icon: 'home',
			menuOrder: 1,
			process: [
				{
					id: 11,
					name: 'Home',
					assigned: true,
					href: '/dashboard',
					__typename: 'UserProcessAssigned',
				},
			],
			__typename: 'UserModuleAssigned',
		},
		{
			id: 2,
			name: 'Usuario',
			menuOrder: 2,
			assigned: true,
			href: 'user',
			subMenu: true,
			icon: 'user',
			process: [
				{
					id: 21,
					name: 'Ingresar',
					assigned: true,
					href: 'user-add',
					iconType: 'material',
					icon: 'AddIcon',
					__typename: 'UserProcessAssigned',
				},
				{
					id: 22,
					name: 'Listar',
					assigned: true,
					href: 'user-list',
					iconType: 'material',
					icon: 'ListIcon',
					__typename: 'UserProcessAssigned',
				},
				{
					id: 23,
					name: 'Privilegios',
					assigned: true,
					href: 'user-privilege',
					iconType: 'material',
					icon: 'AssignmentTurnedInIcon',
					__typename: 'UserProcessAssigned',
				},
			],
			__typename: 'UserModuleAssigned',
		},
		{
			id: 3,
			name: 'Carga Información',
			assigned: true,
			href: 'data',
			subMenu: true,
			iconType: 'material',
			icon: 'BackupIcon',
			process: [
				{
					id: 31,
					name: 'Ingresar',
					assigned: true,
					href: 'data-add',
					iconType: 'material',
					icon: 'AddIcon',
					__typename: 'DataProcessAssigned',
				},
				{
					id: 32,
					name: 'Listar',
					assigned: true,
					href: 'data-list',
					iconType: 'material',
					icon: 'ListIcon',
					__typename: 'DataProcessAssigned',
				},
			],
			__typename: 'DataModuleAssigned',
		},
		{
			id: 3,
			name: 'Unidad productiva',
			assigned: true,
			href: 'pu',
			subMenu: true,
			menuOrder: 7,
			icon: 'productive_unit',
			process: [
				{
					id: 31,
					name: 'Ingresar',
					assigned: true,
					href: 'pu-add',
					iconType: 'material',
					icon: 'AddIcon',
					__typename: 'UserProcessAssigned',
				},
				{
					id: 32,
					name: 'Listar',
					assigned: true,
					href: 'pu-list',
					iconType: 'material',
					icon: 'ListIcon',
					__typename: 'UserProcessAssigned',
				},
			],
			__typename: 'UserModuleAssigned',
		},
		{
			id: 4,
			name: 'Clima',
			assigned: true,
			href: 'hd',
			subMenu: true,
			icon: 'weather_icon',
			process: [
				{
					id: 41,
					name: 'Ingresar',
					assigned: true,
					href: 'hd-add',
					iconType: 'material',
					icon: 'AddIcon',
					__typename: 'UserProcessAssigned',
				},
				{
					id: 42,
					name: 'Listar',
					assigned: true,
					href: 'hd-list',
					iconType: 'material',
					icon: 'ListIcon',
					__typename: 'UserProcessAssigned',
				},
			],
			__typename: 'UserModuleAssigned',
		},
		{
			id: 5,
			name: 'Cliente',
			assigned: true,
			href: 'client',
			menuOrder: 3,
			subMenu: true,
			icon: 'client_icon',
			process: [
				{
					id: 51,
					name: 'Ingresar',
					assigned: true,
					href: 'client-add',
					iconType: 'material',
					icon: 'AddIcon',
					__typename: 'UserProcessAssigned',
				},
				{
					id: 52,
					name: 'Listar',
					assigned: true,
					href: 'client-list',
					iconType: 'material',
					icon: 'ListIcon',
					__typename: 'UserProcessAssigned',
				},
			],
			__typename: 'UserModuleAssigned',
		},
		{
			id: 6,
			name: 'Agrícola',
			assigned: true,
			href: 'am',
			menuOrder: 4,
			subMenu: true,
			icon: 'agrobusiness_icon',
			process: [
				{
					id: 61,
					name: 'Ingresar',
					assigned: true,
					href: 'am-add',
					iconType: 'material',
					icon: 'AddIcon',
					__typename: 'UserProcessAssigned',
				},
				{
					id: 62,
					name: 'Listar',
					assigned: true,
					href: 'am-list',
					iconType: 'material',
					icon: 'ListIcon',
					__typename: 'UserProcessAssigned',
				},
			],
		},
		{
			id: 7,
			name: 'Huerto',
			assigned: true,
			href: 'om',
			subMenu: true,
			menuOrder: 5,
			icon: 'orchard_icon',
			process: [
				{
					id: 71,
					name: 'Ingresar',
					assigned: true,
					href: 'om-add',
					iconType: 'material',
					icon: 'AddIcon',
					__typename: 'UserProcessAssigned',
				},
				{
					id: 72,
					name: 'Listar',
					assigned: true,
					href: 'om-list',
					iconType: 'material',
					icon: 'ListIcon',
					__typename: 'UserProcessAssigned',
				},
			],
		},

		{
			id: 8,
			name: 'Cargar excel',
			assigned: true,
			menuOrder: 8,
			href: 'load',
			subMenu: true,
			icon: 'excel',
			process: [
				{
					id: 81,
					name: 'Cargar excel',
					assigned: true,
					href: 'load-excel',
					iconType: 'material',
					icon: 'AddIcon',
					__typename: 'UserProcessAssigned',
				},
			],
		},

		{
			id: 9,
			name: 'Edicion Masiva',
			assigned: true,
			href: 'me',
			subMenu: true,
			icon: 'massive_edition',
			process: [
				{
					id: 91,
					name: 'Edicion Masiva',
					assigned: true,
					href: 'me-examine',
					iconType: 'material',
					icon: 'AddIcon',
					__typename: 'UserProcessAssigned',
				},
				{
					id: 92,
					name: 'Edicion Masiva Lista',
					assigned: true,
					href: 'me-list',
					iconType: 'material',
					icon: 'ListIcon',
					__typename: 'UserProcessAssigned',
				},
			],
		},
		{
			id: 10,
			name: 'Carga inicial',
			assigned: true,
			href: '/initial-upload',
			subMenu: false,
			menuOrder: 6,
			icon: 'initial_upload',
			process: [
				{
					id: 101,
					name: 'Carga inicial',
					assigned: true,
					href: '/initial-upload',
					__typename: 'UserProcessAssigned',
				},
			],
			__typename: 'UserModuleAssigned',
		},
		{
			id: 11,
			name: 'Reportes',
			assigned: true,
			href: '/report',
			subMenu: false,
			menuOrder: 9,
			iconType: 'material',
			icon: 'AssessmentIcon',
			process: [
				{
					id: 111,
					name: 'Reportes',
					assigned: true,
					href: '/report',
					__typename: 'UserProcessAssigned',
				},
			],
			__typename: 'UserModuleAssigned',
		},
	];

	const handleClickShowPassword = () => {
		setShowPassword((prevState) => !prevState);
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const onSubmit = async (values) => {
		const { user, password } = values;
		// try {
		// 	await signInHandler({ variables: { user, password } });
		// } catch (err) {}

		console.log(user, password, '-----');

		try {
			await login(user, password, 'user:me');
		} catch (e) {
			console.log(e, 'error login');
			// alert('Hubo un error' + e);
		}
	};

	useEffect(() => {
		// console.log(user, accessToken, 'llega la data RESPUESTA LOGIN');
		// ejecutar evento login

		if (user && accessToken) {
			// TagManager.initialize(tagManagerArgs);
			console.log(user, '<---------user');

			// id admin panel 662a5378fff5d52c4f48ef62 - panel_perfil
			// la anterior 6442acd4c818b181aaf9b958

			// if (user?.profile?.id === '6442acd4c818b181aaf9b958' || user?.profile?.id === '662a5378fff5d52c4f48ef62') {
			if (user?.profile?.id === '662a5378fff5d52c4f48ef62') {
				const modulesAssigned =
					user?._id === '664b56296eed13f905b9eb95'
						? modulesPanelAdmin
						: modulesPanel;

				context.login(
					accessToken,
					user._id,
					user.name,
					user.lastname,
					user.username,
					user?.profile, //ejemplo
					user.roleId,
					user.orchard || [],
					modulesAssigned
				);
			} else {
				setProfileIncorrectState(true);
			}

			// getMyOrchardsInfo(user.orchard, accessToken);
			// setUserOrchards(user.orchard);
		}
	}, [user, accessToken, error, modulesPanel]);

	// useEffect(() => {
	// 	window.dataLayer = window.dataLayer || [];
	// 	window.dataLayer.push({
	// 		event: 'logOut',
	// 		userId: '', //this number must be replaced with an actual User ID
	// 		clientName: '',
	// 		fullName: '',
	// 		userName: '',
	// 	});
	// }, []);

	return (
		<div className={classes.root}>
			<BsContainer
				fluid
				class="h-100 px-0 px-md-0"
				style={{ height: '100%' }}
			>
				<BsRow class="h-100 justify-content-center align-items-center justify-content-md-end no-gutters">
					<BsCol class={['col-xl-5 col-lg-6'].join(' ')}>
						<div className={classes.content}>
							<div className={classes.contentBody}>
								<img
									src={LogoSign}
									alt="analytics"
									className={clsx(
										'mb-4 mb-md-3',
										classes.imgPhone
									)}
								/>
								<form
									className={classes.form}
									onSubmit={handleSubmit(onSubmit)}
								>
									<Typography
										className={classes.title}
										variant="h3"
										gutterBottom
									>
										{viewsTexts.titles.signin.title}
									</Typography>
									<Typography
										align="left"
										className={classes.sugestion}
										// color="textSecondary"
										variant="h5"
									>
										{viewsTexts.titles.signin.sugestion}
									</Typography>
									<div className="mt-5 mt-md-5">
										<InputLabel
											error={Boolean(errors.email)}
										>
											Nombre usuario o email
										</InputLabel>
										<TextFieldWidgetTextBig
											name="user"
											placeholder="usuario o email"
											// label={viewsTexts.forms.email}
											label=""
											req
											margin="dense"
											defaultValue=""
											control={control}
											errors={errors.user}
											// autoComplete="email"
										/>
										<InputLabel
											className="mt-5"
											error={Boolean(errors.password)}
										>
											Contraseña
										</InputLabel>
										<TextFieldWidgetTextBig
											name="password"
											req
											// minLength={5}
											// maxLength={10}
											margin="dense"
											type={
												showPassword
													? 'text'
													: 'password'
											}
											label=""
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															color="default"
															onClick={
																handleClickShowPassword
															}
															onMouseDown={
																handleMouseDownPassword
															}
														>
															{showPassword ? (
																<Visibility />
															) : (
																<VisibilityOff />
															)}
														</IconButton>
													</InputAdornment>
												),
											}}
											defaultValue=""
											// label={viewsTexts.forms.password}
											control={control}
											errors={errors.password}
										/>
										<div className={classes.wrapper}>
											<SaveAction
												title="Ingresar"
												color="secondary"
												size="large"
												className="bigButton"
												fullWidth={true}
												variant="contained"
												messageType="add"
												mutationLoading={busy}
												success={false}
											/>
											{/* {busy && (
												<CircularProgress
													size={24}
													className={classes.progress}
												/>
											)} */}
										</div>
									</div>
									{error && (
										<div className="text-center">
											<Alert severity="error">
												Usuario o contraseña
												incorrectos.
											</Alert>
										</div>
									)}
									{profileIncorrectState && (
										<div className="text-center">
											<Alert severity="error">
												Perfil de acceso incorrecto.
											</Alert>
										</div>
									)}
									{/* <Typography
										color="textSecondary"
										variant="body1"
									>
										{generalTexts.textLinkSignInWithRol}{' '}
										<Link
											component={RouterLink}
											to="/sign-in-rol"
											variant="h6"
										>
											{generalTexts.linkSignInWithRol}
										</Link>
									</Typography> */}
									{/* <Typography color="textSecondary" variant="body1">
                    {generalTexts.textLinkSignUp}{' '}
                    <Link component={RouterLink} to="/sign-up" variant="h6">
                      {generalTexts.linkSignUp}
                    </Link>
                  </Typography> */}
									<div className="text-right">
										<Typography variant="body1">
											<Link
												color="secondary"
												component={RouterLink}
												to="/recovery-password"
												variant="h6"
											>
												{
													generalTexts.textRecoveryPassword
												}
											</Link>
										</Typography>
									</div>
								</form>
							</div>
						</div>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};

export default withRouter(SignIn);
