import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import classes from './TextFieldWidgetSelect.module.scss';
import { IsDesktopHandler } from '../../helpers';
import errorMessage from '../../common/errorMessages.json';

const TextFieldWidgetSelect2 = props => {
  const {
    register,
    errors,
    options,
    req,
    loading,
    withDefault,
    name,
    ...others
  } = props;

  const [stateMessage, setStateMessage] = useState('');
  const [stateError, setStateError] = useState(false);
  useEffect(() => {
    if (errors) {
      switch (errors.type) {
        case 'required':
          setStateMessage(
            errorMessage.message[errors.ref.name].required
              ? errorMessage.message[errors.ref.name].required
              : errorMessage.message[errors.ref.name]
          );
          break;
        default:
          break;
      }
      setStateError(true);
    } else {
      setStateMessage('');
      setStateError(false);
    }
  }, [errors]);

  let labelText = null;
  const isDesktop = IsDesktopHandler('sm');
  if (!isDesktop) {
    labelText = props.labeltext;
  } else {
    labelText = null;
  }

  const [personName, setPersonName] = React.useState([]);

  const handleChangeMultiple = event => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  };

  //   validaciones
  return (
    <div className={classes.inputContainerStyle}>
      <div className={classes.labelDesktopStyle}>{props.labeltext}</div>
      {props.loading && <p>cargando</p>}
      <TextField
        variant="outlined"
        select
        name={name}
        margin="dense"
        label={labelText}
        fullWidth
        // MenuProps={MenuProps}
        {...others}
        className={classes.rootOutlinedStyle}
        SelectProps={{
          native: true,
          multiple: true,
          classes: {
            root: classes.rootStyle,
            select: classes.selectStyle
          },
          renderValue: selected => selected.join(', ')
        }}
        inputRef={register({
          required: props.req
        })}
        onChange={handleChangeMultiple}
        error={stateError}
        helperText={stateMessage}
        value={personName}>
        {options.map(option => (
          <option key={option.id} value={option.id}>
            {/* <Checkbox checked={personName.indexOf(option.id) > -1} /> */}
            {option.name}
          </option>
        ))}
      </TextField>
    </div>
  );
};

TextFieldWidgetSelect2.propTypes = {
  name: PropTypes.string.isRequired,
  req: PropTypes.bool,
  loading: PropTypes.bool
};

export default TextFieldWidgetSelect2;
