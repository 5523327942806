import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
// import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingWidget from '../LoadingWidget';

const paginationOptions = {
	rowsPerPageText: 'Filas por página',
	rangeSeparatorText: 'de',
};
const DataTableWidget = (props) => {
	const {
		columns,
		filteredItems,
		subHeaderComponentMemo = null,
		loading,
		sortField = null,
		handleOnSort,
		sortFunction = null,
		changePagination,
		totalRows,
		rowsChange,
		loadingCount,
		conditionalRowStyles,
		customStyles,
		resetPaginationToggle,
		fixedHeader = false,
		fixedHeaderScrollHeight = '',
		subHeader = true,
	} = props;
	const [perPageOptions] = useState([10, 15, 20, 25, 30]);
	const [totalRowState, setTotalRowState] = useState(totalRows);

	useEffect(() => {
		setTotalRowState(totalRows);
	}, [loadingCount, totalRows]);

	return (
		<DataTable
			noHeader
			fixedHeader={fixedHeader}
			fixedHeaderScrollHeight={fixedHeaderScrollHeight}
			columns={columns}
			data={filteredItems}
			defaultSortAsc={false}
			subHeader={subHeader}
			subHeaderComponent={subHeaderComponentMemo}
			onSort={handleOnSort}
			sortFunction={sortFunction} //es para cambiar la forma de interpretar el cambio de sort
			defaultSortFieldId={sortField}
			pagination
			paginationServer
			sortServer
			conditionalRowStyles={
				conditionalRowStyles ? conditionalRowStyles : []
			}
			paginationComponentOptions={paginationOptions}
			paginationRowsPerPageOptions={perPageOptions}
			onChangeRowsPerPage={rowsChange}
			progressPending={loading}
			// paginationPerPage={1}
			progressComponent={<LoadingWidget />}
			paginationTotalRows={totalRowState}
			onChangePage={changePagination}
			noDataComponent="No hay registros."
			customStyles={customStyles}
			paginationResetDefaultPage={resetPaginationToggle}
		/>
	);
};

export default DataTableWidget;
