import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
	SaveAction,
	CardHeaderWidget,
	TextFieldWidgetText,
	TextFieldWidgetSelectControllerHorizontal,
} from '../../../components';
import { Card, CardContent, Divider, TextField } from '@material-ui/core';
import { BsRow, BsCol } from '../../../layouts/components';
import { CloseButtonEditionArea } from '../../../components/EditionAreaWidget';
import {alterArrayGetFields} from '../../../helpers';
import '../../../assets/scss/form.scss';
import viewsTexts from '../../../common/viewsTexts.json';
import { AxiosContext } from '../../../context/AxiosContext';
import { unitProductiveAdd } from '../HdDatos/HdDatos';
import {Specie}from '../Options/OptionsData';

const HdEdit = (props) => {

	const require = true;

	console.log(props.rowEdit, ' <----llega row a edit');
	const {
		client,
		agroBusiness,
		orchard,
		supplier,
		credential,
	} = props.rowEdit;

	const { editFunc, closeFunc } = props;
	const [clientDataState, setClientDataState] = useState([]);
	const [agroBusinessDataState, setAgroBusinessDataState] = useState([]);
	const [loadingDataState, setLoadingDataState] = useState(false);
	// Asume que quieres el rootStock de la primera variedad de la primera especie
	const [rootStockDataState, setRootStockDataState] = useState([]);
	//const [rootStockDataState, setRootStockDataState] = useState([]);
	const [varietyDataState, setVarietyDataState] = useState([]);
	const [specieDataState, setSpecieDataState] = useState(Specie);

	const axiosContext = useContext(AxiosContext);

	const [loadingQueryState, setLoadingQueryState] = useState(false);
	const [successQueryState, setSuccessQueryState] = useState(false);

	const {
		handleSubmit,
		control,
		formState: { errors },	
		getValues,
		reset,
		watch,
		
	} = useForm();

	const watchClient = watch('client');
	const watchSpecie = watch('specie');
	const watchVariety = watch('variety');
	const watchRootStock = watch('rootStock');

	useEffect(() => {
		reset({
			client: client,
			orchard: orchard,
			agroBusiness:agroBusiness,
			supplier: supplier,
			credential: credential,
		});
		console.log('watchClient:', watchClient);
		
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	
		console.log(watchClient, '<----holi!!!');
	}, [agroBusiness, orchard, client,supplier,credential, reset]);

	  
	  console.log(watchClient, '<----CLIENTEE!!!');


	  useEffect(() => {
		console.log(watchClient,'<---watchClient2');
		if (watchClient) {
		  const resultClient = clientDataState.filter(
			(ele) => ele.value === watchClient
		  );
		  console.log('filtered', resultClient);
		  setAgroBusinessDataState(resultClient[0]?.agroBusiness);
		}
	  }, [watchClient, clientDataState]);
	
	
	  

	const onSubmit = async (values) => {
		setLoadingQueryState(true);
		/*const clientObj = clientDataState.filter(
			(ele) => ele.value === values.client
		);*/

	   // saca los objetos
		let clientObj = clientDataState.filter(
			(ele) => ele.value === values.client
		);

		clientObj = alterArrayGetFields(clientObj, ['id', 'label', 'value']);

		// const agroBusinessObj = agroBusinessDataState.filter(
		// 	(ele) => ele.value === values.agroBusiness
		// );

		const agroBusinessArray = Array.isArray(values.agroBusiness)
		? values.agroBusiness.map((agro) => {
			const dataAgro = agroBusinessDataState.filter(
			  (ele) => ele.value === agro
			);
			return dataAgro[0];
		  })
		: []; // Si no es un array, establece un array vacío
		  console.log(agroBusinessArray, '<---agroBusinessArray');

		const query = {
			client: clientObj[0],
			agroBusiness: agroBusinessArray,
			orchard: values.orchard,
			supplier: values.supplier,
			credential: values.credential,			
		};

		try {
			const response = await axiosContext.authAxios.post(
				'v1/user/update-user',
				query
			);

			console.log(response, '<---respuesta de query edit user');

			if (response.status === 200) {
				// alert('listo');
				editFunc(unitProductiveAdd);
				setSuccessQueryState(true);
			}

			setLoadingQueryState(false);
		} catch (err) {
			setLoadingQueryState(false);
		}
	};

	const getClientsAsync = async () => {
		setLoadingDataState(true);
		try {
			let response = await axiosContext.authAxios.post(
				'v1/client/list-clients'
			);

			console.log(response, '<<----respuesta profiles');

			const { data, status } = response;

			if (status === 200) {
				setClientDataState(unitProductiveAdd);
				setLoadingDataState(false);
				console.log(unitProductiveAdd, '<------HOLIWIS');
			}
		} catch (error) {
			console.log(error);
			setLoadingDataState(false);
			// setLoadingVarietyFilterState(false);
		}
	};

	// endpoints
	useEffect(() => {
		getClientsAsync();
		
	}, []);

	return (
		<Card elevation={0}>
			<CardHeaderWidget
				title={
					viewsTexts.titles.historicData.puTextEdit +
					' ' +
					client +
					' ' +
					agroBusiness
				}
			/>
			<CloseButtonEditionArea closeFunc={props.closeFunc} />
			<Divider />
			<CardContent>
				<BsRow>
					<BsCol class="col-md-12">
						<form
							autoComplete="on"
							onSubmit={handleSubmit(onSubmit)}
						>
							<BsRow>
								<BsCol class="col-md-6">
								<TextFieldWidgetText
										InputLabelProps={{
											shrink: true,
										}}
										name="client"
										//labeltext={viewsTexts.forms.orchard}
										labeltext="Cliente"
										req={false}
										control={control}
										errors={errors.client}
										defaultValue=""
										disabled={true}
									/>
								</BsCol>
								<BsCol class="col-md-6">
								<TextFieldWidgetText
										InputLabelProps={{
											shrink: true,
										}}
										name="agroBusiness"
										labeltext="Agricola"
										req={false}
										control={control}
										errors={errors.agroBusiness}
										defaultValue=""
										disabled={true}
									/>
								</BsCol>
							</BsRow>
							<BsRow>
								<BsCol class="col-md-6">
									<TextFieldWidgetText
										InputLabelProps={{
											shrink: true,
										}}
										name="orchard"
										//labeltext={viewsTexts.forms.orchard}
										labeltext="Huerto"
										req={false}
										control={control}
										errors={errors.orchard}
										defaultValue=""
										disabled={true}
									/>
								</BsCol>
								<BsCol class="col-md-6">
									<TextFieldWidgetText
										name="cc"
										//labeltext={viewsTexts.forms.cc}
										labeltext="Proveedor"
										InputLabelProps={{
											shrink: true,
										}}
										req={false}
										control={control}
										errors={errors.supplier}
										defaultValue=""
									/>
								</BsCol>
								
							</BsRow>
							 <BsRow>
								<BsCol class="col-md-6">
									<TextFieldWidgetText
										InputLabelProps={{
											shrink: true,
										}}
										name="quarter"
										//labeltext={viewsTexts.forms.specie}
										labeltext="Credencial"
										req={false}
										control={control}
										errors={errors.credential}
										defaultValue=""
									/>
								</BsCol>
			
							</BsRow>
								<BsRow class="mt-5">
								<BsCol class="col-md-12 text-center">
									<SaveAction
										title="Guardar"
										color="secondary"
										size="large"
										className="roundedButton ml-md-2"
										variant="contained"
										disabled={loadingQueryState}
										messageType="edit"
										actionClose={closeFunc}
										mutationLoading={loadingQueryState}
										success={successQueryState}
									/>
								</BsCol>
							</BsRow>
						</form>
					</BsCol>
				</BsRow>
			</CardContent>
		</Card>
	);
};

export default HdEdit;
