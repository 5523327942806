import React, { useState } from 'react';
import { BrowserRouter, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
// import { ErrorBoundary } from 'react-error-boundary';
// import { ApolloClient, InMemoryCache, ApolloLink } from 'apollo-boost';
// import { onError } from 'apollo-link-error';
// import { createUploadLink } from 'apollo-upload-client';
// import { ApolloProvider } from '@apollo/react-hooks';
import Context from './context/Context';
import { AxiosProvider } from './context/AxiosContext';
import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import Routes from './Routes';
import SnackBarsComponent from './components/SnackBarsComponent';
import { FiltersProvider } from 'context';
// import ErrorView from 'ErrorView';

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
	draw: chartjs.draw,
});

const App = () => {
	const [errorState, setErrorState] = useState(false);
	const [errorMessageState, setErrorMessageState] = useState('');

	// const seeErrors = (graphQLErrors, networkError) => {
	// 	let message = '';
	// 	if (graphQLErrors) {
	// 		message = message.concat('ERROR - ');
	// 		graphQLErrors.map((err) => {
	// 			message = message.concat(err.message);
	// 			switch (err.extensions.code) {
	// 				case 'UNAUTHENTICATED':
	// 					window.sessionStorage.removeItem('token');
	// 					//window.location.href = '/';
	// 					break;
	// 				default:
	// 					break;
	// 			}
	// 			return err;
	// 		});
	// 	}
	// 	if (networkError) {
	// 		message = message.concat(
	// 			'ERROR - Aplicación no puede conectarse con el servidor'
	// 		);
	// 	}
	// 	setErrorState(true);
	// 	setErrorMessageState(message);
	// };

	const handleCloseSnackBarsComponent = () => {
		setErrorState(false);
		setErrorMessageState('');
	};

	const errorHandler = (error, errorInfo) => {
		console.log('//*--------error general: ', error, errorInfo);
	};

	return (
		<Context.Provider>
			<AxiosProvider>
				<FiltersProvider>
					<BrowserRouter>
						<React.Fragment>
							<ThemeProvider theme={theme}>
								{/* <ErrorBoundary
									FallbackComponent={ErrorView}
									onError={errorHandler}
								> */}
								<Router history={browserHistory}>
									<Routes />

									{errorState && (
										<Snackbar
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'center',
											}}
											open={errorState}
											onClose={() => setErrorState(false)}
											autoHideDuration={4500}
											key={Math.random()}
										>
											<SnackBarsComponent
												variant="error"
												message={errorMessageState}
												handleClose={
													handleCloseSnackBarsComponent
												}
											/>
										</Snackbar>
									)}
								</Router>
								{/* </ErrorBoundary> */}
							</ThemeProvider>
						</React.Fragment>
					</BrowserRouter>
				</FiltersProvider>
			</AxiosProvider>
		</Context.Provider>
	);
};
export default App;
