import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackBarsComponent from '../../SnackBarsComponent';
import CircularProgressComponent from '../../Progress/CircularProgressComponent';
import classes from './SaveAction.module.scss';
import snackBarsMessages from '../../../common/snackBarsMessages.json';
import { CardActions, Button } from '@material-ui/core';

const SaveAction = (props) => {
	const {
		mutationLoading,
		success,
		title,
		size = 'medium',
		messageType,
		fullWidth,
		disabled,
		className,
		color = 'primary',
		variant = 'contained',
	} = props;

	const snackBarMessage = snackBarsMessages.message[messageType];

	const [stateSuccess, setStateSuccess] = useState(false);
	const handleCloseSnackBarsComponent = () => {
		setStateSuccess(false);
		const closeAction = props.actionClose ? props.actionClose : () => {};
		closeAction();
	};

	useEffect(() => {
		if (success) {
			setStateSuccess(true);
		} else {
			setStateSuccess(false);
		}
	}, [success]);
	return (
		<>
			<div className={classes.wrapper}>
				<Button
					color={color}
					variant={variant}
					className={className}
					size={size}
					fullWidth={fullWidth}
					type="submit"
					disabled={disabled ? disabled : mutationLoading}
				>
					{title}
				</Button>
				{mutationLoading && <CircularProgressComponent />}
			</div>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={stateSuccess}
				onClose={handleCloseSnackBarsComponent}
				autoHideDuration={4500}
				key={Math.random()}
			>
				<SnackBarsComponent
					variant="success"
					message={snackBarMessage}
					handleClose={handleCloseSnackBarsComponent}
				/>
			</Snackbar>
		</>
	);
};

export default SaveAction;
