import React, { useState, useEffect } from 'react';
import Select from '@material-ui/core/Select';
import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
// import classesModule from './TextFieldWidgetSelectControllerStyled.module.scss';
import { IsDesktopHandler } from '../../../helpers';
import errorMessage from '../../../common/errorMessages.json';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 310,
		[theme.breakpoints.down('sm')]: {
			minWidth: '95%',
		},
	},
	rootLabel: {
		fontSize: 20,
	},
	selectEmpty: {
		marginTop: theme.spacing(6),
	},
	selectStyle: {
		marginTop: 10,
		fontSize: 17,
	},
}));

const TextFieldWidgetSelectControllerStyled = (props) => {
	const {
		errors,
		options,
		req,
		loading,
		name,
		control,
		disabled,
		withDefault,
		defaultValue,
		variant = 'standard',
	} = props;
	const [stateMessage, setStateMessage] = useState('');
	const [stateError, setStateError] = useState(false);

	const classes = useStyles();

	// const [selectedValueState, setSelectedValueState] = useState('');

	useEffect(() => {
		if (errors) {
			switch (errors.type) {
				case 'required':
					setStateMessage(
						errorMessage.message[name].required
							? errorMessage.message[name].required
							: errorMessage.message[name]
					);
					break;
				default:
					break;
			}
			setStateError(true);
		} else {
			setStateMessage('');
			setStateError(false);
		}
	}, [errors]);

	// useEffect(() => {
	// 	setSelectedValueState(selected);
	// 	// console.log("el selected en el select", selectedValueState,"el selected que llega", selected);
	// }, [selected]);

	let labelText = null;
	const isDesktop = IsDesktopHandler('sm');
	if (!isDesktop) {
		labelText = props.labeltext;
	} else {
		labelText = null;
	}

	const renderOptionTitle = (option) => {
		switch (props.titleOption) {
			case 'rut-fullname':
				return `${option.rut} - ${option.fullname}`;
			case 'code-name':
				return `${option.code} - ${option.name}`;
			case 'label':
				return `${option.label}`;
			default:
				return `${option.name}`;
		}
	};

	// console.log(selectedValueState, "ultimo valor selected", options);

	//   validaciones
	return (
		<FormControl className={classes.formControl} error={stateError}>
			{/* <div className={classes.inputContainerStyle}> */}
			{/* <div className={classes.labelDesktopStyle}>{props.labeltext}</div> */}
			<InputLabel
				shrink={withDefault}
				id={labelText}
				className={classes.rootLabel}
			>
				{props.labeltext} {loading && '...cargando'}
			</InputLabel>
			{/* <div style={{ width: '100%' }}> */}
			<Controller
				control={control}
				render={({ field }) => (
					<Select
						labelId={labelText}
						id={labelText}
						// label={labelText}
						// value={selectedValueState}
						// native
						{...field}
						fullWidth
						displayEmpty={withDefault}
						className={withDefault ? classes.selectEmpty : ''}
						classes={{ select: classes.selectStyle }}
						disabled={disabled}
						// margin="dense"
						variant={variant}

						// onChange={e => onChangeFunc(e.target.value)}>
					>
						{withDefault ? (
							<MenuItem value="">
								<em>Seleccione</em>
							</MenuItem>
						) : null}
						{options &&
							options.map((option, index) => (
								<MenuItem key={index} value={option.value}>
									{renderOptionTitle(option)}
								</MenuItem>
							))}
					</Select>
				)}
				name={name}
				defaultValue={defaultValue}
				rules={{
					required: req,
				}}
			/>
			{stateError && (
				<FormHelperText className={classes.errorText} error>
					{stateMessage}
				</FormHelperText>
			)}
			{/* </div> */}
			{/* </div> */}
		</FormControl>
	);
};

TextFieldWidgetSelectControllerStyled.propTypes = {
	name: PropTypes.string.isRequired,
	req: PropTypes.bool,
	loading: PropTypes.bool,
};

export default TextFieldWidgetSelectControllerStyled;
