import MuiButton from './MuiButton';
import MuiCardContent from './MuiCardContent';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';

export default {
	MuiButton,
	MuiIconButton,
	MuiPaper,
	MuiCardContent,
	MuiTableCell,
	MuiTableHead,
	MuiTypography,
};
