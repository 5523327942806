import { colors, createTheme } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

const { palette } = createTheme();

export default {
	black,
	white,
	primary: {
		contrastText: white,
		dark: '#1A2831',
		main: '#29404E',
		light: '#738897',
	},
	secondary: {
		contrastText: white,
		dark: '#137ab4',
		main: '#345EB5',
		light: '#0db1ff',
	},
	tertiary: palette.augmentColor({ main: '#00A0EC' }),
	gradient: {
		main:
			'linear-gradient(0deg, rgba(42,65,79,1) 0%, rgba(82,101,112,1) 100%)',
	},
	mapbutton: {
		main: '#EBEEF1',
	},
	success: {
		contrastText: white,
		dark: colors.green[900],
		main: colors.green[600],
		light: colors.green[400],
	},
	info: {
		contrastText: white,
		dark: colors.blue[900],
		main: colors.blue[600],
		light: colors.blue[400],
	},
	warning: {
		contrastText: white,
		dark: colors.orange[900],
		main: colors.orange[600],
		light: colors.orange[400],
	},
	error: {
		contrastText: white,
		dark: colors.red[900],
		main: colors.red[600],
		light: colors.red[400],
	},
	text: {
		primary: colors.blueGrey[900],
		secondary: colors.grey[500],
		link: colors.blue[600],
	},
	background: {
		default: '#ECEEF0',
		paper: white,
	},
	icon: colors.grey[500],
	divider: colors.grey[200],
};
