import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import SnackBarsComponent from '../SnackBarsComponent';
import CircularProgress from '@material-ui/core/CircularProgress';

import { IsDesktopHandler } from '../../helpers';
import dialogMessages from '../../common/dialogMessages.json';
import classes from './DialogApplicationStages.module.scss';

const DialogApplicationStages = props => {
  const {
    openDialog,
    stageState,
		actionState,
		variant,
		actionType,
    success,
    loading,
    openState
  } = props;
  const [valueState, setValueState] = useState(false);
  const [disableState, setDisableState] = useState(loading);
	const [successState, setSuccessState] = useState(false);
	

  const isDesktop = IsDesktopHandler('md');
  useEffect(() => {
    if (openDialog) {
      setValueState(true);
      setDisableState(false);
    }
  }, [openDialog]);

  useEffect(() => {
    setDisableState(loading);
  }, [loading]);

  useEffect(() => {
    if (success !== undefined) {
      setSuccessState(true);
    }
  }, [success]);

  const handleClick = () => {
    setValueState(false);
    openState(false);
  };

  const handleClickConfirm = async () => {
    await actionState();
    openState(false);
    setValueState(false); //cierra el dialo
  };

  const handleCloseSnackBarsComponent = () => {
    setSuccessState(false);
	};
	
  return (
    <React.Fragment>
      <Dialog
        fullScreen={!isDesktop}
        open={valueState}
        classes={{
          paper: classes.paperStyle
        }}
        onClose={handleClick}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="dialog-title">
        <DialogTitle id="dialog-title" className={classes.title}>
          {dialogMessages.message[actionType].title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="div" className={classes.description}>
            {/* <p className="mb-0">{dialogMessages.message[actionType].subtitle}</p> */}
            <p style={{ fontWeight: 'bold' }}>
              {stageState}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClick} disabled={disableState}>
            Cancelar
          </Button>
          <div className={classes.wrapper}>
            <Button
              onClick={handleClickConfirm}
              variant="contained"
              color="secondary"
							classes={{
								containedSecondary: actionType === 'confirm' ? classes.confirmStyle : classes.refuseStyle
							}}
              autoFocus
              disabled={disableState}
              startIcon={actionType === 'confirm' ? <CheckCircleIcon /> : <CancelIcon />}>
              {actionType === 'confirm' ? 'APROBAR' : 'RECHAZAR'}
            </Button>
            {disableState && (
              <CircularProgress
                style={{ color: 'white' }}
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={successState}
        autoHideDuration={6000}>
        <SnackBarsComponent
          variant={variant}
          message={dialogMessages.message[actionType].confirmation}
          handleClose={handleCloseSnackBarsComponent}
        />
      </Snackbar>
    </React.Fragment>
  );
};

export default DialogApplicationStages;
