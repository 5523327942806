import React, { useEffect, useState } from 'react';
import {
	Divider,
	IconButton,
	Button,
	ListItemText,
	ListItemIcon,
	Menu,
	MenuItem,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import { IsDesktopHandler } from 'helpers';
// import classes from './FilterColumnTableWidget.module.scss';

const useStyles = makeStyles((theme) => ({
	filterContainerStyle: {
		[theme.breakpoints.up('sm')]: {
			position: 'absolute',
			right: 10,
			top: 0,
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			textAlign: 'right',
			marginBottom: 6,
		},
	},
	rootMenuItem: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	rootItemIcon: {
		minWidth: 28,
	},
	filterButton: {
		paddingRight: 6,
		paddingLeft: 6,
		backgroundColor: theme.palette.primary.main,
		borderRadius: 0,
		color: theme.palette.white,
	},
}));

const FilterColumnTableWidget = (props) => {
	const {
		handleFilterClick,
		anchorState,
		handleFilterClose,
		filterOptions,
		toggleColumn,
		toggleAllColumn,
	} = props;

	// console.log(props, '----llega a filters');

	const [toogleAll, setToogleAll] = useState(true);

	const classes = useStyles();

	let newArrayFilter = [...filterOptions];

	const isDesktop = IsDesktopHandler('md');

	const [checkedFilterState, setCheckedFilterState] = useState(
		newArrayFilter.map((c) => c.id)
	);

	const handleToggle = (value) => () => {
		// console.log(value, 'llega a handle togle');

		toggleColumn(value - 1);
		const currentIndex = checkedFilterState.indexOf(value);
		const newChecked = [...checkedFilterState];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setCheckedFilterState(newChecked);
	};

	const handleToggleAll = () => {
		// console.log(checkedFilterState, '----');
		if (!toogleAll) {
			setCheckedFilterState(newArrayFilter.map((c) => c.id));
		} else {
			setCheckedFilterState([]);
		}
		toggleAllColumn(!toogleAll);
		setToogleAll(!toogleAll);
	};

	useEffect(() => {
		let checkedColumns;
		let notCheckedColumns;
		// si no es desktop, no checkea los 5 primeros y los oculta
		if (!isDesktop) {
			checkedColumns = filterOptions.slice(5);
			notCheckedColumns = filterOptions.slice(0, 5);
			notCheckedColumns.map((col) => toggleColumn(col.id - 1));
		} else {
			checkedColumns = filterOptions;
		}
		console.log('-----usf');
		if (props.tab !== '1') {
			setCheckedFilterState(checkedColumns.map((c) => c.id));
		} else {
			let arrayCheckedTab1 = [
				1,
				2,
				3,
				4,
				6,
				8,
				9,
				10,
				11,
				23,
				28,
				29,
				34,
				35,
				37,
				40,
				41,
			];
			setCheckedFilterState(arrayCheckedTab1);
		}
	}, [filterOptions]);

	// console.log(checkedFilterState, '<<<<<<<---checked start');

	return (
		<>
			<div className={classes.filterContainerStyle}>
				<IconButton
					id="filter-button"
					aria-controls="filter-menu"
					aria-haspopup="true"
					color="secondary"
					className={classes.filterButton}
					onClick={handleFilterClick}
					aria-expanded={Boolean(anchorState) ? 'true' : undefined}
				>
					<FilterListIcon />
				</IconButton>
			</div>
			<Menu
				id="filter-menu"
				anchorEl={anchorState}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				open={Boolean(anchorState)}
				onClose={handleFilterClose}
				MenuListProps={{
					'aria-labelledby': 'filter-button',
				}}
			>
				<MenuItem
					key="000"
					role={undefined}
					dense
					onClick={handleToggleAll}
					className={classes.rootMenuItem}
				>
					<ListItemIcon className={classes.rootItemIcon}>
						<Checkbox
							edge="start"
							size="small"
							checked={toogleAll}
							tabIndex={-1}
							disableRipple
							inputProps={{ 'aria-labelledby': '000' }}
						/>
					</ListItemIcon>
					<ListItemText id="000" primary="TODO / NADA" />
				</MenuItem>
				<Divider />
				{newArrayFilter.map((filterOption) => {
					const labelId = `checkbox-list-label-${filterOption.id}`;

					return (
						<MenuItem
							key={filterOption.id}
							role={undefined}
							dense
							onClick={handleToggle(filterOption.id)}
							className={classes.rootMenuItem}
						>
							<ListItemIcon className={classes.rootItemIcon}>
								<Checkbox
									edge="start"
									size="small"
									checked={
										checkedFilterState.indexOf(
											filterOption.id
										) !== -1
									}
									tabIndex={-1}
									disableRipple
									inputProps={{ 'aria-labelledby': labelId }}
								/>
							</ListItemIcon>
							<ListItemText
								id={labelId}
								primary={filterOption.name}
							/>
						</MenuItem>
					);
				})}
				<Divider />

				<MenuItem>
					<Button
						variant="contained"
						color="secondary"
						onClick={handleFilterClose}
					>
						OK
					</Button>
				</MenuItem>
			</Menu>
		</>
	);
};

export default FilterColumnTableWidget;
