import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
	// DataTableWidget,
	SaveAction,
	// SelectMultipleWidget,
	// LoadingWidget,
	// TextFieldWidgetSelectController,
	TextFieldWidgetSelectControllerHorizontal,
	TextFieldWidgetText,
	CardHeaderWidget,
	BreadCrumbs,
	SnackBarsComponent,
	TextFieldWidgetUpload,
	RadioGroupWidget,
	ButtonAction,
	DescriptionAlerts,
} from '../../components';
import { Client, Orchards, Specie } from './Options/OptionsPu';

import alertMessagesFile from '../../common/alertMessagesFile.json';

import {
	// Accordion,
	// AccordionDetails,
	// AccordionSummary,
	// Divider,
	Card,
	CardActions,
	Button,
	CardContent,
	// Hidden,
	// Typography,
	useTheme,
	Snackbar,
	Radio,
	FormControlLabel,
	Paper,
	Typography,
	Grid,
} from '@material-ui/core';
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BsRow, BsCol, BsContainer } from '../../layouts/components';
import { Context } from '../../context/Context';
import { AxiosContext } from '../../context/AxiosContext';
// import viewsTexts from '../../common/viewsTexts.json';
import { makeStyles } from '@material-ui/styles';
// import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { SimpleTabs, TabPanel } from 'components/TabPanel';
import viewsTexts from '../../common/viewsTexts.json';
import {
	// numberFormat,
	// isNumber,
	// findActualYear,
	// alterArrayForSelectVariety,
	// getOrchardVarieties,
	// getOrchardVarietiesDeep,
	alterArrayAddLabels,
	alterArrayGetFields,
	formatRut,
} from '../../helpers';
// import theme from 'theme';
import { FiltersContext } from '../../context';
import { result } from 'lodash';
import { register } from 'serviceWorker';
import AlertMessages from 'components/Alert/Alert';
import { Data } from './Options/OptionsPu';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.tertiary.main,
		fontWeight: 500,
	},
	divider: {
		background: theme.palette.grey[400],
		marginTop: 10,
	},
	rootHelper: {
		fontSize: 17,
	},
	inputText: {
		fontSize: 26,
	},
}));

const excelLoadEndpoint = 'api-task/productive-unit/save-gsheet';

const LoadExcel = (props) => {
	const { userId, userHuerto, userName, userOrchard } = useContext(Context);

	// console.log(userId, userHuerto, userName, "<<<<---context")
	const { state: filterState, addFilter } = useContext(FiltersContext);

	const {
		// orchard_filter,
		// cc_filter,
		// quarter_filter,
		// variety_filter,
		// season_filter,
		userOrchard_filter,
	} = filterState;

	// console.log('----', filterState);

	const axiosContext = useContext(AxiosContext);
	// axiosContext.authAxios //queries with token

	const [filterDataResponseState, setFilterDataResponseState] = useState(
		userOrchard_filter
	);

	const [loadingQueryState, setLoadingQueryState] = useState(false);
	const [loadingValidateState, setLoadingValidateState] = useState(false);
	const [successQueryState, setSuccessQueryState] = useState(false);

	const [orchardsDataComboState, setOrchardsDataComboState] = useState([]);
	const [specieDataState, setSpecieDataState] = useState([]);
	const [clientDataState, setClientDataState] = useState([]);
	const [agroBusinessDataState, setAgroBusinessDataState] = useState([]);
	const [selectedOption, setSelectedOption] = useState('');
	const [loadingFiltersState, setLoadingFiltersState] = useState(false);
	const [filtersDataState, setFiltersDataState] = useState([]);

	const [alertOpen, setAlertOpen] = useState(false);

	const [errorState, setErrorState] = useState(false);

	const [excelMain, setExcelMain] = useState(false);
	const [confirmedDataState, setConfirmedDataState] = useState(false);
	const [disableConfirmButton, setDisableConfirmButton] = useState(false);

	const [dataChangedState, setDataChangedState] = useState(null);
	const [successValidateState, setSuccessValidateState] = useState(false);

	const useStyles = makeStyles((theme) => ({
		paper: {
			padding: theme.spacing(2),
			textAlign: 'center',
			color: theme.palette.text.secondary,
		},
	}));

	const theme = useTheme();

	// console.log(theme)

	const classes = useStyles();

	const {
		handleSubmit,
		control,
		watch,
		reset,
		// setValue,
		getValues,
		// setError,
		// clearErrors,
		formState: { errors },
	} = useForm({
		defaultValues: {
			data_excel: 'no',
		},
	});

	// const watchRut = watch('rut');
	const watchClient = watch('client');

	// const watchOrchard = watch('orchard');

	const onSubmit = async (values, type) => {
		console.log(values, type, '<<<--------');

		if (type === true) {
			setLoadingQueryState(true);
		} else {
			setLoadingValidateState(true);
		}

		setSuccessQueryState(false);
		setSuccessValidateState(false);

		const orchard = getValues('orchard');

		// Encuentra el objeto de orchard correspondiente en orchardsDataComboState
		const orchardObj = orchardsDataComboState.find(
			(orchardObj) => orchardObj.orcharrd === orchard
		);
		setAlertOpen(false);

		let clientObj = clientDataState.filter(
			(ele) => ele.value === values.client
		);

		clientObj = alterArrayGetFields(clientObj, ['id', 'label', 'value']);

		let orchardsObj = transformedOrchardsData.filter(
			(ele) => ele.value === values.orchard
		);

		orchardsObj = alterArrayGetFields(orchardsObj, [
			'id',
			'label',
			'value',
		]);

		console.log(orchardsObj, 'orchardsObj');

		const query = {
			// rut: formatRut(values.rut),
			client: clientObj[0].label,
			clientValue: values.client,
			orchard: orchardsObj[0].label,
			orchardValue: values.orchard,
			username: userName,
			excelMain: excelMain,
			confirmed: type,

			// email: values.email,
			// maternalLastname: values.maternalLastname,
		};

		console.log(query, 'query');

		try {
			const response = await axiosContext.authAxios.post(
				excelLoadEndpoint,
				query,
				{
					timeout: 360000, // Aquí puedes ajustar el tiempo de espera en milisegundos (en este ejemplo, 5 segundos)
				}
			);
			// const bodyInfo = response.Client;

			console.log(response.data, '------response', type);

			if (response.data) {
				if (type === true) {
					setSuccessQueryState(true);
				} else {
					console.log('entra al else');

					setDataChangedState([
						response.data?.menssage?.sucess?.changes,
					]);
					setSuccessValidateState(true);
				}
			}
		} catch (err) {
			// console.log('cae al error', err);
			// console.log('Error', err.message);
			console.log('Error', err.request.status);
			if (err.request.status === 500) {
				setErrorState(true);
			}
		} finally {
			setLoadingQueryState(false);
			setLoadingValidateState(false);
		}
	};

	useEffect(() => {
		// Detectar cambios en el valor del radio button
		if (watch('data_excel') === 'si') {
			setExcelMain(true);
		} else {
			setExcelMain(false);
		}
	}, [watch('data_excel')]);

	const { password } = getValues();

	useEffect(() => {
		if (watchClient) {
			console.log('watchClient', watchClient);
			console.log('clientDataState', clientDataState);

			// Encuentra el cliente seleccionado en clientDataState
			const selectedClient = clientDataState.find(
				(eve) => eve.clientValue === watchClient
			);

			console.log('selectedClient', selectedClient);

			if (selectedClient) {
				// Obtiene los huertos del cliente seleccionado
				const orchards = selectedClient.agroBusinessData.flatMap(
					(agroBusiness) =>
						agroBusiness.orchardData.map((orchard) => ({
							orchard: orchard.orchard,
							orchardValue: orchard.orchardValue,
						}))
				);

				console.log('orchards', orchards);

				// Actualiza el estado con los huertos correspondientes
				setOrchardsDataComboState(orchards);
			}
		}
	}, [watchClient, clientDataState]);

	useEffect(() => {
		if (successQueryState) {
			setAlertOpen(true);
		} else {
			setAlertOpen(false);
		}
	}, [successQueryState]);
	// endpoints

	useEffect(() => {
		getDataFiltersAsync();
	}, []);

	const getDataFiltersAsync = async () => {
		setLoadingFiltersState(true);
		try {
			let response = await axiosContext.authAxios.post(
				'v1/unitProductive/filter-create'
			);

			const { data, status } = response;

			if (status === 200) {
				setFiltersDataState(data);
			}
		} catch (error) {
			console.log(error);
			// setLoadingVarietyFilterState(false);
		} finally {
			setLoadingFiltersState(false);
		}
	};

	useEffect(() => {
		if (filtersDataState) {
			setClientDataState(
				alterArrayAddLabels(filtersDataState.clientData || [], [
					'clientValue',
					'client',
				])
			);
		}
	}, [filtersDataState]);

	const transformedOrchardsData = orchardsDataComboState.map((orchard) => ({
		label: orchard.orchard,
		value: orchard.orchardValue,
	}));

	const handleValidate = (type) => {
		handleSubmit(onSubmit)(type);
	};

	const renderBoxes = () => {
		if (successValidateState) {
			return (
				<>
					<Typography variant="h4" style={{ textAlign: 'left' }}>
						Resumen Validación de carga
					</Typography>
					{/*<Typography variant="h4" style={{textAlign: 'center'}}>Modificación</Typography>*/}
					<br />
					{dataChangedState &&
						dataChangedState.map((item, index) => (
							<Grid container spacing={2} key={index}>
								{/* Iterar sobre las claves y valores del objeto */}
								{Object.entries(item).map(([key, value]) => (
									<Grid item xs={4} key={key}>
										<Paper className={classes.paper}>
											<Typography variant="subtitle1">
												{key}
											</Typography>
											<Typography variant="body1">
												{value}
											</Typography>
										</Paper>
									</Grid>
								))}
							</Grid>
						))}
				</>
			);
		} else {
			return null;
		}
	};

	const handleCloseSnackBarsComponent = () => {
		setErrorState(false);
	};

	return (
		<div className="form-style">
			<BsContainer fluid class="px-0 px-md-3">
				<BsRow class="no-gutters">
					<BsCol class="col-md-12">
						<Card elevation={2}>
							<CardHeaderWidget
								title={viewsTexts.titles.loadexcel.title}
								subheader={<BreadCrumbs subTitle="" />}
							/>
							{/* <Divider /> */}
							<CardContent>
								<BsRow>
									<BsCol class="col-md-12">
										<form
											autoComplete="on"
											// onSubmit={handleSubmit(onSubmit)}
										>
											<BsRow class=" align-items-center">
												<BsCol class="col-lg-4">
													<TextFieldWidgetSelectControllerHorizontal
														options={
															clientDataState
														}
														titleOption="label"
														control={control}
														name="client"
														labeltext="Cliente"
														errors={errors.client}
														defaultValue=""
														loading={
															loadingFiltersState
														}
														req
														withDefault
														variant="outlined"
													/>
													<TextFieldWidgetSelectControllerHorizontal
														options={
															transformedOrchardsData
														}
														titleOption="label"
														control={control}
														name="orchard"
														labeltext="Huerto"
														errors={errors.orchard}
														multiple={false}
														defaultValue=""
														loading={
															loadingFiltersState
														}
														req
														withDefault
														variant="outlined"
													/>
												</BsCol>

												<BsCol class="col-lg-4">
													<RadioGroupWidget
														defaultValue=""
														control={control}
														name="data_excel"
														req={true}
														errors={
															errors.credential
														}
														variant="outlined"
														labeltext="Prioridad origen de datos"
														//onChange={(e) => setSelectedOption(e.target.value)}
													>
														<FormControlLabel
															value="no"
															control={<Radio />}
															label="Base de datos"
														/>
														<FormControlLabel
															value="si"
															control={<Radio />}
															label="Excel"
														/>
													</RadioGroupWidget>
												</BsCol>
												<BsCol class="col-md-2">
													<ButtonAction
														labelButton="Validar"
														color="secondary"
														size="large"
														variant="outlined"
														disabled={
															confirmedDataState ||
															loadingQueryState
														}
														mutationLoading={
															loadingValidateState
														}
														actionOnClick={() =>
															handleValidate(
																false
															)
														}
													/>
												</BsCol>
												<BsCol class="col-md-2">
													<ButtonAction
														labelButton="CONFIRMAR"
														color="secondary"
														size="large"
														variant="contained"
														disabled={
															confirmedDataState ||
															loadingQueryState ||
															loadingValidateState
														}
														mutationLoading={
															loadingQueryState
														}
														messageType="add"
														actionOnClick={() =>
															handleValidate(true)
														}
														success={
															successQueryState
														}
													/>
													{/* <SaveAction
														title="CONFIRMAR"
														color="secondary"
														size="large"
														className="roundedButton ml-md-2"
														variant="contained"
														disabled={
															loadingQueryState
														}
														messageType="add"
														mutationLoading={
															loadingQueryState
														}
														success={
															successQueryState
														}
													/> */}
												</BsCol>
												<BsCol class="col-md-12 mt-4">
													{!excelMain ? (
														<AlertMessages
															severity="info"
															alertTitle="dataBase_info"
															//closeFunc={() => setAlertOpen(false)}
														/>
													) : (
														<AlertMessages
															severity="info"
															alertTitle="excel_info"
															//closeFunc={() => setAlertOpen(false)}
														/>
													)}
												</BsCol>
											</BsRow>
										</form>
									</BsCol>
									<BsCol class="col-md-12 mt-4">
										{successValidateState && renderBoxes()}
									</BsCol>
									{/*{renderBoxes()}*/}
								</BsRow>
								{errorState && (
									<Snackbar
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'center',
										}}
										open={errorState}
										onClose={() => setErrorState(false)}
										autoHideDuration={4500}
										key={Math.random()}
									>
										<SnackBarsComponent
											variant="error"
											message="No hay archivo asociado a los datos ingresados"
											handleClose={
												handleCloseSnackBarsComponent
											}
										/>
									</Snackbar>
								)}
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};
export default LoadExcel;
