import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';

export const useDebouncedSearch = (dataList, searchProperty) => {
	const [searchTermState, setSearchTermState] = useState('');
	const [dataListState, setDataListState] = useState(dataList);
	const [searchResultsState, setSearchResultsState] = useState([]);

	const debouncedSearch = useCallback(
		debounce((term, list) => {
			const filteredResults = list.filter((item) =>
				item[searchProperty].toLowerCase().includes(term.toLowerCase())
			);
			setSearchResultsState(filteredResults);
		}, 300),
		[searchProperty]
	);

	useEffect(() => {
		debouncedSearch(searchTermState, dataListState);
	}, [searchTermState, dataListState, debouncedSearch]);

	const updateDataList = useCallback((newDataList) => {
		setDataListState(newDataList);
	}, []);

	const handleSearch = useCallback((term) => {
		setSearchTermState(term.target.value);
	}, []);

	const clearTerm = useCallback(() => {
		setSearchTermState('');
	}, []);

	return {
		searchDebouncedHandler: handleSearch, // this is the function that will be passed to the input onChange
		searchResults: searchResultsState, // this is the array of results that will be mapped over to display
		updateDataListHandler: updateDataList, // this is the function that will be passed to update the Data if it needs to be changed
		searchTerm: searchTermState, // this is the value of the input
		clearTerm, // this is the function that will be passed to clear the input
	};
};
