import React from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import {
	TextField,
	useTheme,
	InputAdornment,
	Box,
	IconButton,
} from '@material-ui/core';

// const useStyles = makeStyles((theme) => ({}));

const SearchInput = (props) => {
	const { searchTerm, searchFunc, clearFunc, placeholder = 'Buscar' } = props;

	const theme = useTheme();

	return (
		<form noValidate>
			<TextField
				id="searchTermDinamic"
				label=""
				placeholder={placeholder}
				autoComplete="new-password"
				value={searchTerm}
				variant="outlined"
				sx={{
					'& .MuiInputBase-adornedStart': {
						pl: 1,
					},
					'& .MuiInputBase-input': {
						paddingLeft: 0,
					},
					'& .MuiOutlinedInput-input': {
						padding: 1.5,
					},
				}}
				inputProps={{
					autoComplete: 'new-password',
				}}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									width: 40,
									height: 40,
									backgroundColor: theme.palette.grey[50],
									borderRadius:
										theme.shape.borderRadius + 'px',
								}}
							>
								<i className="fas fa-search"></i>
							</Box>
						</InputAdornment>
					),
					endAdornment: (
						<>
							{searchTerm !== '' && (
								<IconButton
									color="primary"
									aria-label="clear"
									component="span"
									onClick={clearFunc}
								>
									<ClearIcon
										fontSize="small"
										style={{
											color: theme.palette.grey[400],
										}}
									/>
								</IconButton>
							)}
						</>
					),
				}}
				// value={name}
				onChange={(e) => searchFunc(e)}
			/>
		</form>
	);
};

export default SearchInput;
