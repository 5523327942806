const unitProductiveAdd = [
    {
    "_id":  {
        "$oid": "6516bb6dd8e6d317f3bcb212"
    }, // El Id de mongo se genera automáticamente al insertar un nuevo registro
        "unitProductiveID": "C-ESM-1",
    "client": "Cerasus",
    "clientValue": "Cerasus", // Representa al Cliente
    "agroBusiness": "Esmeralda",
    "agroBusinessValue": "Esmeralda", // Representa la Razón Social
    "orchard": "Esmeralda",
    "orchardValue": "Esmeralda", // Representa el nombre del huerto
    "specie": "Cerezo",
    "specieValue": "Cerezo", // Representa la especie que contiene el huerto
    "cc": "15 Kordia",
    "ccValue": "15_Kordia", // Representa el centro de costo
    "quarter": "15",
    "quarterValue": "15", // Representa el cuartel
    "variety": "Kordia",
    "varietyValue": "Kordia", // Representa la variedad
    "rootStock": "Gisela",
    "rootStockValue": "Gisela" // Representa el portainjerto
    },
    {
    "_id": {
        "$oid": "6516bb6dd8e6d317f3bcb212"
    }, // El Id de mongo se genera automáticamente al insertar un nuevo registro
        "unitProductiveID": "C-AGRA-1",
    "client": "Cerasus",
    "clientValue": "Cerasus",
    "agroBusiness": "Agricola Arnaiz",
    "agroBusinessValue": "Agricola_Arnaiz",
    "orchard": "Arnaiz",
    "orchardValue": "Arnaiz",
    "specie": "Cerezo",
    "specieValue": "Cerezo",
    "cc": "3",
    "ccValue": "3",
    "quarter": "3 Lapins",
    "quarterValue": "3_Lapins",
    "variety": "Lapins",
    "varietyValue": "Lapins",
    "rootStock": "Colt",
    "rootStockValue": "Colt"
    },
    {
    "_id": {
        "$oid": "6516bb6dd8e6d317f3bcb212"
    }, // El Id de mongo se genera automáticamente al insertar un nuevo registro
        "unitProductiveID": "C-ABD-1",
    "client": "Cerasus",
    "clientValue": "Cerasus",
    "agroBusiness": "Pablo Courbis",
    "agroBusinessValue": "Pablo_Courbis",
    "orchard": "Amalia Bernardita Donoso",
    "orchardValue": "Amalia_Bernardita_Donoso",
    "specie": "Cerezo",
    "specieValue": "Cerezo",
    "cc": "2 Prim 2.5",
    "ccValue": "2_Prim_2.5",
    "quarter": "2",
    "quarterValue": "2",
    "variety": "Prim 2.5",
    "varietyValue": "Prim_2.5",
    "rootStock": "Gisela 12",
    "rootStockValue": "Gisela_12"
    }
];
export  {unitProductiveAdd};