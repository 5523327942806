import React from 'react';
import classes from './FormHelpArea.module.scss';

const FormHelpArea = props => (
	<div className={classes.jumbotron}>
		{props.title && (
			<h3 className={classes.titleStyle}>{props.title}</h3>
		)}
		<div>{props.children}</div>
	</div>
);

export default FormHelpArea;
