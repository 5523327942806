import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { IconButton, Tooltip } from '@material-ui/core';

const IconActionTableRefuse = props => {
  const { handleActionApprove, stageState } = props;

  return (
    <Tooltip title="Rechazar">
      <IconButton aria-label="state" onClick={handleActionApprove}>
        {(stageState === 1 && (
          <CancelIcon style={{ color: 'red' }} fontSize="small" />
        )) ||
          (stageState === 2 && <CheckBoxOutlineBlankIcon fontSize="small" />)}
      </IconButton>
    </Tooltip>
  );
};

export default IconActionTableRefuse;
