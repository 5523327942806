export const filtersReducer = (state, action) => {
	switch (action.type) {
		case 'set_user_orchard':
			return {
				...state,
				isLoading: false,
				userOrchard_filter: action.payload,
			};

		case 'add_filter':
			let newObj = {};

			Object.entries(action.payload).forEach(([key, value]) => {
				return (newObj = {
					...newObj,
					[key]: value,
				});
			});

			// console.log('----->', state, '---', newObj);

			return {
				...state,
				...newObj,
			};

		case 'log_out':
			return action.payload;

		default:
			return state;
	}
};
