import React, { useState, useEffect, useMemo, useContext } from 'react';
import {
	Card,
	CardContent,
	Divider,
	IconButton,
	Paper,
	TextField,
	useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import {
	SearchBasicTable,
	ModalWidget,
	ModalWidgetDetailTable,
	DataTableWidget,
	DialogDelete,
	DialogState,
	IconActionTableDelete,
	IconActionTableState,
	IconActionTableEdit,
	IconActionTableDetail,
	BreadCrumbs,
	CardHeaderWidget,
	EditionAreaWidget,
	LabelChip,
	TextFieldWidgetSelect,
	SearchBasicSelect,
} from '../../../components';
import {
	// handleActionDetail,
	handleActionState,
	handleActionEdit,
	handleActionDelete,
	concatObjectConfigDatatable,
	handleActionEditNew,
	alphabeticalSort,
} from '../../../helpers';

import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
// import '../../../assets/scss/list.scss';
import OmEdit from '../OmEdit';
import viewsTexts from '../../../common/viewsTexts.json';
import { AxiosContext } from '../../../context/AxiosContext';
import { set } from 'lodash';

const useStyles = makeStyles((theme) => ({
	tableContainerStyle: {
		[theme.breakpoints.up('sm')]: {
			paddingRight: 34,
		},
	},
	selectStyle: {
		marginTop: 0,
		marginBottom: 0,
	},
	divider: {
		height: '28px',
		margin: '4px',
	},
	iconButton: {
		padding: '8px',
	},
	actionColumn: {
		display: 'flex',
		justifyContent: 'flex-start',
		width: '100%',
	}
}));

const OmList = () => {
	const theme = useTheme();

	const axiosContext = useContext(AxiosContext);

	const [loadingDataTableState, setLoadingDataTableState] = useState(false);
	const [tableDataState, setTableDataState] = useState([]);
	const [openDialogDeleteState, setOpenDialogDeleteState] = useState(false);
	const [openDialogStateState, setOpenDialogStateState] = useState(false);
	const [
		resetPaginationToggleState,
		setResetPaginationToggleState,
	] = useState(false);

	const [limitState, setLimitState] = useState(10);
	const [sortState, setSortState] = useState({
		key: 'client',
		option: 'asc',
	});
	const [pageState, setPageState] = useState(1);
	const [countState, setCountState] = useState(0);

	const [editionState, setEditionState] = useState(false);
	const [rowToHandleState, setRowToHandleState] = useState('');
	const [rowToEditState, setRowToEditState] = useState('');
	const [modalState, setModalState] = useState({
		openDetail: false,
		detailModal: '',
	});

	const [busyState, setBusyState] = useState(false);

	const [loadingDeleteState, setLoadingDeleteState] = useState(false);
	const [successDeleteState, setSuccessDeleteState] = useState(false);

	const [loadingStateState, setLoadingStateState] = useState(false);
	const [successStateState, setSuccessStateState] = useState(false);

	const [clientDataState, setClientDataState] = useState([]);
	//const [profileDataState, setProfileDataState] = useState([]);
	const [agroBusinessDataState, setAgroBusinessDataState] = useState([]);
	const [orchardDataState, setOrchardDataState] = useState([]);
	const [loadingDataState, setLoadingDataState] = useState(false);

	//const [filterTextUsernameState, setFilterTextUsernameState] = useState(null);
	//const [filterProfileIdState, setFilterProfileIdState] = useState(null);

	const [filterClientIdState, setFilterClientIdState] = useState(null);
	//cliente huerto especie ese orden.. y poner el estado
	const [filterOrchardState, setFilterOrchardState] = useState(null);
	const [filterTextAgrobusinessState,setFilterTextAgrobusinessState] = useState(null);
	const [filterTextSupplierState, setFilterTextSupplierState] = useState(
		null
	);

	//const [formProfileState, setFormProfileState] = useState('');
	//const [formUsernameState, setFormUsernameState] = useState('');

	const [formClientState, setFormClientState] = useState('');
	const [formOrchardState, setFormOrchardState] = useState('');
	const [formSpecieState, setFormSpecieState] = useState('');
	const [formAgrobusinessState, setFormAgrobusinessState] = useState('');

	const classes = useStyles();

	const customStyles = {
		headCells: {
			style: {
				color: 'white',
				backgroundColor: theme.palette.primary.light,
				'&: nth-last-child(1)': {
					backgroundColor: theme.palette.primary.main,
					borderBottom: '4px solid',
					borderColor: theme.palette.secondary.light,
				},
				div: {
					whiteSpace: 'normal',
					wordWrap: 'break-word',
				},
			},
		},
	};

	const searchFunc = async (filterText, type) => {
		let filter = {};

		if (filterText !== '' && type === 'client') {
			const resultClient = clientDataState.find(ele => ele.id === filterText);
			console.log(resultClient, '---resultClient');
			 setFilterClientIdState(resultClient.value);
		}
		if (filterText === '' && type === 'resetClient') {
			setFilterClientIdState(null);
		}
		if (filterText !== '' && type === 'agroBusiness') {
			const resultAgrobusiness = agroBusinessDataState.find(ele => ele.id === filterText);
			console.log(resultAgrobusiness, '---agroBusiness');
			setFilterTextAgrobusinessState(resultAgrobusiness.value);
		}
		if (filterText === '' && type === 'resetAgrobusiness') {
			setFilterTextAgrobusinessState(null);
		}
		if (filterText !== '' && type === 'orchard') {
			setFilterOrchardState(filterText);
		}
		if (filterText === '' && type === 'resetOrchard') {
			setFilterOrchardState(null);
		}

		console.log(filter, '----filter');
	};

	const subHeaderComponentMemo = (
		<>
			<div className="mr-md-4">
				<SearchBasicTable
					resetFunc={() => {
						searchFunc('', 'resetOrchard');
						setFormOrchardState('');
					}}
					id="orchard"
					valuetext={formOrchardState}
					placeholder="Por Huerto"
					filterFunc={() => searchFunc(formOrchardState, 'orchard')}
					onFilter={(value) => setFormOrchardState(value)}
				/>
			</div>
			{/*<div className="mr-md-4">
				<SearchBasicSelect
					resetFunc={() => {
						searchFunc('', 'resetAgrobusiness');
						setFormAgrobusinessState('');
					}}
					id="agroBusiness"
					loading={loadingDataState}
					options={agroBusinessDataState}
					valuetext={formAgrobusinessState}
					placeholder="Agricola"
					filterFunc={() =>
						searchFunc(formAgrobusinessState, 'agroBusiness')
					}
					onFilter={(value) => {
						console.log('valor es : ',value);
						setFormAgrobusinessState(value)
					}}
				</div>
				/>*/}
			<SearchBasicSelect
				resetFunc={() => {
					searchFunc('', 'resetClient');
					setFormClientState('');
				}}
				id="client"
				loading={loadingDataState}
				options={clientDataState}
				valuetext={formClientState}
				placeholder="Por Cliente"
				filterFunc={() => searchFunc(formClientState, 'client')}
				onFilter={(value) => setFormClientState(value)}
			/>

		</>
	);


	const columnsConfig = useMemo(
		
		() => [
			{
				name: 'Cliente',
				selector: (row) => row.client,
				sortField: 'client',
				// hide: 'sm',
				
				sortable: true,
				id: 1, 
			},
			{
				name: 'Agricola',
				selector: (row) => row.agroBusinessFilter && row.agroBusinessFilter.length > 0 ? row.agroBusinessFilter[0].label : '',
				sortField: 'agroBusiness',
				sortable: true,
				id: 2,
			},
			{
				name: 'Huerto',
				selector: (row) => row.label,
				sortField: 'label',
				sortable: true,
				id: 3,
			},
			{
				name: 'Comuna',
				selector: (row) => row.comuna,
				sortField: 'comuna',
				sortable: false,
				id: 4,
			},
			/*{
				name: 'Provincia',
				selector: (row) => row.provincia,
				sortField: 'provincia',
				sortable: false,
				id: 5,
			},*/
			{
				name: 'Region',
				selector: (row) => row.region,
				sortField: 'region',
				sortable: false,
				id: 5,
			},
			/*{
				name: 'Icono',
				selector: (row) => row.icon,
				sortField: 'icon',
				sortable: false,
				id: 7,
			},
			{
				name: 'Latitud',
				selector: (row) => row.location.lat,
				sortField: 'lat',
				sortable: false,
				id: 8,
			},
			{
				name: 'Longitud',
				selector: (row) => row.location.lng,
				sortField: 'lng',
				sortable: false,
				id: 9,
			},*/
			{
				name: 'Temporada',
				selector: (row) => Array.isArray(row.season) ? row.season.join(' - ') : row.season,
				sortField: 'season',
				sortable: false,
				id: 6,
			},
			{
				name: 'Link documento',
				selector: (row) => row.url ? 'Link' : '', // Si hay una URL, muestra "Link"; de lo contrario, muestra una cadena vacía
				sortable: false,
				cell: (row) => row.url ? <a href={row.url} target="_blank" rel="noopener noreferrer">URL</a> : null, // Si hay una URL, muestra el enlace; de lo contrario, muestra nulo para dejar el campo vacío
				id: 9,
			  }
			  
			  
			/*{
		  name: 'Estado',
		  selector: (row) => row.active,
		  cell: (row) => <LabelChip label={row ? 'Activo' : 'Inactivo'} />,
		  sortable: false,
		  id: 9,
		},*/
		],
		[]
	);
	console.log(columnsConfig, '---columnas');



	const actionsConfig = [
		{
			name: 'Acciones',
			width: '200px',
			id: 11,
			cell: (row) => (
				<div className={classes.actionColumn}>
					{row.edit ? (<IconActionTableEdit
						handleActionEdit={() => {
							setRowToEditState(row);
							setEditionState(true);
						}}
					/>) : null}
					{row.edit ? (<IconActionTableDelete
						handleActionDelete={() => DialogDeleteHandler(row)}
					/>) : null}
				</div>
			),
		},
	];
	const actions = concatObjectConfigDatatable(actionsConfig[0]);
	const columns = columnsConfig.concat(actions);


	async function queryData(page = 1, sort = sortState) {
		setLoadingDataTableState(true);
		try {
			setBusyState(true);
			let objQuery = {
				clientValue: filterClientIdState,
				//agroBusinessValue: filterTextAgrobusinessState,
				search: formOrchardState,
				page: page,
				limit: limitState,
				// sort: sort ? sort : sortState,
				sortColumn: sort ? sort.key : sortState.key,
				sortOrder: sort ? sort.option : sortState.option,
			};
			console.log('Sending query with agrobusinessValue:', filterTextAgrobusinessState);
			console.log(objQuery, '---la query que va');

			const response = await axiosContext.authAxios.post(
				'v1/orchard/list-orchard-platform',
				objQuery
			);

			const { data, status } = response;

			console.log(response, '<-----QUERY');

			// setCountState(data.totalPassengers);
			if (status === 200) {
				setTableDataState(data.data);
				setCountState(data.count);
				setPageState(page);
				setSortState(sort);
				setBusyState(false);
			}
			setLoadingDataTableState(false);
		} catch (err) {
			setTableDataState([]);
			setBusyState(false);
			setLoadingDataTableState(false);
			console.log('-----------', err);
		}
	}

	const DialogDeleteHandler = (row) => {
		setRowToHandleState(row);
		setEditionState(false);
		setOpenDialogDeleteState(!openDialogDeleteState);
	};

	const deleteActionDialogHandler = async () => {
		setLoadingDeleteState(true);
		try {
			let objDel = {
				id: rowToHandleState.id,
			};

			// console.log(objDel, '----------------------la query que va');

			const responseDel = await axiosContext.authAxios.post(
				'v1/orchard/delete-orchard-platform',
				objDel
			);

			// console.log(responseDel, '<-----response query');

			if (responseDel.status === 200) {
				setSuccessDeleteState(true);
				setLoadingDeleteState(false);
				setSuccessDeleteState(false);
			}
		} catch (err) {
			console.log('-----------', err);
			setLoadingDeleteState(false);
		}
		setCountState(countState - 1);
		const newUsers = handleActionDelete(rowToHandleState, tableDataState);
		setTableDataState(newUsers);
	};

	const closeEditionAreaHandler = () => {
		setEditionState(false);
	};
	
	const handleActionEditObj = (newObject) => {
		// console.log('asi pasa al editAction', newObject);
		console.log(newObject, '---newObject')
		const newUsers = handleActionEditNew(newObject, tableDataState);
		console.log('new users to table', newUsers,newObject,tableDataState);
		//console.log('new users to table', newUsers);
		setTableDataState(newUsers);
	};
	  
	/*const editActionHandler = (newObject) => {
		// console.log('asi pasa al editAction', newObject);
		const newUsers = handleActionEdit(newObject, tableDataState);

		// console.log('new users to table', newUsers);
		setTableDataState(newUsers);
	};*/

	//const searchTool = false;

	// table functions
	const handleOnSort = async (column, sortDirection) => {
		// console.log('handleSort', column, sortDirection);
		let nameColumn = column.sortField;
		// console.log(column.selector.toString().split(".")[1], "---");
		// let nameColumn = column.selector.toString().split('.')[1];

		if (nameColumn !== null) {
			console.log({
				key: nameColumn,
				option: sortDirection,
			});
			if (!busyState) {
				queryData(pageState, {
					key: nameColumn,
					option: sortDirection,
				});
			}
		}
	};

	const handleChangePagination = (page, totalRows) => {
		console.log(page, totalRows);
		//setPageState(page);
		if (!busyState) {
			queryData(page);
		}
	};

	const handleChangeRows = async (currentRowsPerPage, currentPage) => {
		setLoadingDataTableState(false);
		try {
			let objQuery = {
				clientValue: filterClientIdState,
				search: formOrchardState,
				page: currentPage,
				limit: currentRowsPerPage,
				sortColumn: sortState?.key,
				sortOrder: sortState?.option,
			};

			console.log(objQuery, '---la query que va en handle');

			const response = await axiosContext.authAxios.post(
				'v1/orchard/list-orchard-platform',
				objQuery
			);
			const { data, status } = response;

			// console.log(response, "<-----response change rows")

			// setCountState(data.totalPassengers);
			if (status === 200) {
				// setTableDataState(data.data);
				console.log('eeeeeeee en handle', data);
				setTableDataState(data.data);
				// setCountState(data.count);
				setLimitState(currentRowsPerPage);
			}
			setLoadingDataTableState(false);
		} catch (err) {
			setTableDataState([]);
			setLoadingDataTableState(false);
		}
	};

	const handleCloseModal = () => {
		setModalState({
			...modalState,
			openDetail: false,
		});
	};

	const getClientsAsync = async () => {
		setLoadingDataState(true);
		try {
			let objQuery = {
				page: 1,
				limit: 500,
			};
			let response = await axiosContext.authAxios.post(
				'v1/client/list-clients',
				objQuery
			);

			console.log(response, '<<----respo clientes');

			const { data, status } = response;

			if (status === 200) {
				setClientDataState(alphabeticalSort(data.data, 'label'));
				//setClientDataState(unitProductiveAdd);
				setLoadingDataState(false);
			}
		} catch (error) {
			console.log(error);
			setLoadingDataState(false);
			// setLoadingVarietyFilterState(false);
		}
	};

	const getAgrobusinessAsync = async () => {
		setLoadingDataState(true);
		try {
			let objQuery = {
				page: 1,
				limit: 500,
			};
			let response = await axiosContext.authAxios.post(
				'v1/agrobusiness/list',
				objQuery
			);

			console.log(response, '<<----respo agro');

			const { data, status } = response;

			if (status === 200) {
				setAgroBusinessDataState(data.data);
				setLoadingDataState(false);
			}
		} catch (error) {
			console.log(error);
			setLoadingDataState(false);
			// setLoadingVarietyFilterState(false);
		}
	};

	useEffect(() => {
		queryData(pageState);
		getClientsAsync();
		getAgrobusinessAsync();
	}, []);

	useEffect(() => {
		queryData();
	}, [filterClientIdState, filterTextAgrobusinessState, filterOrchardState ]); 

	useEffect(() => {
		const timeOut = setTimeout(() => {
			queryData();
		}, 500);
		return () => clearTimeout(timeOut);
	}, [formOrchardState]);

	return (
		<div className="form-style">
			<BsContainer fluid class="px-0 px-md-3">
				<BsRow class="no-gutters">
					<BsCol class="col-md-12">
						{editionState && (
							<EditionAreaWidget
								component={
									<OmEdit
										rowEdit={rowToEditState}
										closeFunc={closeEditionAreaHandler}
										editFunc={handleActionEditObj}
									/>
								}
								visible={editionState}
							/>
						)}
						<Card elevation={0}>
							<CardHeaderWidget
								title={viewsTexts.titles.orchard.list.title}
								subheader={<BreadCrumbs subTitle="" />}
							/>
							<CardContent>
								{/* <GenericToolbar search={searchTool} /> */}
								<div
									style={{
										minHeight: '300px',
									}}
								>
									<DataTableWidget
										totalRows={countState}
										handleOnSort={handleOnSort}
										fixedHeader={true}
										fixedHeaderScrollHeight={'590px'}
										// conditionalRowStyles={conditionalRowStyles}
										// sortFunction={customSort}
										changePagination={
											handleChangePagination
										} //onChangePage
										rowsChange={handleChangeRows} //onChangeRowsPerPage
										columns={columns}
										sortField="1"
										filteredItems={tableDataState}
										subHeaderComponentMemo={
											subHeaderComponentMemo
										}
										loading={loadingDataTableState}
										loadingCount={loadingDataTableState}
										customStyles={customStyles}
										moduleName="Usuarios"
										resetPaginationToggle={
											resetPaginationToggleState
										}
									/>
								</div>
								<DialogDelete
									openDialog={openDialogDeleteState}
									rowDelete={rowToHandleState}
									actionDelete={deleteActionDialogHandler}
									variant="success"
									loading={loadingDeleteState}
									success={
										successDeleteState
											? successDeleteState
											: undefined
									}
									openState={setOpenDialogDeleteState}
								/>
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};

export default OmList;
