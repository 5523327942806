import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextFieldWidgetText } from '../../../components';
import { Controller } from 'react-hook-form';

const AutoCompleteWidget = React.memo(props => {
  return (
    <Controller
      render={({ onChange }) => (
        <Autocomplete
          options={props.data}
          getOptionLabel={option =>
            props.dataOptionLabel ? option[props.dataOptionLabel] : option.name
          }
          // renderOption={(option) => (
          //   <React.Fragment>
          //     <span id={option.id}>{option.fullname}</span>
          //   </React.Fragment>
          // )}
          disableClearable={props.disableClearable ? true : false}
          getOptionSelected={
            props.checkId
              ? (option, value) => option.id === value.id
              : (option, value) => option === value
          }
          disabled={props.disabled}
          // value={props.valuecombo ? props.valuecombo : null}
          noOptionsText={props.inicialValue}
          clearText="Limpiar"
          fullWidth
          // onChange={(event, value) => props.onSelectState(value)}
          onChange={(event, value) => props.onSelectState(value)}
          // loadingText={"cargando"}

          renderInput={params => {
            return (
              <TextFieldWidgetText
                {...params}
                control={props.control}
                loading={props.loading}
                name={props.name}
                placeholder={props.inicialValue}
                labeltext={props.label}
                errors={props.errors}
                req={props.req}
              />
            );
          }}
        />
      )}
      defaultValue={props.valuecombo ? props.valuecombo : ''}
      control={props.control}
      name={props.name}
    />
  );
});

export default AutoCompleteWidget;
