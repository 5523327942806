import React from 'react';
import clsx from 'clsx';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { IconButton, Tooltip } from '@material-ui/core';

import classes from './QuestionsTableDetail.module.scss';
import { IconActionTableDelete } from 'components';

const QuestionsTableDetail = props => {
  const { dataQuestions, deleteAction, title } = props;

  return (
    <table className={clsx("table table-borderless table-sm", classes.tableStyle)}>
      <thead>
        <tr>
          <th colSpan="2"><i className="far fa-question-circle"></i> Preguntas de {title}</th>
        </tr>
        <tr>
          <th scope="col">Pregunta</th>
          <th scope="col">Acción</th>
        </tr>
      </thead>
      <tbody>
        {
          dataQuestions.map((question, i) => {
            return (
              <tr key={question.id}>
                <td><b>Pregunta {i + 1}:</b> {question.description}</td>
                <td></td>
              </tr>
            )
          })
        }
        {
          dataQuestions.length === 0 && <tr><td colSpan="2">No hay documentos adjuntos.</td></tr>
        }
      </tbody>
    </table>
  );
};

export default QuestionsTableDetail;
