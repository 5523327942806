import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  makeStyles,
  useTheme
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import DeleteIcon from '@material-ui/icons/Delete';
import SnackBarsComponent from '../SnackBarsComponent';
import CircularProgress from '@material-ui/core/CircularProgress';

import { IsDesktopHandler } from '../../helpers';
import dialogMessages from '../../common/dialogMessages.json';
import classes from './DialogDelete.module.scss';

const useStyles = makeStyles((theme) => ({
	paperStyle: {
		borderRadius: theme.shape.borderRadius,
    minWidth: 400
  },
  buttonDelete: {
    backgroundColor: theme.palette.error.dark,
  }
}));

const DialogDelete = props => {
  const {
    openDialog,
    rowDelete,
    actionDelete,
    loading,
    success,
    openState
  } = props;
  const [valueState, setValueState] = useState(false);
  const [disableState, setDisableState] = useState(loading);
  const [successState, setSuccessState] = useState(success);

  const theme = useTheme();

  // console.log(theme)

  const classesUI = useStyles();

  const isDesktop = IsDesktopHandler('md');
  useEffect(() => {
    if (openDialog) {
      setValueState(true);
      setDisableState(false);
    }
  }, [openDialog]);

  useEffect(() => {
    setDisableState(loading);
  }, [loading]);

  useEffect(() => {
    if (success !== undefined) {
      setSuccessState(true);
    }
  }, [success]);

  const handleClick = (event, reason) => {
		if (reason == 'backdropClick') {
			return false;
		} else {
			setValueState(false);
    	openState(false);
		}
  };

  const handleClickConfirm = async () => {
    await actionDelete();
    openState(false);
    setValueState(false);
  };

  const handleCloseSnackBarsComponent = () => {
    setSuccessState(false);
  };

  // console.log(rowDelete,"en el dialog");

  return (
    <React.Fragment>
      <Dialog
        fullScreen={!isDesktop}
        open={valueState}
        classes={{
          paper: classesUI.paperStyle
        }}
        onClose={handleClick}
        // disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="dialog-title">
        <DialogTitle id="dialog-title" className={classes.title}>
          {dialogMessages.message.delete.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="div" className={classes.description}>
            <p className="mb-0">{dialogMessages.message.delete.subtitle}</p>
            <p style={{ fontWeight: 'bold' }}>
							{rowDelete.id ? `ID ${rowDelete.id} - ` : null}
              {rowDelete.name ? rowDelete.name : null}{' '}
              {rowDelete.names ? rowDelete.names : null}{' '}
              {rowDelete.lastname ? rowDelete.lastname : null}
              {rowDelete.lastNames ? rowDelete.lastNames : null}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClick} disabled={disableState}>
            Cancelar
          </Button>
          <div className={classes.wrapper}>
            <Button
              onClick={handleClickConfirm}
              variant="contained"
              color="primary"
               className={classesUI.buttonDelete}
              autoFocus
              disabled={disableState}
              startIcon={<DeleteIcon />}>
              Eliminar
            </Button>
            {disableState && (
              <CircularProgress
                style={{ color: 'white' }}
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={successState}
        autoHideDuration={6000}>
        <SnackBarsComponent
          variant={props.variant}
          message={dialogMessages.message.delete.confirmation}
          handleClose={handleCloseSnackBarsComponent}
        />
      </Snackbar>
    </React.Fragment>
  );
};

export default DialogDelete;
