import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Modal, Backdrop, Fade, makeStyles, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { BsRow, BsCol, BsContainer } from '../../layouts/components';
// import classes from './ModalWidgetDetailTable.module.scss';
import {
	DocumentsTableDetail,
	QuestionsTableDetail,
	TagsTableDetail,
} from 'components';
import {
	dashDelete,
	dateConvertString,
	getYearsFromBirth,
	numberFormat,
} from 'helpers';
import Typography from 'theme/typography';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paperModal: {
		backgroundColor: theme.palette.background.paper,
		// border: '2px solid #000',
		borderRadius: theme.shape.borderRadius,
		boxShadow: theme.shadows[3],
		padding: theme.spacing(1, 3, 3),
		width: '75%',
		minHeight: '60vh',
		maxHeight: '90vh',
		flexDirection: 'column',
		display: 'flex',
		justifyContent: 'flex-start',
	},
	scrollAreaModalStyle: {
		overflow: 'auto',
		flexGrow: 1,
	},
	divider: {
		background: theme.palette.grey[400],
		marginTop: 10,
	},
	root: {
		color: theme.palette.tertiary.main,
		fontWeight: 500,
	},
	tableStyle: {
		'& th': {
			fontSize: 18,
			lineHeight: 2,
		},
		'& tr td': {
			fontSize: 15,
			paddingTop: 5,
			paddingBottom: 8,
		},
	},
	tdListStyle: {
		listStyle: 'inside',
		'& li': {
			marginBottom: 10,
		},
	},
	labelTd: {
		width: '25%',
	},
}));

const ModalWidgetDetailTable = (props) => {
	const { openDetail, detailModal, moduleName, size, closeFunc } = props;
	const [openStateDetail, setOpenStateDetail] = useState(openDetail);
	console.log(detailModal, 'carga modal');

	const classes = useStyles();

	const handleClose = () => {
		closeFunc(false);
	};

	useEffect(() => {
		setOpenStateDetail(openDetail);
	}, [openDetail]);

	const handleRowTable = (row) => {
		const detail = [];
		for (let fieldName in row) {
			switch (fieldName) {
				case 'lastName':
					detail.push({
						label: 'Apellidos',
						value: row[fieldName],
						order: 1,
					});
					break;
				case 'phone':
					detail.push({
						label: 'Teléfono',
						value: row[fieldName],
						order: 2,
					});
					break;
				case 'rut':
					detail.push({
						label: 'Rut',
						value: row[fieldName],
						order: 3,
					});
					break;
				case 'tote':
					detail.push({
						label: 'Valores Tote',
						value: row[fieldName],
						order: 6,
						map: true,
					});
					break;
				// case 'subcontrators':
				//   detail.push({
				//     label: 'Subcontratista',
				//     value: row[fieldName].name,
				//     order: 4
				//   });
				//   break;
				case 'email':
					detail.push({
						label: 'Email',
						value: row[fieldName],
						order: 5,
					});
					break;
				case 'codeSession':
					detail.push({
						label: 'Código',
						value: row[fieldName],
						order: 6,
					});
					break;
				case 'name':
					detail.push({
						label: 'Nombre',
						value: row[fieldName],
						order: 7,
					});
					break;
				case 'username':
					detail.push({
						label: 'Nombre Usuario',
						value: row[fieldName],
						order: 3,
					});
					break;

				case 'date':
					detail.push({
						label: 'Fecha',
						value: row[fieldName],
						order: 11,
					});
					break;

				case 'orchard':
					detail.push({
						label: 'Huertos',
						value: row[fieldName],
						order: 10,
						map: true,
					});
					break;
				case 'agrobusiness':
					detail.push({
						label: 'Agrícola',
						value: row[fieldName],
						order: 10,
						map: true,
					});
					break;
				case 'birthDate':
					detail.push({
						label: 'Edad',
						value: row[fieldName]
							? getYearsFromBirth(
									dateConvertString(row[fieldName])
							  )
							: '',
						order: 12,
						map: false,
					});
					break;

				default:
					break;
			}
		}
		return detail;
	};

	const showDetailTable = (moduleName) => {
		switch (moduleName) {
			case 'Usuarios':
				return null;

			case 'Proyectos':
				return (
					<DocumentsTableDetail
						dataDocs={detailModal.document}
						title="Proyecto"
					/>
				);

			case 'Tipo de Tag':
				return (
					<TagsTableDetail dataDocs={detailModal.tag} title="Tags" />
				);

			case 'Tipo de Protocolos':
				return (
					<DocumentsTableDetail
						dataDocs={detailModal.document}
						title="Tipo de Protocolos"
					/>
				);

			case 'Contratista':
				return (
					<DocumentsTableDetail
						dataDocs={detailModal.document}
						title="Contratista"
					/>
				);

			case 'Tipo de Actividad':
				return (
					<DocumentsTableDetail
						dataDocs={detailModal.document}
						title="Tipo de Actividad"
					/>
				);

			case 'Cuadrillas':
				return null;

			case 'CheckLists':
				return (
					<QuestionsTableDetail
						dataQuestions={detailModal.questions}
						title="CheckLists"
					/>
				);

			default:
				return null;
		}
	};

	return (
		<Modal
			aria-labelledby="modal-title"
			aria-describedby="modal-description"
			className={classes.modal}
			open={openStateDetail}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			{/* <Fade in={openStateDetail}> */}
			<div className={classes.paperModal}>
				<div>
					<BsContainer fluid>
						<BsRow>
							<BsCol class="col-md-12 text-right pr-0">
								<Button onClick={handleClose}>
									<CloseIcon />
								</Button>
							</BsCol>
						</BsRow>
					</BsContainer>
				</div>
				<div className={classes.scrollAreaModalStyle}>
					<BsContainer fluid>
						<BsRow>
							<BsCol class="col-12">
								<table
									className={clsx(
										'table table-borderless table-sm',
										classes.tableStyle
									)}
								>
									<thead>
										<tr>
											<th colSpan="2">
												<i className="far fa-address-card"></i>{' '}
												Información
											</th>
										</tr>
									</thead>
									<tbody>
										{detailModal &&
											handleRowTable(detailModal).map(
												(item) => {
													if (
														item.map &&
														(moduleName ===
															'Usuarios' ||
															moduleName ===
																'Carga')
													) {
														return (
															<tr
																key={item.label}
															>
																<td
																	className={
																		classes.labelTd
																	}
																>
																	{item.label}
																</td>
																<td
																	className={
																		classes.tdListStyle
																	}
																>
																	<ul>
																		{item.value
																			? item.value.map(
																					(
																						ele,
																						i
																					) => {
																						return (
																							<li
																								key={`${ele.id}_${i}`}
																							>
																								{
																									ele?.label
																								}
																								{dashDelete(
																									ele?.orchard
																								)}
																							</li>
																						);
																					}
																			  )
																			: ''}
																	</ul>
																</td>
															</tr>
														);
													}

													return (
														<tr key={item.label}>
															<td
																className={
																	classes.labelTd
																}
															>
																{item.label}
															</td>
															<td>
																{item.value}
															</td>
														</tr>
													);
												}
											)}
									</tbody>
								</table>
							</BsCol>
							<BsCol class="col-md-12">
								{showDetailTable(moduleName)}
							</BsCol>
						</BsRow>
					</BsContainer>
				</div>
			</div>
			{/* </Fade> */}
		</Modal>
	);
};
export default ModalWidgetDetailTable;
