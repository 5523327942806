import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import classes from './GenericToolbar.module.scss';

import { SearchInput } from 'components';

const GenericToolbar = props => {
    const { className, search, ...rest } = props;

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <div className={classes.row}>
                <span className={classes.spacer} />
                <Button className={classes.exportButton}>Export PDF</Button>
                <Button className={classes.exportButton}>Export CSV</Button>
            </div>

            {search && <div className={classes.row}>
                <SearchInput
                    className={classes.searchInput}
                    placeholder="Search"
                />
            </div>}
        </div>
    );
};

GenericToolbar.propTypes = {
    className: PropTypes.string,
    search: PropTypes.bool
};

export default GenericToolbar;
