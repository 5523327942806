const Client = [
	{
		id: '63ff5a0c43f82fe93544ec5b',
		label: 'Alex Sedano',
		value: 'Alex_Sedano',
		orchard: [
			{
				id: '63ff55716ada16f93b35b798',
				label: 'Orchard 11',
				value: 'Orchard_11',
                url: 'https://www.bing.com',
			},
			{
				id: '63ff580843f82fe93544ec52',
				label: 'Orchard 21',
				value: 'Orchard_21',
                url: 'https://www.google.com',
			},
		],
	},
	{
		id: '63ff5a0c43f82fe935455555',
		label: 'Garces Fruit',
		value: 'Graces_Fruit',
		orchard: [
			{
				id: '63ff579843f82fe93544ec4c',
				label: 'Orchard 31',
				value: 'Orchard_31',
                url: 'https://www.youtube.com'
			},
			{
				id: '63ff57be43f82fe93544ec4f',
				label: 'Orchard 41',
				value: 'Orchard_41',
                url: 'https://www.emol.com'
			},
		],
	},
];



export { Client};
