import React, { useState, useEffect, useMemo, useContext } from 'react';
import {
	Card,
	CardContent,
	Divider,
	IconButton,
	Paper,
	TextField,
	useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import {
	SearchBasicTable,
	ModalWidget,
	ModalWidgetDetailTable,
	DataTableWidget,
	DialogDelete,
	DialogState,
	IconActionTableDelete,
	IconActionTableState,
	IconActionTableEdit,
	IconActionTableDetail,
	BreadCrumbs,
	CardHeaderWidget,
	EditionAreaWidget,
	LabelChip,
	TextFieldWidgetSelect,
	SearchBasicSelect,
} from '../../../components';
import {
	// handleActionDetail,
	handleActionState,
	handleActionEdit,
	handleActionDelete,
	concatObjectConfigDatatable,
} from '../../../helpers';

import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
// import '../../../assets/scss/list.scss';
//import PuEdit from '../PuEdit';
import HdEdit from '../HdEdit';
import viewsTexts from '../../../common/viewsTexts.json';
import { AxiosContext } from '../../../context/AxiosContext';
import { get, set } from 'lodash';
import { unitProductiveAdd } from '../HdDatos/HdDatos';

const useStyles = makeStyles((theme) => ({
	tableContainerStyle: {
		[theme.breakpoints.up('sm')]: {
			paddingRight: 34,
		},
	},
	selectStyle: {
		marginTop: 0,
		marginBottom: 0,
	},
	divider: {
		height: '28px',
		margin: '4px',
	},
	iconButton: {
		padding: '8px',
	},
}));


const HdList = () => {

	const theme = useTheme();

	const axiosContext = useContext(AxiosContext);

	const [loadingDataTableState, setLoadingDataTableState] = useState(false);
	const [tableDataState, setTableDataState] = useState([]);
	const [openDialogDeleteState, setOpenDialogDeleteState] = useState(false);
	const [openDialogStateState, setOpenDialogStateState] = useState(false);
	const [
		resetPaginationToggleState,
		setResetPaginationToggleState,
	] = useState(false);

	const [limitState, setLimitState] = useState(10);
	const [sortState, setSortState] = useState({
		key: 'name',
		option: 'asc',
	});
	const [pageState, setPageState] = useState(1);
	const [countState, setCountState] = useState(0);

	const [editionState, setEditionState] = useState(false);
	const [rowToHandleState, setRowToHandleState] = useState('');
	const [rowToEditState, setRowToEditState] = useState('');
	const [modalState, setModalState] = useState({
		openDetail: false,
		detailModal: '',
	});

	const [busyState, setBusyState] = useState(false);

	const [loadingDeleteState, setLoadingDeleteState] = useState(false);
	const [successDeleteState, setSuccessDeleteState] = useState(false);

	const [loadingStateState, setLoadingStateState] = useState(false);
	const [successStateState, setSuccessStateState] = useState(false);

	const [clientDataState, setClientDataState] = useState([]);
	//const [profileDataState, setProfileDataState] = useState([]);
	const [agroBusinessDataState, setAgroBusinessDataState] = useState([]);
	const [orchardDataState, setOrchardDataState] = useState([]);
	const [loadingDataState, setLoadingDataState] = useState(false);

	//const [filterTextUsernameState, setFilterTextUsernameState] = useState(null);
	//const [filterProfileIdState, setFilterProfileIdState] = useState(null);

	const [filterClientIdState, setFilterClientIdState] = useState(null);
	//cliente huerto especie ese orden.. y poner el estado 
	const [filterOrchardState, setFilterOrchardState] = useState(null);
	const [filterTextSpecieState, setFilterTextSpecieState] = useState(null);
	const [filterTextSupplierState, setFilterTextSupplierState] = useState(null);

	
	//const [formProfileState, setFormProfileState] = useState('');
	//const [formUsernameState, setFormUsernameState] = useState('');

	const [formClientState, setFormClientState] = useState('');
	const [formOrchardState, setFormOrchardState] = useState('');
	const [formSpecieState, setFormSpecieState] = useState('');
	const [formSupplierState, setFormSupplierState] = useState('');

	

	const classes = useStyles();

	const customStyles = {
		headCells: {
			style: {
				color: 'white',
				backgroundColor: theme.palette.primary.light,
				'&: nth-last-child(1)': {
					backgroundColor: theme.palette.primary.main,
					borderBottom: '4px solid',
					borderColor: theme.palette.secondary.light,
				},
				div: {
					whiteSpace: 'normal',
					wordWrap: 'break-word',
				},
			},
		},
	};

	const searchFunc = async (filterText, type) => {
		let filter = {};
		if (filterText !== '' && type === 'client') {
			const resultClient = unitProductiveAdd.find(ele => ele.clientValue === filterText);
			setFilterClientIdState(resultClient.clientValue);
		}
		if (filterText === '' && type === 'resetClient') {
			setFilterClientIdState(null);
		}
		if (filterText !== '' && type === 'orchard') {
			const resultOrchard = unitProductiveAdd.find(ele => ele.orchardValue === filterText);
			setFilterOrchardState(resultOrchard.orchardValue);
		}
		if (filterText === '' && type === 'resetOrchard') {
			setFilterOrchardState(null);
		}
	};
	

	const subHeaderComponentMemo = (
		<>
			<div className="mr-md-4">
			<SearchBasicSelect
					resetFunc={() => {
						searchFunc('', 'resetOrchard');
						setFormOrchardState('');
					}}
					id="orchard"
					loading={loadingDataState}
					options={orchardDataState}
					valuetext={formOrchardState}
					placeholder="Por Huerto"
					filterFunc={() => searchFunc(formOrchardState, 'orchard')}
					onFilter={(value) => setFormOrchardState(value)}
				/>

			</div>
			<SearchBasicSelect
				resetFunc={() => {
					searchFunc('', 'resetClient');
					setFormClientState('');
				}}
				id="client"
				loading={loadingDataState}
				options={clientDataState}
				valuetext={formClientState}
				placeholder="Por Cliente"
				filterFunc={() => searchFunc(formClientState, 'client')}
				onFilter={(value) => setFormClientState(value)}
			/>
		</>
	);

	const columnsConfig = useMemo(() => [
		{
			name: 'Cliente',
			selector: (row) => row.client,
			sortField: 'client',
			// hide: 'sm',
			sortable: true,
			id: 1,
		},
		/*
		{
		  name: 'Agricola',
		  selector: (row) => row.agroBusiness,
		  sortField: 'agroBusiness',
		  sortable: false,
		  id: 2,
		},*/
		{
		  name: 'Huerto',
		  selector: (row) => row.orchard,
		  sortField: 'orchard',
		  sortable: false,
		  id: 2,
		},
		/*
		{
		  name: 'Proveedor',
		  selector: (row) => row.supplier,
		  sortField: 'supplier',
		  sortable: false,
		  id: 4,
		},
		{
		  name: 'Credencial',
		  selector: (row) => row.credential,
		  sortField: 'credential',
		  sortable: false,
		  id: 5,
		},*/
		/*{
		  name: 'Temporada',
		  selector: (row) => row.season,
		  sortField: 'season',
		  sortable: false,
		  id: 9,
		},*/
		{
		  name: 'Fecha',
		  selector: (row) => row.date,
		  sortField: 'date',
		  sortable: false,
		  id: 3,
		},
		{
		  name: 'Estado',
		  selector: (row) => row.active,
		  cell: (row) => <LabelChip label={row ? 'Activo' : 'Inactivo'} />,
		  sortable: false,
		  id: 4,
		},
	  ], []);

	const actionsConfig = [
		{
			name: 'Acciones',
			width: '200px',
			id: 11,
			cell: (row) => (
				<div className="actionColumn">
					{/*<IconActionTableEdit
						handleActionEdit={() => {
							setRowToEditState(row);
							setEditionState(true);
						}}
					/>*/}
					<IconActionTableDelete
						handleActionDelete={() => DialogDeleteHandler(row)}
					/>
				</div>
			),
		},
	];
	const actions = concatObjectConfigDatatable(actionsConfig[0]);
	const columns = columnsConfig.concat(actions);

// Aquí puedes aplicar las correcciones y mejoras sugeridas

async function queryData(page, sort = sortState) {
    setLoadingDataTableState(true);
    console.log(sortState, 'sortState');
    try {
        let objQuery = {
            search: formClientState,
            page: page,
            limit: limitState,
            sortColumn: sort ? sort.key : sortState.key,
            sortOrder: sort ? sort.option : sortState.option,
        };

        console.log(objQuery, '----------------------la query que va');

        const response = { data: { data: unitProductiveAdd }, status: 200 };

        const { data, status } = response;

        console.log(response, '<-----response query');

        if (status === 200) {
            console.log(unitProductiveAdd, '<<----data usuarios');
            setTableDataState(unitProductiveAdd);
            setCountState(unitProductiveAdd.count);
            setPageState(page);
            setSortState(sort);
            setBusyState(false);
            console.log('Datos actualizados en query', unitProductiveAdd);
        }
        setLoadingDataTableState(false);
    } catch (err) {
        console.error('Error en queryData:', err);
        setTableDataState([]);
        setBusyState(false);
        setLoadingDataTableState(false);
    }
}

// Resto del código con las correcciones y mejoras sugeridas


	const DialogDeleteHandler = (row) => {
		setRowToHandleState(row);
		setEditionState(false);
		setOpenDialogDeleteState(!openDialogDeleteState);
	};

	const deleteActionDialogHandler = async () => {
		setLoadingDeleteState(true);
		try {
			let objDel = {
				id: rowToHandleState.id,
			};

			console.log(objDel, '----------------------la query que va');

			const responseDel = await axiosContext.authAxios.post(
				'v1/user/delete-user',
				objDel
			);

			console.log(responseDel, '<-----response query');

			if (responseDel.status === 200) {
				setSuccessDeleteState(true);
				setLoadingDeleteState(false);
				setSuccessDeleteState(false);
			}
		} catch (err) {
			console.log('-----------', err);
			setLoadingDeleteState(false);
		}
		setCountState(countState - 1);
		const newUsers = handleActionDelete(rowToHandleState, tableDataState);
		setTableDataState(newUsers);
	};


	const closeEditionAreaHandler = () => {
		setEditionState(false);
	};

	const editActionHandler = (newObject) => {
		// console.log('asi pasa al editAction', newObject);
		const newUsers = handleActionEdit(newObject, tableDataState);

		console.log('new users to table', newUsers);
		setTableDataState(newUsers);
	};

	//const searchTool = false;

	// table functions
	const handleOnSort = async (column, sortDirection) => {
		// console.log('handleSort', column, sortDirection);
		let nameColumn = column.sortField;
		// console.log(column.selector.toString().split(".")[1], "---");
		// let nameColumn = column.selector.toString().split('.')[1];

		if (nameColumn !== null) {
			console.log({
				key: nameColumn,
				option: sortDirection,

			});
			console.log('llegue')
			if (!busyState) {
				queryData(pageState, {
					key: nameColumn,
					option: sortDirection,
				});
				console.log(queryData, 'queryData')
			}
		}
	};

	const handleChangePagination = (page, totalRows) => {
		console.log(page, totalRows);
		// setPageState(page);
		if (!busyState) {
			queryData(page);
		}
	};

	const handleChangeRows = async (currentRowsPerPage, currentPage) => {
		setLoadingDataTableState(false);
		try {
			let objQuery = {
				page: currentPage,
				limit: currentRowsPerPage,
				sort: sortState,
			};

			console.log(objQuery, '---la query que va en handle');

			const response = await axiosContext.authAxios.post(
				'v1/user/list-users',
				objQuery
			);
			const { data, status } = response;

			// console.log(response, "<-----response change rows")

			// setCountState(data.totalPassengers);
			if (status === 200) {
				// setTableDataState(data.data);
				console.log('eeeeeeee en handle', data);
				setTableDataState(data.data);
				// setCountState(data.count);
				setLimitState(currentRowsPerPage);
			}
			setLoadingDataTableState(false);
		} catch (err) {
			setTableDataState([]);
			setLoadingDataTableState(false);
		}
	};

	const handleCloseModal = () => {
		setModalState({
			...modalState,
			openDetail: false,
		});
	};

	const getClientsAsync = async () => {
		setLoadingDataState(true);
		try {
			let objQuery = {
				page: 1,
				limit: 1000,
				order: 'asc',
			};
			let response = await axiosContext.authAxios.post(
				'v1/client/list-clients',
				objQuery
			);

			console.log(response, '<<----respo clientes');

			const {data, status } = response;

			if (status === 200) {
				setClientDataState(data.data);
				//setClientDataState(unitProductiveAdd);
				setLoadingDataState(false);
				console.log(unitProductiveAdd, '<<----data clientes');
			}
		} catch (error) {
			console.log(error);
			setLoadingDataState(false);
			// setLoadingVarietyFilterState(false);
		}
	};

	const getOrchardsAsync = async () => {
		setLoadingDataState(true);
		
		try {

			let objQuery = {
				page: 1,
				limit: 1000,
			};

			let response = await axiosContext.authAxios.post(
				'v1/orchard/list-orchard-platform',
				objQuery
			);

			console.log(response, '<<----respo huertos');

			const {data, status } = response;

			if (status === 200) {
				setOrchardDataState(data.data);
				//setOrchardDataState(unitProductiveAdd);
				setLoadingDataState(false);
			}
		} catch (error) {
			console.log(error);
			setLoadingDataState(false);
			// setLoadingVarietyFilterState(false);
		}
	};




	useEffect(() => {
		queryData(pageState);
		getClientsAsync();
		getOrchardsAsync();
	}, []);

	useEffect(() => {
		queryData();
	}, [filterClientIdState, filterTextSpecieState, filterOrchardState]);

	// hacer un use effect para que ante cambios de formUsernameState
	// se haga la busqueda con un debounce de 300ms


	return (
		<div className="form-style">
			<BsContainer fluid class="px-0 px-md-3">
				<BsRow class="no-gutters">
					<BsCol class="col-md-12">
						{editionState && (
							<EditionAreaWidget
								component={
									<HdEdit
										rowEdit={rowToEditState}
										closeFunc={closeEditionAreaHandler}
										editFunc={editActionHandler}
									/>
								}
								visible={editionState}
							/>
						)}
						<Card elevation={0}>
							<CardHeaderWidget
								title={viewsTexts.titles.historicData.list.title}
								subheader={<BreadCrumbs subTitle="" />}
							/>
							<CardContent>
								{/* <GenericToolbar search={searchTool} /> */}
								<div
									style={{
										minHeight: '300px',
									}}
								>
									<DataTableWidget
										totalRows={countState}
										handleOnSort={handleOnSort}
										fixedHeader={true}
										fixedHeaderScrollHeight={'590px'}
										// conditionalRowStyles={conditionalRowStyles}
										// sortFunction={customSort}
										changePagination={
											handleChangePagination
										} //onChangePage
										rowsChange={handleChangeRows} //onChangeRowsPerPage
										columns={columns}
										sortField="1"
										filteredItems={tableDataState}
										subHeaderComponentMemo={
											subHeaderComponentMemo
										}
										loading={loadingDataTableState}
										loadingCount={loadingDataTableState}
										customStyles={customStyles}
										moduleName="Usuarios"
										resetPaginationToggle={
											resetPaginationToggleState
										}
									/>
								</div>
								<DialogDelete
									openDialog={openDialogDeleteState}
									rowDelete={rowToHandleState}
									actionDelete={deleteActionDialogHandler}
									variant="success"
									loading={loadingDeleteState}
									success={
										successDeleteState
											? successDeleteState
											: undefined
									}
									openState={setOpenDialogDeleteState}
								/>
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};

export default HdList;
