import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import errorMessage from '../../../common/errorMessages.json';
const TextFieldWidgetDescription = props => {
  const {
    control,
    errors,
    name,
    labeltext,
    defaultValue,
    req,
    ...others
  } = props;
  const [stateMessage, setStateMessage] = useState('');
  const [stateError, setStateError] = useState(false);
  // useEffect(() => {
  //   if (errors && errors.message) {
  //     setStateMessage(errors.message);
  //     setStateError(true);
  //   } else {
  //     setStateMessage('');
  //     setStateError(false);
  //   }
  // }, [errors]);

  useEffect(() => {
    if (errors) {
      switch (errors.type) {
        case 'required':
          setStateMessage(
            errorMessage.message[errors.ref.name].required
              ? errorMessage.message[errors.ref.name].required
              : errorMessage.message[errors.ref.name]
          );
          break;
        case 'pattern':
          setStateMessage(errorMessage.message[errors.ref.name].pattern);
          break;
        case 'maxLength':
          setStateMessage(errorMessage.message[errors.ref.name].maxLength);
          break;
        case 'minLength':
          setStateMessage(errorMessage.message[errors.ref.name].minLength);
          break;
        case 'menorQue':
          setStateMessage(errorMessage.message[errors.ref.name].menorQue);
          break;
        case 'mayorQue':
          setStateMessage(errorMessage.message[errors.ref.name].mayorQue);
          break;
        case 'rutValido':
          setStateMessage(errorMessage.message[errors.ref.name].rutValido);
          break;
        case 'matchesPreviousPassword':
          setStateMessage(
            errorMessage.message[errors.ref.name].matchesPreviousPassword
          );
          break;
        case 'sizeFileValidate':
          setStateMessage('error en el doc, de peso');
          break;
        case 'typeFileValidate':
          setStateMessage('error en el doc, de tipo');
          break;
        default:
          break;
      }
      setStateError(true);
    } else {
      setStateMessage('');
      setStateError(false);
    }
  }, [errors]);
  return (
    <Controller
      as={
        <TextField
          {...others}
          label={labeltext}
          variant="outlined"
          multiline
          fullWidth
          rows="3"
          margin="dense"
          error={stateError}
          helperText={stateMessage}
        />
      }
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: req
      }}
    />
  );
};
TextFieldWidgetDescription.propTypes = {
  name: PropTypes.string.isRequired,
  req: PropTypes.bool,
  labeltext: PropTypes.string.isRequired,
  loading: PropTypes.bool
};
export default TextFieldWidgetDescription;
