import React from 'react';
import { Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import classes from './NotNetwork.module.scss';

const NotNetwork = () => {
  return (
    <div className={classes.root}>
      <BsContainer fluid>
        <BsRow class="justify-content-md-center">
          <BsCol class="col-lg-6">
            <div className={classes.content}>
              <Typography variant="h1">Error de conexión</Typography>
              <Typography variant="subtitle2">
                Puedes probar volver hacia atrás o{' '}
                <RouterLink className={classes.link} to="/">
                  click aquí{' '}
                </RouterLink>{' '}
                para ir al home.
              </Typography>
              <img
                alt="Under development"
                className={classes.image}
                src="/images/undraw_signal_searching_bhpc.svg"
              />
            </div>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default NotNetwork;
