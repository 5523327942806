import _ from 'lodash';
import React from 'react';
import { dateFormatIso8601, numberFormat } from './functionHelpers';
import UserStatus from '../common/userStatus.json';
import ProfessionalStatus from '../common/professionalStatus.json';
import SessionClosedPatientStatus from '../common/sessionClosedPatientStatus.json';

const getText = (propName, stateProp) => {
	switch (propName) {
		case 'attendanceSee':
			if (stateProp === 'true') {
				return 'Sí';
			} else {
				return 'No';
			}
		case 'documentSee':
			if (stateProp === 'true') {
				return 'Sí';
			} else {
				return 'No';
			}
		default:
			return null;
	}
};

export const handleObservationStage = (stage) => {
	const detail = [];
	detail.push({
		label: 'Observación',
		value: stage,
	});
	const detailList = detail.map((it) => {
		if (it.value !== null) {
		}
		return (
			<span
				key={it.label}
				style={{
					display: 'block',
					margin: '8px',
					padding: '3px',
				}}
			>
				{it.value}
			</span>
		);
	});
	return detailList;
};

export const handleActionDetail = (row) => {
	console.log(row, 'la row que llega');
	const detail = [];
	for (let fieldName in row) {
		switch (fieldName) {
			case 'name':
				detail.push({
					label: 'nombres',
					value: row[fieldName],
					order: 1,
				});
				break;
			case 'lastname':
				detail.push({
					label: 'apellidos',
					value: row[fieldName],
					order: 2,
				});
				break;
			case 'email':
				detail.push({
					label: fieldName,
					value: row[fieldName],
					order: 4,
				});
				break;
			case 'rut':
				detail.push({
					label: fieldName,
					value: row[fieldName],
					order: 3,
				});
				break;
			case 'phone':
				detail.push({
					label: 'teléfono',
					value: row[fieldName],
					order: 5,
				});
				break;
			case 'user':
				detail.push({
					label: 'Nombre usuario',
					value: row.user.fullname,
					order: 6,
				});
				break;
			case 'description':
				detail.push({
					label: 'Descripción',
					value: row[fieldName],
					order: 7,
				});
				break;
			case 'reason':
				detail.push({
					label: 'Motivo',
					value: row[fieldName],
					order: 8,
				});
				break;
			case 'observation':
				detail.push({
					label: 'Observación',
					value: row[fieldName],
					order: 9,
				});
				break;
			case 'accident':
				detail.push({
					label: 'Estado Vehículo',
					value: row[fieldName],
					order: 10,
				});
				break;
			case 'date':
				detail.push({
					label: 'Fecha',
					// value: dateFormatIso8601(row[fieldName]),
					value: row[fieldName],
					order: 11,
				});
				break;
			case 'hour':
				detail.push({
					label: 'Hora',
					value: row[fieldName],
					order: 12,
				});
				break;
			case 'car':
				detail.push({
					label: 'Vehículo',
					value:
						row[fieldName].make.name +
						' | ' +
						row[fieldName].model.name +
						' | KM: ' +
						row[fieldName].mileage +
						' | Tasación: ' +
						numberFormat(row[fieldName].assessment),
					order: 13,
				});
				break;
			case 'year':
				detail.push({
					label: 'Año',
					value: row[fieldName],
					order: 14,
				});
				break;
			case 'hrefCalendly':
				detail.push({
					label: 'Link Reserva Calendly',
					value: row[fieldName] ? row[fieldName] : '-',
					order: 15,
				});
				break;
			default:
				break;
		}
	}

	function dynamicSort(property) {
		return function(a, b) {
			return a[property] < b[property]
				? -1
				: a[property] > b[property]
				? 1
				: 0;
		};
	}

	detail.sort(dynamicSort('order'));

	const detailList = detail.map((it) => {
		if (it.value !== null) {
		}
		return (
			<span
				key={it.label}
				style={{
					display: 'block',
					margin: '8px',
					padding: '3px',
					whiteSpace: 'pre-line',
				}}
			>
				<b style={{ textTransform: 'capitalize' }}>{it.label}:</b>{' '}
				{it.value}
			</span>
		);
	});
	return detailList;
};

export const handleActionEditNew = (newObject, allObjects) => {
	const newArrayToEdit = [...allObjects];
	// find in data the index of the object to edit
	const index = newArrayToEdit.findIndex((obj) => obj.id === newObject.id);
	// replace the object in the array with the new edited object
	newArrayToEdit[index] = newObject;
  
	return newArrayToEdit;
  };

export const handleActionEditObj = (newObject, allObjects) => {
	const newArrayEdit = allObjects.map((obj) => {
		return obj.id === parseInt(newObject.id) ? newObject : obj;
	});
	return newArrayEdit;
};

export const handleActionEdit = (newValuesToEdit, users) => {
	const newArrayEdit = users.map((user) => {
		if (user.id !== newValuesToEdit.id) {
			return user;
		} else {
			console.log('entra con', user);
			for (let fieldName in user) {
				if (
					fieldName === 'email' ||
					fieldName === 'code' ||
					fieldName === 'name' ||
					fieldName === 'lastname' ||
					fieldName === 'phone' ||
					fieldName === 'rut' ||
					fieldName === 'businessDays' ||
					fieldName === 'since' ||
					fieldName === 'until' ||
					fieldName === 'year' ||
					fieldName === 'description' ||
					fieldName === 'maternalLastname' ||
					fieldName === 'paternalLastname' ||
					fieldName === 'nameuser' ||
					fieldName === 'username' ||
					fieldName === 'profile' ||
					fieldName === 'client'
				) {
					_.set(user, fieldName, newValuesToEdit[fieldName]);
				}
				// else if (fieldName === 'profile') {
				// 	_.set(user.profile, ['id'], newValuesToEdit[fieldName]);
				// 	// ACA DEBERÍA CAMBIAR EL PROFILE NAME--------------------
				// }
				else if (fieldName === 'type') {
					_.set(user.type, ['id'], newValuesToEdit[fieldName]);
					// ACA DEBERÍA CAMBIAR EL TYPE NAME--------------------
				} else if (
					fieldName === 'attendance' ||
					fieldName === 'document'
				) {
					_.set(user, fieldName, newValuesToEdit[fieldName]);
					// const propName = fieldName + 'See';
					// const stateProp = newValuesToEdit[fieldName].toString();
					// let valueProp = getText(propName, stateProp);
					// _.set(user, propName, valueProp);
				} else if (fieldName === 'specialty') {
					_.set(user.specialty, ['id'], newValuesToEdit[fieldName]);
					// ACA HAY QUE BUSCAR NUEVO NOMBRE
					let valueProp = 'especialidad';
					_.set(user.specialty, ['name'], valueProp);
				}
			}
			return user;
		}
	});
	return newArrayEdit;
};

export const handleActionDelete = (row, users) => {
	const difArray = _.differenceWith(users, [row], _.isEqual);
	return difArray;
};

export const handleActionState = (row, users) => {
	let newIdState = null;
	let newNameState = null;
	let newState = {};
	if (row.active === UserStatus.UserStatus[0].active) {
		newIdState = UserStatus.UserStatus[1].active;
		newNameState = 'Inactivo';
	} else {
		newIdState = UserStatus.UserStatus[0].active;
		newNameState = 'Activo';
	}
	newState = newIdState;

	const newArrayState = users.map((user) => {
		if (user.id !== row.id) {
			return user;
		} else {
			user.active = newState;
			return user;
		}
	});

	return newArrayState;
};

export const handleActionStateProfessional = (row, users) => {
	let newIdState = null;
	let newNameState = null;
	let newState = {};
	if (row.status.id === ProfessionalStatus.ProfessionalStatus[0].id) {
		newIdState = ProfessionalStatus.ProfessionalStatus[1].id;
		newNameState = 'Inactivo';
	} else {
		newIdState = ProfessionalStatus.ProfessionalStatus[0].id;
		newNameState = 'Activo';
	}
	newState = { id: newIdState, name: newNameState };

	const newArrayState = users.map((user) => {
		if (user.id !== row.id) {
			return user;
		} else {
			user.status = newState;
			return user;
		}
	});

	return newArrayState;
};

export const handleActionStateReview = (row, users) => {
	let newIdState = null;
	let newNameState = null;
	let newState = null;
	if (row.statusReview) {
		newIdState = false;
		// newNameState = 'Inactivo';
	} else {
		newIdState = true;
		// newNameState = 'Activo';
	}
	newState = newIdState;

	const newArrayState = users.map((user) => {
		if (user.id !== row.id) {
			return user;
		} else {
			user.statusReview = newState;
			return user;
		}
	});

	return newArrayState;
};
