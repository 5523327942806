import React, { useState, useEffect } from 'react';
import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import Select from '@material-ui/core/Select';
import classes from './TextFieldWidgetSelectControllerHorizontal.module.scss';
import { validateRut, IsDesktopHandler } from '../../../helpers';
import errorMessage from '../../../common/errorMessages.json';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			top: '300px !important',
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 210,
		[theme.breakpoints.down('sm')]: {
			minWidth: '95%',
		},
	},
	rootLabel: {
		fontSize: 18,
	},
	selectEmpty: {
		// marginTop: theme.spacing(2),
		'& .MuiSelect-root': {
			paddingTop: 14,
			paddingBottom: 14,
		},
	},
	InputStyle: {
		paddingTop: 3,
	},
}));

const TextFieldWidgetSelectControllerHorizontal = (props) => {
	const {
		errors,
		req,
		options,
		loading,
		disabled,
		withDefault,
		defaultValue,
		name,
		control,
		multiple = false,
		variant = 'standard',
		...others
	} = props;
	const [stateMessage, setStateMessage] = useState('');
	const [stateError, setStateError] = useState(false);

	const classesUI = useStyles();

	useEffect(() => {
		if (errors) {
			switch (errors.type) {
				case 'required':
					setStateMessage(
						errorMessage.message[name].required
							? errorMessage.message[name].required
							: errorMessage.message[name]
					);
					break;
				default:
					break;
			}
			setStateError(true);
		} else {
			setStateMessage('');
			setStateError(false);
		}
	}, [errors]);

	let labelText = null;
	const isDesktop = IsDesktopHandler('sm');
	if (!isDesktop) {
		labelText = props.labeltext;
	} else {
		labelText = props.labeltext;
	}

	const renderOptionTitle = (option) => {
		switch (props.titleOption) {
			case 'rut-fullname':
				return `${option.rut} - ${option.fullname}`;
			case 'code-name':
				return `${option.code} - ${option.name}`;
			case 'label':
				return `${option.label}`;
			default:
				return `${option.name}`;
		}
	};

	return (
		<div className={classes.inputContainerStyle}>
			<div id={`${labelText}_1`} className={classes.labelDesktopStyle}>
				{props.labeltext}
			</div>
			{props.loading && <p>cargando</p>}
			<div style={{ width: '100%' }}>
				<Controller
					control={control}
					render={({ field }) => (
						<Select
							labelId={`${labelText}_1`}
							id={`${labelText}_1`}
							// label={labelText}
							// value={selectedValueState}
							// native
							{...field}
							multiple={multiple}
							fullWidth
							displayEmpty={withDefault}
							className={
								withDefault
									? classesUI.selectEmpty
									: classesUI.InputStyle
							}
							disabled={disabled}
							// margin="dense"
							variant={variant}
							MenuProps={{
								anchorOrigin: {
									vertical: 'bottom',
									horizontal: 'left',
								},
								transformOrigin: {
									vertical: 'top',
									horizontal: 'left',
								},
								getContentAnchorEl: null,
							}}

							// onChange={e => onChangeFunc(e.target.value)}>
						>
							{withDefault ? (
								<MenuItem disabled value="">
									<em>Seleccione {props.labeltext}</em>
								</MenuItem>
							) : null}
							{options &&
								options.map((option, index) => (
									<MenuItem key={index} value={option.value}>
										{renderOptionTitle(option)}
									</MenuItem>
								))}
						</Select>
					)}
					name={name}
					defaultValue={defaultValue}
					rules={{
						required: req,
					}}
				/>
				{stateError && (
					<FormHelperText className={classes.errorText} error>
						{stateMessage}
					</FormHelperText>
				)}
			</div>
		</div>
	);
};

export default TextFieldWidgetSelectControllerHorizontal;
