import React, { useState, useEffect, useMemo, useContext } from 'react';
import {
	Card,
	CardContent,
	Divider,
	IconButton,
	Paper,
	TextField,
	useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import {
	SearchBasicTable,
	ModalWidget,
	ModalWidgetDetailTable,
	DataTableWidget,
	DialogDelete,
	DialogState,
	IconActionTableDelete,
	IconActionTableState,
	IconActionTableEdit,
	IconActionTableDetail,
	BreadCrumbs,
	CardHeaderWidget,
	EditionAreaWidget,
	LabelChip,
	TextFieldWidgetSelect,
	SearchBasicSelect,
} from '../../../components';
import {
	// handleActionDetail,
	handleActionState,
	handleActionEdit,
	handleActionDelete,
	concatObjectConfigDatatable,
} from '../../../helpers';

import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
// import '../../../assets/scss/list.scss';
import viewsTexts from '../../../common/viewsTexts.json';
import { AxiosContext } from '../../../context/AxiosContext';

const useStyles = makeStyles((theme) => ({
	tableContainerStyle: {
		[theme.breakpoints.up('sm')]: {
			paddingRight: 34,
		},
	},
	selectStyle: {
		marginTop: 0,
		marginBottom: 0,
	},
	divider: {
		height: '28px',
		margin: '4px',
	},
	iconButton: {
		padding: '8px',
	},
}));

const DATA_LIST = [
	{
		id: 'dsfdsfsdf',
		user: { name: 'jorge', id: 3 },
		bdCollection: true,
		recoMuestral: false,
		date: '22-05-2023',
		status: { id: 1, name: 'activo' },
		orchard: [
			{ id: 21, label: 'Almahue' },
			{ id: 12, label: 'Bodega' },
		],
		production: false,
	},
	{
		id: 'd43543543',
		user: { name: 'Pablo', id: 2 },
		bdCollection: true,
		recoMuestral: true,
		date: '24-05-2023',
		status: { id: 1, name: 'activo' },
		orchard: [
			{ id: 34, label: 'Reconquista' },
			{ id: 3, label: 'Huerto' },
		],
		production: true,
	},
];

const DataList = () => {
	const theme = useTheme();

	const axiosContext = useContext(AxiosContext);

	const [loadingDataTableState, setLoadingDataTableState] = useState(false);
	const [tableDataState, setTableDataState] = useState([]);
	const [openDialogDeleteState, setOpenDialogDeleteState] = useState(false);
	const [openDialogStateState, setOpenDialogStateState] = useState(false);
	const [
		resetPaginationToggleState,
		setResetPaginationToggleState,
	] = useState(false);

	const [limitState, setLimitState] = useState(10);
	const [sortState, setSortState] = useState({
		key: 'user',
		option: 'asc',
	});
	const [pageState, setPageState] = useState(1);
	const [countState, setCountState] = useState(0);

	const [editionState, setEditionState] = useState(false);
	const [rowToHandleState, setRowToHandleState] = useState('');
	const [rowToEditState, setRowToEditState] = useState('');
	const [modalState, setModalState] = useState({
		openDetail: false,
		detailModal: '',
	});

	const [busyState, setBusyState] = useState(false);

	const [loadingDeleteState, setLoadingDeleteState] = useState(false);
	const [successDeleteState, setSuccessDeleteState] = useState(false);

	const [loadingStateState, setLoadingStateState] = useState(false);
	const [successStateState, setSuccessStateState] = useState(false);

	const [clientDataState, setClientDataState] = useState([]);
	const [loadingDataState, setLoadingDataState] = useState(false);
	const [filterTextUsernameState, setFilterTextUsernameState] = useState(
		null
	);
	const [filterClientIdState, setFilterClientIdState] = useState(null);
	const [formClientState, setFormClientState] = useState('');
	const [formUsernameState, setFormUsernameState] = useState('');

	const classes = useStyles();

	const customStyles = {
		headCells: {
			style: {
				color: 'white',
				backgroundColor: theme.palette.primary.light,
				'&: nth-last-child(1)': {
					backgroundColor: theme.palette.primary.main,
					borderBottom: '4px solid',
					borderColor: theme.palette.secondary.light,
				},
				div: {
					whiteSpace: 'normal',
					wordWrap: 'break-word',
				},
			},
		},
	};

	const columnsConfig = useMemo(
		() => [
			// {
			// 	name: 'Rut',
			// 	selector: (row) => row.rut,
			// 	sortField: 'rut',
			// 	sortable: true,
			// 	id: 1,
			// },
			{
				name: 'Usuario',
				selector: (row) =>
					row.user.name ? row.user.name : row.user.username,
				sortField: 'user',
				sortable: true,
				id: 1,
			},
			{
				name: 'Fecha',
				selector: (row) => row.date,
				sortField: 'date',
				// hide: 'md',
				sortable: false,
				id: 2,
			},
			{
				name: 'Bd Collection',
				selector: (row) => (row.bdCollection ? 'SI' : 'NO'),
				sortField: 'bdCollection',
				// hide: 'sm',
				sortable: false,
				id: 3,
			},
			{
				name: 'Reco Muestral',
				selector: (row) => (row.recoMuestral ? 'SI' : 'NO'),
				sortField: 'recoMuestral',
				// hide: 'sm',
				sortable: false,
				id: 6,
			},
			{
				name: 'Estado',
				selector: (row) => row.status,
				cell: (row) => <LabelChip label={row.status.name} />,
				sortable: false,
				id: 5,
			},
		],
		[]
	);

	const actionsConfig = [
		{
			name: 'Acciones',
			width: '200px',
			id: 7,
			cell: (row) => (
				<div className="actionColumn">
					<IconActionTableDetail
						handleActionDetail={() => {
							const html = row;
							setModalState({
								...modalState,
								openDetail: true,
								detailModal: html,
							});

							console.info('estado modal: ', modalState);
						}}
					/>
					<IconActionTableState
						rowState={row.production}
						tooltip={
							row.production
								? 'En Producción'
								: 'Pasar a Producción'
						}
						handleActionState={
							!row.production
								? () => DialogStateHandler(row)
								: () => {}
						}
					/>
					{/* <IconActionTableDelete
						handleActionDelete={() => DialogDeleteHandler(row)}
					/> */}
				</div>
			),
		},
	];
	const actions = concatObjectConfigDatatable(actionsConfig[0]);
	const columns = columnsConfig.concat(actions);

	async function queryData(page, sort = sortState) {
		setLoadingDataTableState(true);
		try {
			let objQuery = {
				page: page,
				limit: limitState,
				sort: sort ? sort : sortState,
			};

			console.log(objQuery, '----------------------la query que va');

			const response = await axiosContext.authAxios.post(
				'v1/user/list-users',
				objQuery
			);

			const { data, status } = response;

			console.log(response, '<-----response query');
			setTableDataState(DATA_LIST);
			setCountState(DATA_LIST.length);

			// setCountState(data.totalPassengers);
			if (status === 200) {
				// setTableDataState(data.data);
				// setCountState(data.count);
				setTableDataState(DATA_LIST);
				setCountState(DATA_LIST.length);
				setPageState(page);
				setSortState(sort);
				setBusyState(false);
			}
			setLoadingDataTableState(false);
		} catch (err) {
			// setTableDataState([]);
			setTableDataState(DATA_LIST);
			setCountState(DATA_LIST.length);
			setBusyState(false);
			setLoadingDataTableState(false);
			console.log('-----------', err);
		}
	}

	const DialogDeleteHandler = (row) => {
		setRowToHandleState(row);
		setEditionState(false);
		setOpenDialogDeleteState(!openDialogDeleteState);
	};

	const deleteActionDialogHandler = async () => {
		setLoadingDeleteState(true);
		try {
			let objDel = {
				id: rowToHandleState.id,
			};

			console.log(objDel, '----------------------la query que va');

			const responseDel = await axiosContext.authAxios.post(
				'v1/user/delete-user',
				objDel
			);

			console.log(responseDel, '<-----response query');

			if (responseDel.status === 200) {
				setSuccessDeleteState(true);
				setLoadingDeleteState(false);
				setSuccessDeleteState(false);
			}
		} catch (err) {
			console.log('-----------', err);
			setLoadingDeleteState(false);
		}
		setCountState(countState - 1);
		const newUsers = handleActionDelete(rowToHandleState, tableDataState);
		setTableDataState(newUsers);
	};

	// state
	const DialogStateHandler = (row) => {
		setEditionState(false);
		setRowToHandleState(row);
		setOpenDialogStateState(!openDialogStateState);
	};

	const stateActionDialogHandler = async () => {
		setLoadingStateState(true);
		try {
			let objSta = {
				id: rowToHandleState.id,
			};

			console.log(objSta, '----------------------la query que va');

			const responseSta = await axiosContext.authAxios.post(
				'v1/user/update-active-user',
				objSta
			);

			console.log(responseSta, '<-----response query');

			if (responseSta.status === 200) {
				setSuccessStateState(true);
				setLoadingStateState(false);
				setSuccessStateState(false);
			}
		} catch (err) {
			console.log('-----------', err);
			setLoadingStateState(false);
		}
		const newUsers = handleActionState(rowToHandleState, tableDataState);
		setTableDataState(newUsers);
	};

	const closeEditionAreaHandler = () => {
		setEditionState(false);
	};

	//const searchTool = false;

	// table functions
	const handleOnSort = async (column, sortDirection) => {
		// console.log('handleSort', column, sortDirection);
		let nameColumn = column.sortField;
		// console.log(column.selector.toString().split(".")[1], "---");
		// let nameColumn = column.selector.toString().split('.')[1];

		if (nameColumn !== null) {
			console.log({ key: nameColumn, option: sortDirection });
			if (!busyState) {
				queryData(pageState, {
					key: nameColumn,
					option: sortDirection,
				});
			}
		}
	};

	const handleChangePagination = (page, totalRows) => {
		console.log(page, totalRows);
		// setPageState(page);
		if (!busyState) {
			queryData(page);
		}
	};

	const handleChangeRows = async (currentRowsPerPage, currentPage) => {
		setLoadingDataTableState(false);
		try {
			let objQuery = {
				page: currentPage,
				limit: currentRowsPerPage,
				sort: sortState,
			};

			console.log(objQuery, '---la query que va en handle');

			const response = await axiosContext.authAxios.post(
				'v1/user/list-users',
				objQuery
			);
			const { data, status } = response;

			// console.log(response, "<-----response change rows")

			// setCountState(data.totalPassengers);
			if (status === 200) {
				// setTableDataState(data.data);
				console.log('eeeeeeee en handle', data);
				setTableDataState(data.data);
				// setCountState(data.count);
				setLimitState(currentRowsPerPage);
			}
			setLoadingDataTableState(false);
		} catch (err) {
			setTableDataState([]);
			setLoadingDataTableState(false);
		}
	};

	const handleCloseModal = () => {
		setModalState({
			...modalState,
			openDetail: false,
		});
	};

	useEffect(() => {
		queryData(pageState);
	}, []);

	// console.log(usersDataState,"la data");

	return (
		<div className="form-style">
			<BsContainer fluid class="px-0 px-md-3">
				<BsRow class="no-gutters">
					<BsCol class="col-md-12">
						<Card elevation={0}>
							<CardHeaderWidget
								title={viewsTexts.titles.data.list.title}
								subheader={<BreadCrumbs subTitle="" />}
							/>
							<CardContent>
								{/* <GenericToolbar search={searchTool} /> */}
								<div style={{ minHeight: '300px' }}>
									<DataTableWidget
										totalRows={countState}
										handleOnSort={handleOnSort}
										fixedHeader={true}
										fixedHeaderScrollHeight={'590px'}
										// conditionalRowStyles={conditionalRowStyles}
										// sortFunction={customSort}
										changePagination={
											handleChangePagination
										} //onChangePage
										rowsChange={handleChangeRows} //onChangeRowsPerPage
										columns={columns}
										sortField="1"
										filteredItems={tableDataState}
										// subHeaderComponentMemo={
										// 	subHeaderComponentMemo
										// }
										subHeader={false}
										loading={loadingDataTableState}
										loadingCount={loadingDataTableState}
										customStyles={customStyles}
										moduleName="Carga"
										resetPaginationToggle={
											resetPaginationToggleState
										}
									/>
								</div>
								{/* <ModalWidget
									size="modal-50"
									openDetail={modalState.openDetail}
									detailModal={modalState.detailModal}
									moduleName="Usuarios"
								/> */}
								<ModalWidgetDetailTable
									size="modal-50"
									closeFunc={handleCloseModal}
									openDetail={modalState.openDetail}
									detailModal={modalState.detailModal}
									moduleName="Carga"
								/>
								<DialogState
									openDialog={openDialogStateState}
									rowState={rowToHandleState}
									actionState={stateActionDialogHandler}
									variant="success"
									loading={loadingStateState}
									success={
										successStateState
											? successStateState
											: undefined
									}
									openState={setOpenDialogStateState}
								/>
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};

export default DataList;
