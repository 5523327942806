import React, { useState, useEffect } from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import { FormLabel,FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import errorMessage from '../../../common/errorMessages.json';
import classes from './RadioGroupWidget.module.scss';
import { Controller } from 'react-hook-form';

const RadioGroupWidget = props => {
  const { errors, name, checked } = props;
  const [stateMessage, setStateMessage] = useState('');
  const [stateError, setStateError] = useState(false);

  useEffect(() => {
    if (errors) {
      switch (errors.type) {
        case 'required':
          setStateMessage(errorMessage.message.minimum);
          break;
        default:
          break;
      }
      setStateError(true);
    } else {
      setStateMessage('');
      setStateError(false);
    }
  }, [errors]);

  return (
    <span>
        <FormLabel component="legend">{props.labeltext}</FormLabel>
        <Controller
          name={name}
          control={props.control}
          defaultValue={checked ? checked : ''}
          rules={{
            required: props.req
          }}
          render={({ field }) => (
            <RadioGroup
              {...field}
              classes={{
                root: classes.horizontalStyle
              }}>
              {props.children}
            </RadioGroup>
          )}
        />
      {stateError && (
        <FormHelperText className={classes.errorText} error>
          {stateMessage}
        </FormHelperText>
      )}
    </span>
  );
};

RadioGroupWidget.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.object
};

export default RadioGroupWidget;
