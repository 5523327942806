import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography, Link } from '@material-ui/core';
import classes from './Footer.module.scss';

const Footer = (props) => {
	const { className, ...rest } = props;

	return (
		<div {...rest} className={clsx(classes.root, className)}>
			<Typography variant="caption" color="textPrimary">
				Panel Admin Analytics
			</Typography>
			<Typography variant="body2" color="textPrimary">
				&copy;{' '}
				<Link
					component="a"
					color="textPrimary"
					href="http://ceranalytics.com/"
					target="_blank"
				>
					ceranalytics.com
				</Link>
				. 2022
			</Typography>
		</div>
	);
};

Footer.propTypes = {
	className: PropTypes.string,
};

export default Footer;
