import gql from "graphql-tag";

export const listParameter = gql`
  query listParameter {
    listParameter {
      id
      name
      nameInput
      value
      type
    }
  }
`;

export const getParameter = gql`
  query getParameter($id: ID!){
    getParameter(id: $id) {
      id
      name
      nameInput
      value
      type
    }
  }
`;
