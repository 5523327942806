import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, IconButton, Link, Typography } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useForm } from 'react-hook-form';
import { signUp } from '../../graphql/mutation/auth/auth';
import { useMutation } from '@apollo/react-hooks';
import {
	TextFieldWidgetTextBig,
	CheckboxWidgetUncontrolled,
} from '../../components';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import SnackBarsComponent from '../../components/SnackBarsComponent';
import snackBarsMessages from '../../common/snackBarsMessages.json';
import classes from './SignUp.module.scss';
import viewsTexts from '../../common/viewsTexts.json';
import generalTexts from '../../common/generalTexts.json';

const SignUp = (props) => {
	const { history } = props;
	const [loadingState, setLoadingState] = useState(false);
	const [successState, setSuccessState] = useState(false);
	const [passToCompare, setPassToCompare] = useState('');
	const { handleSubmit, control, errors } = useForm();
	const snackBarMessage = snackBarsMessages.message['signUp'];

	const [signUpHandler, { loading, error, data }] = useMutation(signUp);

	useEffect(() => {
		if (loading) setLoadingState(true);
		if (error) {
			setLoadingState(false);
		}
		if (data) {
			setSuccessState(true);
		}
	}, [loading, error, data]);

	const handleBack = () => {
		history.goBack();
	};

	const onSubmit = async (values) => {
		setLoadingState(true);
		try {
			await signUpHandler({
				variables: { email: values.email, password: values.password },
			});
		} catch (err) {}
	};

	const handleCloseSnackBarsComponent = () => {
		setSuccessState(false);
		history.push('/');
	};

	const handleChangePass = (event) => {
		setPassToCompare(event.target.value);
	};

	return (
		<div className={classes.root}>
			<BsContainer fluid class={classes.grid}>
				<BsRow class={classes.grid}>
					<BsCol
						class={[
							'col-lg-5 px-md-0',
							classes.quoteContainer,
						].join(' ')}
					>
						<div className={classes.quote}>
							<div className={classes.quoteInner}>
								<Typography
									className={classes.quoteText}
									variant="h1"
								>
									Plataforma de Pago
								</Typography>
								<div className={classes.person}>
									<Typography
										className={classes.name}
										variant="body1"
									>
										{generalTexts.enterprice}
									</Typography>
									{/* <Typography className={classes.bio} variant="body2">
                    Manager at inVision
                  </Typography> */}
								</div>
							</div>
						</div>
					</BsCol>
					<BsCol class={['col-lg-7', classes.content].join(' ')}>
						<div className={classes.content}>
							<div className={classes.contentHeader}>
								<IconButton onClick={handleBack}>
									<ArrowBackIcon />
								</IconButton>
							</div>
							<div className={classes.contentBody}>
								<form
									className={classes.form}
									autoComplete="on"
									onSubmit={handleSubmit(onSubmit)}
								>
									<Typography
										className={classes.title}
										variant="h2"
									>
										{viewsTexts.titles.signup.title}
									</Typography>
									<Typography
										color="textSecondary"
										gutterBottom
									>
										{viewsTexts.titles.signup.sugestion}
									</Typography>
									<TextFieldWidgetTextBig
										name={viewsTexts.forms.email}
										className="mt-3"
										label="E-mail"
										req
										control={control}
										errors={errors.email}
										autoComplete="email"
									/>
									<TextFieldWidgetTextBig
										name={viewsTexts.forms.password}
										req
										minLength={6}
										maxLength={10}
										type="password"
										label="Contraseña"
										onChange={handleChangePass}
										className="mt-3"
										control={control}
										errors={errors.password}
									/>
									<TextFieldWidgetTextBig
										name={viewsTexts.forms.password2}
										req
										minLength={6}
										maxLength={10}
										type="password"
										equalto={passToCompare}
										label="Confirmar Contraseña"
										className="mt-3"
										control={control}
										errors={errors.password2}
									/>

									<div className={classes.policy}>
										<CheckboxWidgetUncontrolled
											name="politica"
											color="primary"
											onChangeHandler={() => {}}
											req
											control={control}
											errors={errors.politica}
										/>
										<Typography
											className={classes.policyText}
											color="textSecondary"
											variant="body1"
										>
											He leído los{' '}
											<Link
												color="primary"
												component={RouterLink}
												to="#"
												underline="always"
												variant="h6"
											>
												{
													viewsTexts.forms
														.termsAndConditions
												}
											</Link>
										</Typography>
									</div>
									<div className={classes.wrapperButton}>
										<Button
											className={classes.signUpButton}
											color="primary"
											disabled={loadingState}
											fullWidth
											size="large"
											type="submit"
											variant="contained"
										>
											Registrarte
										</Button>
										{loadingState && (
											<CircularProgress
												size={24}
												className={classes.progress}
											/>
										)}
									</div>
									<Typography
										color="textSecondary"
										variant="body1"
										style={{ marginTop: '10px' }}
									>
										{generalTexts.textLinkSignin}{' '}
										<Link
											component={RouterLink}
											to="/sign-in"
											variant="h6"
										>
											{generalTexts.linkSignin}
										</Link>
									</Typography>
								</form>
							</div>
						</div>
					</BsCol>
				</BsRow>
			</BsContainer>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={successState}
				autoHideDuration={6000}
			>
				<SnackBarsComponent
					variant="success"
					message={snackBarMessage}
					handleClose={handleCloseSnackBarsComponent}
				/>
			</Snackbar>
		</div>
	);
};

SignUp.propTypes = {
	history: PropTypes.object,
};

export default withRouter(SignUp);
