import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import MoodIcon from '@material-ui/icons/Mood';
import classes from './StatusBox.module.scss';

const StatusBox = props => {
  const {
    idState,
    days = 0
  } = props;

  let icon = null;
  let colorName = null;
  let titleState = null;

  switch (idState) {
    case 1:
      icon = <MoodIcon className={classes.icon}/>;
      titleState = 'Completado';
      colorName = 'green';
      break;
    case 2:
      icon = <RadioButtonUncheckedIcon className={classes.icon} />;
      titleState = 'Pendiente';
      colorName = 'orange';
      break;
    case 3:
      icon = <MoodBadIcon className={classes.icon} />;
      titleState = 'Atrasado';
      colorName = 'red';
      break;
    default:
      break;
  }

  return (
    <Card className={clsx(classes.root, classes[colorName])}>
      <CardContent>
        <div className={classes.container}>
          <div className={classes.item}>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="h6">
              Estado {titleState}
            </Typography>
            <Typography variant="h2">{days} días</Typography>
          </div>
          <div className={classes.item}>
						{icon}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

StatusBox.propTypes = {
  idState: PropTypes.any.isRequired,
  days: PropTypes.any
};

export default StatusBox;
