import React from 'react';
import Button from '@material-ui/core/Button';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import classes from './PostulationHeader.module.scss';

const PostulationHeader = props => {
  const { userId } = props;

  const history = useHistory();

  const onClickFunc = () => {
    let path = `/curriculum-report?idUser=${userId}`;
    history.push(path);
  };

  return (
    <div className={classes.headerContainer}>
      <div>
        <Button
          variant="contained"
          color={props.disableExport ? 'primary' : 'default'}
          size="medium"
          disabled={!props.disableExport}
          className={classes.button}
          startIcon={<PictureAsPdfOutlinedIcon />}
          onClick={onClickFunc}>
          Exportar Postulación
        </Button>
        {!props.disableExport && (
          <Typography variant="caption" display="block" className="mt-2">
            Se habilita cuando completes tu información.
          </Typography>
        )}
      </div>
    </div>
  );
};

export default PostulationHeader;
