import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
	SaveAction,
	CardHeaderWidget,
	TextFieldWidgetText,
	TextFieldWidgetSelectControllerHorizontal,
} from '../../../components';
import { Card, CardContent, Divider, TextField } from '@material-ui/core';
import { BsRow, BsCol } from '../../../layouts/components';
import { CloseButtonEditionArea } from '../../../components/EditionAreaWidget';
import {
	formatRut,
	formatOnChangeRut,
	alterArrayForMulti,
	alterArrayGetFields,
	alterArrayForQuery,
	alphabeticalSort,
} from '../../../helpers';
import '../../../assets/scss/form.scss';
import viewsTexts from '../../../common/viewsTexts.json';
import { AxiosContext } from '../../../context/AxiosContext';

function allValuesExistInMaster(masterArray, checkArray) {
	// First, let's create a set from the master array values for O(1) lookup time
	// Because who wants to wait around? Not us, that's for sure!
	const masterValues = new Set(masterArray.map((item) => item.value));

	// Now, we'll check each value from the checkArray
	// If we find even one party pooper that's not in our master list, it's game over
	return checkArray.every((item) => {
		if (!masterValues.has(item.value)) {
			console.log(
				`Ah, snap! ${item.value} is like a ghost - it doesn't exist in the master list.`
			);
			return false;
		}
		return true; // All good, this value is partying with the cool kids
	});
}

const UserEdit = (props) => {
	const require = true;

	const {
		id,
		email,
		name,
		lastname,
		phone,
		username,
		client,
		agrobusiness,
		profile,
	} = props.rowEdit;
	const { editFunc, closeFunc } = props;
	const [profileDataState, setProfileDataState] = useState([]);
	const [clientDataState, setClientDataState] = useState([]);
	const [agroBusinessDataState, setAgroBusinessDataState] = useState([]);
	const [loadingDataState, setLoadingDataState] = useState(false);

	const axiosContext = useContext(AxiosContext);

	const [loadingQueryState, setLoadingQueryState] = useState(false);
	const [successQueryState, setSuccessQueryState] = useState(false);

	const [hideClientDataState, setHideClientDataState] = useState(false);

	const [disableAgroBusinessState, setDisableAgroBusinessState] = useState(
		false
	);

	const {
		handleSubmit,
		control,
		formState: { errors },
		register,
		getValues,
		reset,
		watch,
		setValue,
	} = useForm();

	console.log(props.rowEdit, ' llega row a edit');
	const watchClient = watch('client');
	const watchProfile = watch('profile');

	useEffect(() => {
		console.log(profile, '----profile');
		if (profile && profile.value === 'Administrador_Panel') {
			setHideClientDataState(true);
		} else {
			setHideClientDataState(false);
		}

		reset({
			id: id,
			profile: profile?.value,
			// rut: formatRut(rut),
			name: name,
			lastName: lastname,
			userName: username,
			// maternalLastname: maternalLastname,
			email: email,
			phone: phone || '',
			client:
				profile.value !== 'Administrador_Panel' ? client?.value : null,
			agroBusiness:
				agrobusiness && agrobusiness.length
					? agrobusiness.map((agro) => agro.value)
					: [],
			// projectAssigned: alterArrayForMulti(project)
		});

		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [
		id,
		email,
		// rut,
		name,
		lastname,
		username,
		agrobusiness,
		// maternalLastname,
		profile,
		phone,
		client,
		reset,
	]);

	useEffect(() => {
		console.log(watchClient, '-----watchClient');
		if (watchClient && clientDataState.length) {
			const resultClient = clientDataState.filter(
				(ele) => ele.value === watchClient
			);
			console.log('filtrado', resultClient);

			// TODO: evaluar si hay agricolas del user que no están en las filtradas

			const agroBusinessClient = resultClient[0]?.agrobusiness;

			const agroBusinessUser = agrobusiness;

			if (!allValuesExistInMaster(agroBusinessClient, agroBusinessUser)) {
				setDisableAgroBusinessState(true);
			}

			setAgroBusinessDataState(
				alphabeticalSort(resultClient[0]?.agrobusiness, 'label')
			);
		}
	}, [watchClient, clientDataState]);

	const onSubmit = async (values) => {
		// console.log(values, '<<---values')

		setLoadingQueryState(true);

		const profileSelected = profileDataState.filter(
			(ele) => ele.value === values.profile
		);

		// saca los objetos
		let clientObj = values.client
			? clientDataState.filter((ele) => ele.value === values.client)
			: null;

		clientObj = clientObj
			? alterArrayGetFields(clientObj, ['id', 'label', 'value'])
			: null;

		// const agroBusinessObj = agroBusinessDataState.filter(
		// 	(ele) => ele.value === values.agroBusiness
		// );

		let agrobusinessToSend = [];

		if (disableAgroBusinessState) {
			agrobusinessToSend = agrobusiness;
		} else {
			const agroBusinessArray = values.agroBusiness
				? values.agroBusiness.map((agro) => {
						const dataAgro = agroBusinessDataState.filter(
							(ele) => ele.value === agro
						);
						return dataAgro[0];
				  })
				: '';
			agrobusinessToSend = agroBusinessArray.filter((item) => item);
		}

		const query = {
			id: values.id,
			email: values.email,
			// rut: formatRut(values.rut),
			name: values.name,
			lastname: values.lastName,
			username: values.userName,
			// maternalLastname: values.maternalLastname,
			phone: values.phone,
			password: values.password || null,
			profile: profileSelected[0],
			agrobusiness: agrobusinessToSend,
			client: clientObj ? clientObj[0] : null,
		};

		console.log(query, '<<---query');

		try {
			const response = await axiosContext.authAxios.post(
				'v1/user/update-user',
				query
			);

			// console.log(response, '<---resp');

			if (response.status === 200) {
				// alert('listo');
				editFunc(response.data);
				setSuccessQueryState(true);
			}

			setLoadingQueryState(false);
		} catch (err) {
			setLoadingQueryState(false);
		}
	};

	const getProfilesAsync = async () => {
		setLoadingDataState(true);
		try {
			let response = await axiosContext.authAxios.post(
				'v1/profile/list-profiles'
			);

			// console.log(response, '<<----respo profiles');

			const { data, status } = response;

			if (status === 200) {
				setProfileDataState(alphabeticalSort(data.data, 'label'));
				setLoadingDataState(false);
			}
		} catch (error) {
			console.log(error);
			// setLoadingVarietyFilterState(false);
			setLoadingDataState(false);
		}
	};

	const getClientsAsync = async () => {
		setLoadingDataState(true);
		try {
			let response = await axiosContext.authAxios.post(
				'v1/client/list-clients',
				{
					limit: 500,
				}
			);

			// console.log(response, '<<----respo profiles');

			const { data, status } = response;

			if (status === 200) {
				setClientDataState(alphabeticalSort(data.data, 'label'));
				setLoadingDataState(false);
			}
		} catch (error) {
			console.log(error);
			setLoadingDataState(false);
			// setLoadingVarietyFilterState(false);
		}
	};

	// endpoints
	useEffect(() => {
		getProfilesAsync();
		getClientsAsync();
	}, []);

	// TOMA EL VALOR DE PASSWORD
	const { password } = getValues();

	useEffect(() => {
		// console.log(watchProfile, '----watchProfile')
		if (watchProfile && watchProfile === 'Administrador_Panel') {
			setHideClientDataState(true);
		} else {
			setHideClientDataState(false);
		}
	}, [watchProfile]);

	return (
		<Card elevation={0}>
			<CardHeaderWidget
				title={
					viewsTexts.titles.user.userTextEdit +
					' ' +
					name +
					' ' +
					lastname
				}
			/>
			<CloseButtonEditionArea closeFunc={props.closeFunc} />
			<Divider />
			<CardContent>
				<BsRow>
					<BsCol class="col-md-12">
						<form
							autoComplete="on"
							onSubmit={handleSubmit(onSubmit)}
						>
							<BsRow>
								<BsCol class="col-md-12">
									<TextFieldWidgetSelectControllerHorizontal
										options={profileDataState}
										titleOption="label"
										control={control}
										name="profile"
										labeltext="Perfil"
										errors={errors.profile}
										defaultValue=""
										loading={loadingDataState}
										req
										withDefault
										variant="outlined"
									/>
								</BsCol>
							</BsRow>
							{!hideClientDataState ? (
								<BsRow>
									<BsCol class="col-md-6">
										<TextFieldWidgetSelectControllerHorizontal
											options={clientDataState}
											titleOption="label"
											control={control}
											name="client"
											labeltext="Cliente"
											errors={errors.client}
											defaultValue=""
											loading={loadingDataState}
											req
											withDefault
											variant="outlined"
										/>
									</BsCol>
									<BsCol class="col-md-6">
										<TextFieldWidgetSelectControllerHorizontal
											options={agroBusinessDataState}
											titleOption="label"
											control={control}
											name="agroBusiness"
											labeltext="Agrícola"
											errors={errors.agroBusiness}
											defaultValue={[]}
											multiple={true}
											disabled={disableAgroBusinessState}
											loading={false}
											req={false}
											withDefault
											variant="outlined"
										/>
									</BsCol>
								</BsRow>
							) : null}
							<BsRow>
								<BsCol class="col-md-12">
									{/* <TextFieldWidgetText
										InputLabelProps={{ shrink: true }}
										name="rut"
										autoComplete="rut"
										onChange={formatOnChangeRut}
										labeltext={viewsTexts.forms.rut}
										req={require}
										control={control}
										placeholder="11.111.111-1"
										errors={errors.rut}
										defaultValue=""
									/> */}
									<TextFieldWidgetText
										InputLabelProps={{
											shrink: true,
										}}
										name="id"
										labeltext=""
										control={control}
										style={{
											display: 'none',
										}}
										defaultValue={id}
									/>
									<TextFieldWidgetText
										name="userName"
										labeltext={viewsTexts.forms.userName}
										InputLabelProps={{
											shrink: true,
										}}
										req={true}
										defaultValue=""
										minLength={3}
										maxLength={12}
										control={control}
										errors={errors.userName}
									/>
								</BsCol>
								{/* <BsCol class="col-md-6"></BsCol> */}
							</BsRow>
							<BsRow>
								<BsCol class="col-md-6">
									<TextFieldWidgetText
										InputLabelProps={{
											shrink: true,
										}}
										name="name"
										labeltext={viewsTexts.forms.name}
										req={false}
										minLength={3}
										maxLength={100}
										control={control}
										errors={errors.name}
										autoComplete="given-name"
										defaultValue=""
									/>
								</BsCol>
								<BsCol class="col-md-6">
									<TextFieldWidgetText
										name="lastName"
										labeltext={viewsTexts.forms.lastName}
										InputLabelProps={{
											shrink: true,
										}}
										req={false}
										minLength={3}
										maxLength={100}
										control={control}
										autoComplete="family-name"
										errors={errors.lastName}
										defaultValue=""
									/>
								</BsCol>
							</BsRow>
							<BsRow>
								<BsCol class="col-md-6">
									<TextFieldWidgetText
										InputLabelProps={{
											shrink: true,
										}}
										name="email"
										labeltext={viewsTexts.forms.email}
										req={false}
										control={control}
										errors={errors.email}
										autoComplete="email"
										defaultValue=""
									/>
								</BsCol>
								<BsCol class="col-md-6">
									<TextFieldWidgetText
										InputLabelProps={{
											shrink: true,
										}}
										name="phone"
										type="number"
										labeltext={viewsTexts.forms.phone}
										req={false}
										minLength={8}
										maxLength={11}
										control={control}
										placeholder="56999999999"
										autoComplete="phone"
										errors={errors.phone}
										defaultValue=""
									/>
								</BsCol>
							</BsRow>
							<BsRow>
								<BsCol class="col-md-6">
									<TextFieldWidgetText
										name="password"
										type="password"
										autoComplete="new-password"
										minLength={6}
										maxLength={10}
										labeltext={viewsTexts.forms.password}
										control={control}
										errors={errors.password}
										defaultValue=""
									/>
								</BsCol>
								<BsCol class="col-md-6">
									<TextFieldWidgetText
										name="password2"
										type="password"
										equalto={password}
										autoComplete="new-password"
										minLength={6}
										maxLength={10}
										labeltext={viewsTexts.forms.password2}
										control={control}
										errors={errors.password2}
										defaultValue=""
									/>
								</BsCol>
							</BsRow>
							<BsRow class="mt-5">
								<BsCol class="col-md-12 text-center">
									<SaveAction
										title="Guardar"
										color="secondary"
										size="large"
										className="roundedButton ml-md-2"
										variant="contained"
										disabled={loadingQueryState}
										messageType="edit"
										actionClose={closeFunc}
										mutationLoading={loadingQueryState}
										success={successQueryState}
									/>
								</BsCol>
							</BsRow>
						</form>
					</BsCol>
				</BsRow>
			</CardContent>
		</Card>
	);
};

export default UserEdit;
