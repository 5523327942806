import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
	// DataTableWidget,
	SaveAction,
	ButtonAction,
	Alert,
	// SelectMultipleWidget,
	// LoadingWidget,
	// TextFieldWidgetSelectController,
	TextFieldWidgetSelectControllerHorizontal,
	TextFieldWidgetText,
	CardHeaderWidget,
	BreadCrumbs,
	SnackBarsComponent,
	TextFieldWidgetUpload,
} from '../../components';

import { Client } from './OptionsIU/OptionsIU';

import {
	// Accordion,
	// AccordionDetails,
	// AccordionSummary,
	// Divider,
	Card,
	CardActions,
	Button,
	CardContent,
	// Hidden,
	// Typography,
	useTheme,
	Snackbar,
} from '@material-ui/core';
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BsRow, BsCol, BsContainer } from '../../layouts/components';
import { Context } from '../../context/Context';
import { AxiosContext } from '../../context/AxiosContext';
// import viewsTexts from '../../common/viewsTexts.json';
import { makeStyles } from '@material-ui/styles';
// import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { SimpleTabs, TabPanel } from 'components/TabPanel';
import viewsTexts from '../../common/viewsTexts.json';
import {
	alphabeticalSort,
	alterArrayAddLabels,
	// numberFormat,
	// isNumber,
	// findActualYear,
	// alterArrayForSelectVariety,
	// getOrchardVarieties,
	// getOrchardVarietiesDeep,
	alterArrayGetFields,
	formatRut,
} from '../../helpers';
// import theme from 'theme';
import { FiltersContext } from '../../context';
import { result } from 'lodash';
import { register } from 'serviceWorker';
import AlertMessages from 'components/Alert/Alert';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.tertiary.main,
		fontWeight: 500,
	},
	divider: {
		background: theme.palette.grey[400],
		marginTop: 10,
	},
	rootHelper: {
		fontSize: 17,
	},
	inputText: {
		fontSize: 26,
	},
}));

// const initialUploadGetEndpoint = '/api-task/productive-unit/get-file-edit'; // habilita para edición masiva
const initialUploadEndpoint = 'api-task/productive-unit/save-new-orchard';

const InitialUpload = (props) => {
	const { userId, userHuerto, userName, userOrchard } = useContext(Context);

	// console.log(userId, userHuerto, userName, "<<<<---context")
	const { state: filterState, addFilter } = useContext(FiltersContext);

	const {
		// orchard_filter,
		// cc_filter,
		// quarter_filter,
		// variety_filter,
		// season_filter,
		userOrchard_filter,
	} = filterState;

	// console.log('----', filterState);

	const axiosContext = useContext(AxiosContext);
	// axiosContext.authAxios //queries with token

	const [filterDataResponseState, setFilterDataResponseState] = useState(
		userOrchard_filter
	);

	const [loadingQueryState, setLoadingQueryState] = useState(false);
	const [successQueryState, setSuccessQueryState] = useState(false);

	const [orchardsDataComboState, setOrchardsDataComboState] = useState([]);
	const [clientDataState, setClientDataState] = useState([]);
	const [alertOpen, setAlertOpen] = useState(false);

	const [errorState, setErrorState] = useState(false);

	const [loadingConfirmActionState, setLoadingConfirmActionState] = useState(
		false
	);

	const [showAlert, setShowAlert] = useState(false);
	const [filtersDataState, setFiltersDataState] = useState([]);
	const [loadingFiltersState, setLoadingFiltersState] = useState(false);

	const [orchardsDataState, setOrchardsDataState] = useState([]);

	const [excelUrl, setExcelUrl] = useState('');

	const [successMessageState, setSuccessMessageState] = useState(null);

	const theme = useTheme();

	// console.log(theme)

	const classes = useStyles();

	const {
		handleSubmit,
		control,
		watch,
		reset,
		setValue,
		getValues,
		// setError,
		// clearErrors,
		formState: { errors },
	} = useForm();

	const watchClient = watch('client');
	const watchOrchard = watch('orchard');

	const onSubmit = async (values) => {
		setLoadingQueryState(true);
		setExcelUrl('');
		setSuccessMessageState(null);

		// const orchard = getValues("orchard");

		// Encuentra el objeto de orchard correspondiente en orchardsDataComboState
		const orchardObj = orchardsDataState.find(
			(orchardObj) => orchardObj.orchardValue === values.orchard
		);
		console.log(values, '<<<--------');

		// let clientObj = clientDataState.filter(
		// 	(ele) => ele.value === values.client
		// );

		// clientObj = alterArrayGetFields(clientObj, ['id', 'label', 'value']);

		// let orchardsObj = transformedOrchardsData.filter(
		// 	(ele) => ele.value === values.orchard
		// );

		// orchardsObj = alterArrayGetFields(orchardsObj, ['id', 'label', 'value']);

		// console.log(orchardsObj, 'orchardsObj');

		// const query = {
		// 	client: clientObj[0].label,
		// 	clientValue: clientObj[0].value,
		// 	orchard: orchardsObj[0].label,
		// 	orchardValue: orchardsObj[0].value,
		// 	username: userName,
		// };

		console.log(orchardObj, 'orchardObj');

		setExcelUrl(orchardObj.url);

		// try {
		// 	const response = await axiosContext.authAxios.post(
		// 		initialUploadGetEndpoint,
		// 		query
		// 	);
		// 	const {data, status } = response;
		// 	console.log(response, '------response');

		// 	if (response.status === 200) {

		// 		setSuccessQueryState(true);
		// 		// TODO: pasar excel url
		// 		setExcelUrl(data.menssage.sucess.url);

		// 	} else {
		// 	}
		// } catch (err) {

		// 	//console.log('Error', err.request.status);
		// 	if (err.request.status === 500) {
		// 		setErrorState(true);
		// 	}
		// } finally {
		// 	setLoadingQueryState(false);
		// }
		setSuccessQueryState(true);
		setLoadingQueryState(false);
	};

	const { password } = getValues();

	// Lógica para cargar los datos de los huertos basados en el cliente seleccionado
	useEffect(() => {
		if (watchClient) {
			console.log('watchClient', watchClient);
			console.log('clientDataState', clientDataState);

			// Encuentra el cliente seleccionado en clientDataState
			const selectedClient = clientDataState.find(
				(eve) => eve.clientValue === watchClient
			);

			console.log('selectedClient', selectedClient);

			if (selectedClient) {
				// Obtiene los huertos del cliente seleccionado
				const orchards = selectedClient.orchardData.map((orchard) => ({
					orchard: orchard.orchard,
					orchardValue: orchard.orchardValue,
					url: orchard.url,
				}));

				console.log('orchards', orchards);

				// Actualiza el estado con los huertos correspondientes
				setOrchardsDataState(orchards);
			}
		}
	}, [watchClient, clientDataState]);

	useEffect(() => {
		getDataFiltersAsync();
	}, []);

	const getDataFiltersAsync = async () => {
		setLoadingFiltersState(true);
		try {
			let response = await axiosContext.authAxios.get(
				'v1/unitProductive/filter-create-initial-excel'
			);

			const { data, status } = response;

			if (status === 200) {
				setFiltersDataState(data);
			}
		} catch (error) {
			console.log(error);
			// setLoadingVarietyFilterState(false);
		} finally {
			setLoadingFiltersState(false);
		}
	};

	useEffect(() => {
		if (filtersDataState) {
			setClientDataState(
				alphabeticalSort(
					alterArrayAddLabels(filtersDataState.clientData || [], [
						'clientValue',
						'client',
					]),
					'label'
				)
			);
		}
	}, [filtersDataState]);

	const transformedOrchardsData = alphabeticalSort(
		orchardsDataState.map((orchard) => ({
			label: orchard.orchard,
			value: orchard.orchardValue,
		})),
		'label'
	);

	const handleCloseSnackBarsComponent = () => {
		setErrorState(false);
	};

	// función para confirmar cambios
	const onConfirmDataLoading = async () => {
		setLoadingConfirmActionState(true);

		const selectedClient = clientDataState.find(
			(eve) => eve.clientValue === watchClient
		);

		const selectedOrchard = transformedOrchardsData.find(
			(orch) => orch.value === watchOrchard
		);

		const objQueryConfirm = {
			client: selectedClient.label,
			clientValue: selectedClient.value,
			orchard: selectedOrchard.label,
			orchardValue: selectedOrchard.value,
			username: userName,
		};

		console.log(objQueryConfirm, 'objQueryConfirm');

		try {
			const response = await axiosContext.authAxios.post(
				initialUploadEndpoint,
				objQueryConfirm,
				{
					timeout: 360000, // Aquí puedes ajustar el tiempo de espera en milisegundos (en este ejemplo, 5 segundos)
				}
			);

			// console.log(response, '------response de plantas calculadas');
			const { status, data } = response;
			if (status === 200) {
				console.log('data', data);
				// TODO: recibir mensaje de éxito
				setSuccessMessageState('Carga inicial Exitosa');
				setValue('client', '');
				setValue('orchard', '');
			}
		} catch (error) {
			if (error.request.status === 500) {
				setErrorState(true);
			}
		} finally {
			setLoadingConfirmActionState(false);
		}
	};

	return (
		<div className="form-style">
			<BsContainer fluid class="px-0 px-md-3">
				<BsRow class="no-gutters">
					<BsCol class="col-md-12">
						<Card elevation={0}>
							<CardHeaderWidget
								title={viewsTexts.titles.initialUpload.title}
								subheader={<BreadCrumbs subTitle="" />}
							/>
							{/* <Divider /> */}
							<CardContent>
								<BsRow>
									<BsCol class="col-md-12">
										<form
											autoComplete="on"
											onSubmit={handleSubmit(onSubmit)}
										>
											<BsRow>
												<BsCol class="col-md-5">
													<TextFieldWidgetSelectControllerHorizontal
														options={
															clientDataState
														}
														titleOption="label"
														control={control}
														name="client"
														labeltext="Cliente"
														errors={errors.client}
														defaultValue=""
														loading={
															loadingFiltersState
														}
														req
														withDefault
														disabled={
															loadingQueryState
														}
														variant="outlined"
													/>
												</BsCol>
												<BsCol class="col-md-5">
													<TextFieldWidgetSelectControllerHorizontal
														options={
															transformedOrchardsData
														}
														titleOption="label"
														control={control}
														name="orchard"
														labeltext="Huerto"
														errors={errors.orchard}
														multiple={false}
														defaultValue=""
														loading={
															loadingFiltersState
														}
														req
														withDefault
														disabled={
															loadingQueryState
														}
														variant="outlined"
													/>
												</BsCol>
												<SaveAction
													title="Procesar"
													color="secondary"
													size="large"
													className="roundedButton ml-md-2"
													variant="contained"
													disabled={loadingQueryState}
													messageType="add"
													mutationLoading={
														loadingQueryState
													}
												/>
											</BsRow>
											<BsRow class="mt-5">
												<BsCol class="col-md-12">
													{!alertOpen &&
														!successMessageState && (
															<AlertMessages
																severity={
																	excelUrl
																		? 'success'
																		: 'info'
																}
																alertTitle={
																	excelUrl
																		? 'excel_confirm'
																		: 'info_link'
																}
															/>
														)}
												</BsCol>
												{successMessageState && (
													<BsCol class="col-md-12 mt-4">
														<AlertMessages
															severity="success"
															alertTitle="initial_load_confirm"
														/>
													</BsCol>
												)}
											</BsRow>
										</form>

										<BsRow class="mt-2">
											<BsCol class="col-md-6">
												<ButtonAction
													labelButton="Ir al Excel"
													color="secondary"
													size="large"
													className="roundedButton ml-md-2"
													variant="contained"
													mutationLoading={false}
													disabled={
														loadingQueryState ||
														!successQueryState
													} // Deshabilita si la consulta está cargando o no se ha realizado con éxito
													actionOnClick={() =>
														window.open(
															excelUrl,
															'_blank'
														)
													}
												/>
											</BsCol>
											<BsCol class="col-md-6">
												<ButtonAction
													labelButton="Confirmar carga de unidades"
													color="secondary"
													size="large"
													className="roundedButton ml-md-2"
													variant="contained"
													mutationLoading={
														loadingQueryState ||
														loadingConfirmActionState
													}
													disabled={
														loadingConfirmActionState ||
														!excelUrl
													} // Deshabilita si la consulta está cargando o no se ha realizado con éxito
													//disabled={loadingQueryState}
													actionOnClick={
														onConfirmDataLoading
													}
												/>
											</BsCol>
										</BsRow>
									</BsCol>
								</BsRow>

								{errorState && (
									<Snackbar
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'center',
										}}
										open={errorState}
										onClose={() => setErrorState(false)}
										autoHideDuration={4500}
										key={Math.random()}
									>
										<SnackBarsComponent
											variant="error"
											message="No hay archivo asociado a los datos ingresados"
											handleClose={
												handleCloseSnackBarsComponent
											}
										/>
									</Snackbar>
								)}
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};
export default InitialUpload;
