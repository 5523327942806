import _ from 'lodash';
import {
	Accordion,
	AccordionSummary,
	Avatar,
	Button,
	Card,
	CardContent,
	Divider,
	Grid,
	Hidden,
	makeStyles,
	Modal,
	Paper,
	Typography,
	useTheme,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import {
	LoadingWidget,
	SaveAction,
	TextFieldWidgetSelectControllerStyled,
} from '../../components';
import { FiltersContext } from '../../context';
import { AxiosContext } from '../../context/AxiosContext';

// import { ButtonBox } from '../../components/Dashboard';

const useStyles = makeStyles((theme) => ({
	tableAreaStyle: {
		maxHeight: '370px',
		overflow: 'auto',
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paperContainerStyle: {
		padding: 20,
		marginBottom: 15,
	},
	dashCard: {
		minHeight: '100%',
	},
	dividerRow: {
		marginTop: 30,
	},
	theadLight: {
		'& th': {
			// color: '#495057',
			backgroundColor: theme.palette.grey[200],
			borderColor: theme.palette.grey[300],
		},
	},
}));

const Report = () => {
	// let history = useHistory();

	const theme = useTheme();

	console.log(theme);

	const classes = useStyles();

	const axiosContext = useContext(AxiosContext);

	const {
		state: { userOrchard_filter, orchard_filter, orchard_id_filter },
		addFilter,
	} = useContext(FiltersContext);

	const [loadingDataState, setLoadingDataState] = useState(false);
	const [urlDataState, setUrlDataState] = useState(null);

	const getReportAsync = async () => {
		setLoadingDataState(true);
		setUrlDataState(null);
		try {
			let response = await axiosContext.authAxios.get(
				'v1/library/library-yield-calculator/comparison-yield-calculator-with-others'
			);

			// console.log(response, '<<----respo clientes');

			const { data, status } = response;

			if (status === 200) {
				setUrlDataState(data.url);
				setLoadingDataState(false);
			}
		} catch (error) {
			console.log(error);
			setLoadingDataState(false);
			// setLoadingVarietyFilterState(false);
		}
	};

	return (
		<div className="form-style">
			<BsContainer fluid class="px-0 px-md-3">
				<BsRow class="no-gutters">
					<BsCol class="col-12 mb-md-3">
						<Card
							elevation={0}
							// classes={{
							// 	root: 'MainCardRoot',
							// }}
						>
							<CardContent>
								<BsRow class="mb-1 mb-md-4">
									<BsCol class="col-md-6">
										<Typography
											gutterBottom
											variant="h3"
											component="h2"
										>
											Reportes
										</Typography>
									</BsCol>
								</BsRow>
								<BsRow>
									<BsCol class="col-md-12">
										<Button
											variant="contained"
											color="secondary"
											onClick={getReportAsync}
											disabled={loadingDataState}
										>
											Generar Informe Verificador Datos RP
										</Button>
									</BsCol>
									{loadingDataState && <LoadingWidget />}
									{urlDataState && (
										<BsCol class="col-md-12 mt-4">
											<div
												className="alert alert-primary"
												role="alert"
											>
												<a
													target="_blank"
													href={urlDataState}
												>
													DESCARGUE AQUÍ
												</a>
											</div>
										</BsCol>
									)}
								</BsRow>
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};

export default Report;
