import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import classes from './TextFieldWidgetText.module.scss';
import { validateRut, IsDesktopHandler } from '../../../helpers';
import errorMessage from '../../../common/errorMessages.json';
import { Controller } from 'react-hook-form';

const TextFieldWidgetText = (props) => {
	const {
		errors,
		req,
		loading,
		disabled,
		defaultValue,
		name,
		control,
		...others
	} = props;
	const [stateMessage, setStateMessage] = useState('');
	const [stateError, setStateError] = useState(false);
	useEffect(() => {
		if (errors) {
			switch (errors.type) {
				case 'required':
					setStateMessage(
						errorMessage.message[name].required
							? errorMessage.message[name].required
							: errorMessage.message[name]
					);
					break;
				case 'pattern':
					setStateMessage(errorMessage.message[name].pattern);
					break;
				case 'maxLength':
					setStateMessage(errorMessage.message[name].maxLength);
					break;
				case 'minLength':
					setStateMessage(errorMessage.message[name].minLength);
					break;
				case 'menorQue':
					setStateMessage(errorMessage.message[name].menorQue);
					break;
				case 'mayorQue':
					setStateMessage(errorMessage.message[name].mayorQue);
					break;
				case 'rutValido':
					setStateMessage(errorMessage.message[name].rutValido);
					break;
				case 'matchesPreviousPassword':
					setStateMessage(
						errorMessage.message[name].matchesPreviousPassword
					);
					break;
				case 'sizeFileValidate':
					setStateMessage('error en el doc, de peso');
					break;
				case 'typeFileValidate':
					setStateMessage('error en el doc, de tipo');
					break;
				default:
					break;
			}
			setStateError(true);
		} else {
			setStateMessage('');
			setStateError(false);
		}
	}, [errors]);

	//   validaciones
	let pattern = null;
	let validate = null;
	if (props.name === 'email') {
		pattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
	}

	if (name === 'rut' && req) {
		validate = {
			rutValido: (value) => validateRut(value),
		};
	}

	// ADJUNTOS

	// if (
	// 	name === 'CNIsimpleCopy' ||
	// 	name === 'updatedCurriculumVitae'
	// ) {
	// 	validate = {
	// 		sizeFileValidate(value) {
	// 			if (!value[0]) {
	// 				return true;
	// 			} else {
	// 				return value[0].size < 3145728;
	// 			}
	// 		},
	// 		typeFileValidate(value) {
	// 			if (!value[0]) {
	// 				return true;
	// 			} else {
	// 				return (
	// 					value[0].type === 'application/pdf' ||
	// 					value[0].type === 'image/jpeg' ||
	// 					value[0].type === 'image/png'
	// 				);
	// 			}
	// 		},
	// 	};
	// }

	// NOTAS
	// if (
	// 	name === 'pediatricInternship' ||
	// 	name === 'gynecologyInternship'
	// ) {
	// 	pattern = /^(10|\d([.,]\d{1,2})?)$/;
	// }

	if (name === 'password2' && props.equalto) {
		validate = {
			matchesPreviousPassword: (value) => props.equalto === value,
		};
	}

	let labelText = null;
	const isDesktop = IsDesktopHandler('sm');
	if (!isDesktop) {
		labelText = props.labeltext;
	} else {
		labelText = null;
	}

	return (
		<div className={classes.inputContainerStyle}>
			<div className={classes.labelDesktopStyle}>
				{props.labeltext} {props.download}
			</div>
			{props.loading && <p>cargando</p>}
			<Controller
				control={control}
				render={({ field }) => (
					<TextField
						variant="outlined"
						{...others}
						{...field}
						margin="dense"
						fullWidth
						label={labelText}
						disabled={disabled}
						error={stateError}
						helperText={stateMessage}
						classes={{
							root: classes.textFieldRootStyle,
						}}
					/>
				)}
				name={name}
				defaultValue={defaultValue}
				rules={{
					required: props.req,
					maxLength: props.maxLength,
					minLength: props.minLength,
					pattern: pattern,
					validate: validate,
				}}
			/>
		</div>
	);
};

TextFieldWidgetText.propTypes = {
	name: PropTypes.string.isRequired,
	req: PropTypes.bool,
	labeltext: PropTypes.string.isRequired,
	loading: PropTypes.bool,
};

export default TextFieldWidgetText;
