import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import classes from './TextFieldWidgetSelect.module.scss';
import { IsDesktopHandler } from '../../../helpers';
import errorMessage from '../../../common/errorMessages.json';
import { Controller } from 'react-hook-form';

const TextFieldWidgetSelect = (props) => {
	// console.log(props, '<------------------');
	const {
		register,
		errors,
		options,
		onChangeFunc,
		req,
		loading,
		selected,
		disabled,
		withDefault,
		...others
	} = props;
	const [stateMessage, setStateMessage] = useState('');
	const [stateError, setStateError] = useState(false);

	const [selectedValueState, setSelectedValueState] = useState('');

	useEffect(() => {
		if (errors) {
			switch (errors.type) {
				case 'required':
					setStateMessage(
						errorMessage.message[errors.ref.name].required
							? errorMessage.message[errors.ref.name].required
							: errorMessage.message[errors.ref.name]
					);
					break;
				default:
					break;
			}
			setStateError(true);
		} else {
			setStateMessage('');
			setStateError(false);
		}
	}, [errors]);

	useEffect(() => {
		setSelectedValueState(selected);
	}, [selected]);

	let labelText = null;
	const isDesktop = IsDesktopHandler('sm');
	if (!isDesktop) {
		labelText = props.labeltext;
	} else {
		labelText = null;
	}

	console.log('---------->', selectedValueState);

	//   validaciones
	return (
		<div className={classes.inputContainerStyle}>
			<div className={classes.labelDesktopStyle}>{props.labeltext}</div>
			{props.loading && <p>cargando</p>}
			<Controller
				render={({ field }) => (
					<TextField
						variant="outlined"
						{...others}
						select
						margin="dense"
						label={labelText}
						disabled={disabled}
						value={selectedValueState}
						onChange={(e) => onChangeFunc(e.target.value)}
						fullWidth
						SelectProps={{
							native: true,
						}}
						error={stateError}
						helperText={stateMessage}
						// value={props.selected !== null ? props.selected : '0'}
					>
						{withDefault ? (
							props.selected === 0 ? (
								<option key="0" value="">
									Seleccione...
								</option>
							) : (
								<option key="0" value="">
									Seleccione...
								</option>
							)
						) : null}
						{options &&
							options.map((option) => (
								<option key={option.id} value={option.id}>
									{option.name}
								</option>
							))}
					</TextField>
				)}
				name={props.name}
				defaultValue={selectedValueState}
				control={props.control}
				rules={{
					required: props.req,
				}}
			/>
		</div>
	);
};

TextFieldWidgetSelect.propTypes = {
	name: PropTypes.string.isRequired,
	req: PropTypes.bool,
	loading: PropTypes.bool,
};

export default TextFieldWidgetSelect;
