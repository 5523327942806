import React, { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
	AppBar,
	Toolbar,
	// Hidden,
	IconButton,
	// useTheme,
	Avatar,
	Menu,
	Button,
	ListItemIcon,
	ListItemText,
} from '@material-ui/core';
import LogoTopbar from '../../../../assets/logo-white.png';
import Bgtoplogo from '../../../../assets/backlogo-top.png';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
// import InputIcon from '@material-ui/icons/Input';
// import Select from '@material-ui/core/Select';
// import { useActions } from 'store/actions';

import classesModule from './Topbar.module.scss';

// import './SelectStyle.scss';
import { Context } from '../../../../context/Context';
import { makeStyles } from '@material-ui/styles';
// import Typography from 'theme/typography';
import { IsDesktopHandler } from 'helpers';

// const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer - 11,
		// paddingLeft: theme.spacing(8) + 1,
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(8) + 1,
		},
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		// marginLeft: drawerWidth,
		// width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	rightBar: {
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'space-between',
	},
	buttonProfile: {
		display: 'flex',
		flexDirection: 'column',
		lineHeight: 1.2,
		alignItems: 'flex-start',
		'&& div': {
			fontSize: 14,
			fontWeight: 700,
		},
		'&& span': {
			fontSize: 12,
		},
	},
}));

const Topbar = (props) => {
	const { className, onSidebarOpen, openState, drawerWidth, ...rest } = props;

	const {
		// projectSelected,
		// projectList,
		// setProjectSelected,
		userFullname,
		logout,
	} = useContext(Context);

	// console.log(userFullname);

	const [anchorElState, setAnchorElState] = useState(null);

	const classes = useStyles();
	// const theme = useTheme();
	// const context = useContext(Context);

	// console.log(projectList, 'todo el contexto proyectos');

	// const { changeProject } = useActions();

	// const [projectState, setProjectState] = useState(null);

	// useEffect(() => {
	// 	console.log(projectSelected, 'en el topbar');
	// 	setProjectState(projectSelected.id);
	// }, [projectSelected]);

	// const handleChange = (event) => {
	// 	if (event.target.name === 'project_select') {
	// 		const result = projectList.filter(
	// 			(ele) => ele.id === event.target.value
	// 		);

	// 		setProjectSelected(result[0]);
	// 		// changeProject(event.target.value);
	// 	} else {
	// 		// setAge(event.target.value);
	// 	}
	// };

	const handleTopClick = (event) => {
		setAnchorElState(event.currentTarget);
	};

	const handleTopClose = () => {
		setAnchorElState(null);
	};

	const renderSelects = () => {
		return (
			<div className={classesModule.topBarDropdown}>
				<Avatar alt="name">
					<PersonIcon />
				</Avatar>
				<Button
					id="profile-button"
					aria-controls="profile-menu"
					aria-haspopup="true"
					color="inherit"
					onClick={handleTopClick}
					aria-expanded={Boolean(anchorElState) ? 'true' : undefined}
					endIcon={<KeyboardArrowDownIcon />}
				>
					<div className={classes.buttonProfile}>
						<div>{userFullname}</div>
						{/* <span>Administrador general</span> */}
					</div>
				</Button>
				<Menu
					id="profile-menu"
					anchorEl={anchorElState}
					getContentAnchorEl={null}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					keepMounted
					open={Boolean(anchorElState)}
					onClose={handleTopClose}
					MenuListProps={{
						'aria-labelledby': 'profile-button',
					}}
				>
					{/* <MenuItem onClick={handleTopClose}>
						<ListItemIcon>
							<PermIdentityIcon />
						</ListItemIcon>
						<ListItemText primary="Perfil usuario" />
					</MenuItem> */}
					<MenuItem
						onClick={() => {
							handleTopClose();
							logout();
						}}
					>
						<ListItemIcon>
							<ExitToAppIcon />
						</ListItemIcon>
						<ListItemText primary="Cerrar sesión" />
					</MenuItem>
				</Menu>
			</div>
		);
	};

	// console.log(theme.breakpoints.up('sm'), '<-----');

	return (
		<AppBar
			{...rest}
			position="fixed"
			classes={{
				colorPrimary: classes.colortopbar,
			}}
			className={clsx(classes.appBar, {
				[classes.appBarShift]: openState,
			})}
		>
			<Toolbar
				style={{
					backgroundImage: `url(${Bgtoplogo})`,
					backgroundRepeat: 'no-repeat',
					backgroundPositionX: '-40px',
				}}
			>
				{/* para formato flex */}
				{!IsDesktopHandler('sm') && (
					<div className="mr-4">
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={onSidebarOpen}
							edge="start"
						>
							<MenuIcon />
						</IconButton>
					</div>
				)}
				<div className={classes.rightBar}>
					<RouterLink to="/">
						<img
							className={clsx(classesModule.logoTop, 'ml-2')}
							alt="Logo"
							// src="/images/logos/logo-top-w.png"
							src={LogoTopbar}
						/>
					</RouterLink>

					<div className={classesModule.flexGrow} />
					{/* <Hidden smDown> */}
					{IsDesktopHandler('sm') && renderSelects()}
					{/* <IconButton
						className={classesModule.signOutButton}
						color="default"
						onClick={logout}
					>
						<InputIcon />
					</IconButton> */}
					{/* </Hidden> */}
					{/* <Hidden mdUp>
					<IconButton color="secondary" onClick={onSidebarOpen}>
						<MenuIcon />
					</IconButton>
				</Hidden> */}
				</div>
			</Toolbar>
		</AppBar>
	);
};

Topbar.propTypes = {
	className: PropTypes.string,
	onSidebarOpen: PropTypes.func,
};

export default Topbar;
