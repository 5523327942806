import gql from "graphql-tag";

export const signUp = gql`
  mutation signUp(
    $profile: UserInputProfile
    $email: String
    $name: String
    $lastName: String
    $phone: String
    $password: String
  ) {
    signUp(
      Input: {
        profile: $profile
        email: $email
        name: $name
        lastName: $lastName
        phone: $phone
        password: $password
      }
    )
  }
`;

export const signUpGoogle = gql`
  mutation signUpGoogle(
    $profile: UserInputProfile
    $email: String
    $name: String
    $lastName: String
    $photo: String
  ) {
    signUpGoogle(
      Input: {
        profile: $profile
        email: $email
        name: $name
        lastName: $lastName
        photo: $photo
      }
    ) {
      id
      token
      profile {
        id
        name
        value
      }
      password
    }
  }
`;
