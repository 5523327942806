import React from 'react';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import {
  IconButton,
  Tooltip,
} from '@material-ui/core';
import '../style.scss';
const IconActionTableStateOff = (props) => {
  const { handleActionState } = props;

  return (
    <Tooltip title="Cambio de estado" className="buttonIconAction">
      <IconButton aria-label="state" onClick={handleActionState}>
        <AssignmentLateIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}

export default IconActionTableStateOff;