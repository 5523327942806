import React from 'react';
import classes from './LabelChip.module.scss';

const LabelChip = (props) => {
	const { label } = props;

	return (
		<div
			className={
				label === 'Activo'
					? classes.containerStyle
					: classes.containerTransparentStyle
			}
		>
			<span>{label}</span>
		</div>
	);
};

export default LabelChip;
