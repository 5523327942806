import React from 'react';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import {
  IconButton,
  Tooltip,
} from '@material-ui/core';
import '../style.scss';
const IconActionTableProfile = (props) => {
  const { handleActionDetail } = props;
  //<IconButton aria-label="delete" onClick={() => handleActionDetail(row)}>
  return (
    <Tooltip title="Ver Ficha" className="buttonIconAction">
      <IconButton aria-label="detail" onClick={handleActionDetail} >
        <AccountBoxIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}

export default IconActionTableProfile;