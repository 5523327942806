import React from 'react';
// import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// import classesModule from './Tabs.module.scss';
import { IsDesktopHandler } from 'helpers';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	tabsStyle: {
		borderBottomColor: theme.palette.divider,
		borderBottom: '1px solid',
	},
}));

const SimpleTabs = (props) => {
	const classes = useStyles();

	const tabList = props.tabitems.map((tKey, tid) => {
		return (
			<Tab
				key={tid}
				classes={
					{
						// root: classes.rootTabs,
						// selected: classes.TabsSelected,
						// textColorPrimary: classes.TabsTextColor,
					}
				}
				label={tKey}
				{...a11yProps({ tid })}
			/>
		);
	});

	const isDesktop = IsDesktopHandler('md');

	// console.log(isDesktop, '<-----isDesktop');

	return (
		<div className={classes.root}>
			<Box
				elevation={2}
				component="div"
				// style={{ borderBottom: '1px solid' }}
			>
				<Tabs
					value={props.value}
					onChange={props.toggler}
					indicatorColor="secondary"
					className={classes.tabsStyle}
					textColor="secondary"
					aria-label="area de Tabs"
					variant="scrollable"
					scrollButtons={isDesktop ? 'auto' : 'on'}
				>
					{}
					{tabList}
				</Tabs>
			</Box>
			{props.children}
		</div>
	);
};

export default SimpleTabs;
