import React from 'react';
import clsx from 'clsx';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { IconButton, Tooltip } from '@material-ui/core';

import classes from './TagsTableDetail.module.scss';
import { IconActionTableDelete } from 'components';

const DocumentsTableDetail = (props) => {
	const { dataDocs, deleteAction, title } = props;

	return (
		<table
			className={clsx(
				'table table-borderless table-sm',
				classes.tableStyle
			)}
		>
			<thead>
				<tr>
					<th colSpan="1">
						<i className="fas fa-tag"></i> {title} Asociados
					</th>
				</tr>
				<tr>
					<th scope="col">Tag</th>
				</tr>
			</thead>
			<tbody>
				{dataDocs.map((docFile) => {
					return (
						<tr key={docFile.id}>
							<td>{docFile.name}</td>
						</tr>
					);
				})}
				{dataDocs.length === 0 && (
					<tr>
						<td colSpan="1">No hay documentos adjuntos.</td>
					</tr>
				)}
			</tbody>
		</table>
	);
};

export default DocumentsTableDetail;
