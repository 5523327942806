import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackBarsComponent from '../../SnackBarsComponent';
import CircularProgressComponent from '../../Progress/CircularProgressComponent';
import classes from './ButtonAction.module.scss';
import snackBarsMessages from '../../../common/snackBarsMessages.json';
import { CardActions, Button } from '@material-ui/core';

const ButtonAction = props => {
  const {
    mutationLoading,
    success,
    messageType,
    disabled,
    actionOnClick,
    labelButton,
    variant = "contained",
    color = "primary"
  } = props;
  const snackBarMessage = snackBarsMessages.message[messageType];

  const [stateSuccess, setStateSuccess] = useState(false);
  const handleCloseSnackBarsComponent = () => {
    setStateSuccess(false);
    const closeAction = props.actionClose ? props.actionClose : () => {};
    closeAction();
  };


  useEffect(() => {
    if (success) {
      setStateSuccess(true);
    } else {
      setStateSuccess(false);
    }
  }, [success]);
  return (
    <CardActions className={classes.cardActions}>
      <div className={classes.wrapper}>
        <Button
          color={color}
          variant={variant}
          type="button"
          onClick={actionOnClick}
          disabled={disabled ? disabled : mutationLoading}>
          {labelButton}
        </Button>
        {mutationLoading && <CircularProgressComponent />}
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={stateSuccess}
        onClose={() => setStateSuccess(false)}
        autoHideDuration={4500}
        key={Math.random()}>
        <SnackBarsComponent
          variant="success"
          message={snackBarMessage}
          handleClose={handleCloseSnackBarsComponent}
        />
      </Snackbar>
    </CardActions>
  );
};

export default ButtonAction;
