import gql from 'graphql-tag';

export const signValidation = gql`
	mutation($email: String!, $validationCode: String!) {
		signValidation(
			authInput: { email: $email, validationCode: $validationCode }
		) {
			id
			email
			rut
			name
			paternalLastname
			maternalLastname
			phone
			profile {
				id
				name
			}
			state {
				id
				name
			}
			moduleUser {
				id
				name
			}
			processUser {
				id
				name
				module {
					id
					name
				}
			}
			password
			delete
			edit
		}
	}
`;
