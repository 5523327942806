import React, { useState, useEffect } from 'react';
import esLocale from 'date-fns/locale/es';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	DatePicker,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import { IsDesktopHandler } from '../../../helpers';
import classes from './TextFieldWidgetDateController.module.scss';
import errorMessage from '../../../common/errorMessages.json';
import { Controller } from 'react-hook-form';
import {
	TextField,
	InputLabel,
	makeStyles,
	FormControl,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 210,
		maxWidth: 230,
		[theme.breakpoints.down('sm')]: {
			minWidth: '95%',
		},
	},
	rootLabel: {
		fontSize: 18,
	},
	textField: {
		marginTop: 22,
	},
}));

const TextFieldWidgetDateController = (props) => {
	const {
		errors,
		success,
		labeltext,
		name,
		loading,
		disabled,
		control,
		defaultValue,
		req,
		...others
	} = props;

	const classesUI = useStyles();
	const [stateMessage, setStateMessage] = useState('');
	const [stateError, setStateError] = useState(false);
	const esLocaleDate = esLocale;
	// const [selectedDate, setSelectedDate] = useState('');
	// const handleDateChange = date => {
	//   setSelectedDate(date);
	// };
	useEffect(() => {
		if (errors && errors.message) {
			setStateMessage(errors.message);
			setStateError(true);
		} else {
			setStateMessage('');
			setStateError(false);
		}
	}, [errors]);

	// useEffect(() => {
	//   if (success) {
	//     setSelectedDate('');
	//   }
	// }, [success]);

	let labelText = null;
	const isDesktop = false;
	if (!isDesktop) {
		labelText = labeltext;
	} else {
		labelText = null;
	}

	return (
		<FormControl className={classesUI.formControl} error={stateError}>
			{/* <div className={classes.labelDesktopStyle}>{labeltext}</div> */}
			{loading && <p>cargando</p>}
			<InputLabel
				shrink={true}
				id={labelText}
				className={classesUI.rootLabel}
			>
				{props.labeltext} {loading && '...cargando'}
			</InputLabel>

			<Controller
				control={control}
				defaultValue={defaultValue}
				name={name}
				rules={{
					required: errorMessage.message[name],
				}}
				render={({ field }) => {
					// console.log(field, '-----');
					const { ref, ...otherfields } = field;
					return (
						<MuiPickersUtilsProvider
							utils={DateFnsUtils}
							locale={esLocaleDate}
						>
							<KeyboardDatePicker
								clearable
								name={name}
								placeholder="25-10-2021"
								format="dd-MM-yyyy"
								label=""
								variant="standard"
								inputVariant="standard"
								disabled={disabled}
								{...otherfields}
								// onChange={onChange}
								// value={value}
								{...others}
								fullWidth
								TextFieldComponent={(params) => (
									<TextField
										inputRef={ref}
										className={classesUI.textField}
										{...params}
									/>
								)}
								InputLabelProps={{ shrink: true }}
								invalidDateMessage="Fecha no válida"
								margin="dense"
								// onChange={handleDateChange}
								// inputRef={register({
								//   required: errorMessage.message[name]
								// })}
								error={stateError}
								helperText={stateMessage}
							/>
						</MuiPickersUtilsProvider>
					);
				}}
			/>
		</FormControl>
	);
};
export default TextFieldWidgetDateController;
