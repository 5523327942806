import React from 'react';
import clsx from 'clsx';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { IconButton, Tooltip } from '@material-ui/core';

import classes from './DocumentsTableDetail.module.scss';
import { IconActionTableDelete } from 'components';

const DocumentsTableDetail = props => {
  const { dataDocs, deleteAction, title } = props;

  return (
    <table className={clsx("table table-borderless table-sm", classes.tableStyle)}>
      <thead>
        <tr>
          <th colSpan="2"><i className="fas fa-paperclip"></i> Adjuntos del {title}</th>
        </tr>
        <tr>
          <th scope="col">Nombre Adjunto</th>
          <th scope="col">Acción</th>
        </tr>
      </thead>
      <tbody>
        {
          dataDocs.map((docFile) => {
            return (
              <tr key={docFile.id}>
                <td>{docFile.name}</td>
                <td>
                  <Tooltip title="Ver Imagen" className="buttonIconAction">
                    <IconButton aria-label="detail" onClick={()=> window.open(docFile.href, "_blank")} >
                      <AttachFileIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  { deleteAction && (
                    <IconActionTableDelete
                      handleActionDelete={() => deleteAction(docFile)}
                    />
                  )}
                </td>
              </tr>
            )
          })
        }
        {
          dataDocs.length === 0 && <tr><td colSpan="2">No hay documentos adjuntos.</td></tr>
        }
      </tbody>
    </table>
  );
};

export default DocumentsTableDetail;
