import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { updateParameter } from '../../graphql/mutation/parameter/parameter';
import { listParameter } from '../../graphql/queries/parameter/parameter';
import {
	SaveAction,
	CardHeaderWidget,
	TextFieldWidgetText,
	TextFieldWidgetDate,
	BreadCrumbs,
} from '../../components';
import { Card, CardContent, Divider } from '@material-ui/core';
import { BsRow, BsCol, BsContainer } from '../../layouts/components';
import '../../assets/scss/form.scss';
// import { Context } from '../../context/Context';
import viewsTexts from '../../common/viewsTexts.json';

const Parameters = (props) => {
	const require = true;
	const [parametersDataState, setParametersDataState] = useState([]);
	// const { userId } = useContext(Context);

	const { handleSubmit, control, errors } = useForm();

	const { data: dataParametersGraph } = useQuery(listParameter, {
		// variables: {
		// 	idProject: projectSelected.id,
		// },
		errorPolicy: 'all',
		fetchPolicy: 'cache-first',
	});

	const [
		updateParametersHandler,
		{ loading: loadingSubmitGraph, data: dataSubmitGraph },
	] = useMutation(updateParameter);

	useEffect(() => {
		// console.log(dataParametersGraph, '<---');
		if (dataParametersGraph && dataParametersGraph.listParameter) {
			// console.log('datos: ', dataParametersGraph.listParameter);
			setParametersDataState(dataParametersGraph.listParameter);
		}
	}, [dataParametersGraph]);

	// const onChange = (e, id) => {
	//   const val = e.target.value;
	//   const newParams = parametersDataState.map(param => {
	//     if (param.id === id) {
	//       const updatedParam = {
	//         ...param,
	//         value: val
	//       };
	//       return updatedParam;
	//     }
	//     return param;
	//   });
	//   setParametersDataState(newParams);
	// };

	const parametro = parametersDataState.map((parameter) => {
		if (parameter.type !== 'date') {
			return (
				<TextFieldWidgetText
					InputLabelProps={{ shrink: true }}
					name={parameter.nameInput}
					type={parameter.type}
					labeltext={parameter.name}
					req={require}
					key={parameter.name}
					defaultValue={parameter.value}
					// onChange={e => onChange(e, parameter.id)}
					control={control}
					placeholder={`Ingrese ${parameter.name}`}
					autoComplete={parameter.name}
					// errors={`errors.${parameter.nameInput}`}
					errors={errors[parameter.nameInput]}
				/>
			);
		}
		if (parameter.type === 'date') {
			return (
				<TextFieldWidgetDate
					name="date"
					label="Fecha"
					control={control}
					errors={errors.date}
				/>
			);
		}
		return parameter;
	});

	const onSubmit = async (values) => {
		let newArray = parametersDataState;

		Object.keys(values).map((param) => {
			const elementIndex = parametersDataState.findIndex(
				(element) => element.nameInput === param
			);

			newArray[elementIndex] = {
				...newArray[elementIndex],
				value: values[param],
			};

			return newArray;
		});

		// console.log(parametersDataState, '<------resultado final');

		// const onChange = (e, id) => {
		//   const val = e.target.value;
		//   const newParams = parametersDataState.map(param => {
		//     if (param.id === id) {
		//       const updatedParam = {
		//         ...param,
		//         value: val
		//       };
		//       return updatedParam;
		//     }
		//     return param;
		//   });
		//   setParametersDataState(newParams);
		// };

		const query = parametersDataState.map((param) => {
			if (param.id) {
				const updatedParam = {
					id: param.id,
					value: param.value,
				};
				return updatedParam;
			}
			return param;
		});

		const variable = {
			parameterInput: query,
		};

		console.log(variable, '<----');

		try {
			await updateParametersHandler({
				variables: variable,
			});
		} catch (err) {}
	};

	return (
		<div className="form-style">
			<BsContainer fluid class="px-md-0">
				<BsRow>
					<BsCol class="col-md-12">
						<Card>
							<CardHeaderWidget
								title={viewsTexts.titles.parameters.title}
								subheader={
									<BreadCrumbs
										subTitle={
											viewsTexts.titles.parameters
												.sugestion
										}
									/>
								}
							/>
							<Divider />
							<CardContent>
								<BsRow>
									<BsCol class="col-md-12">
										<form
											autoComplete="on"
											onSubmit={handleSubmit(onSubmit)}
										>
											<BsRow>
												<BsCol class="col-md-12">
													{parametro}
												</BsCol>
											</BsRow>
											<SaveAction
												messageType="add"
												mutationLoading={
													loadingSubmitGraph
												}
												success={dataSubmitGraph}
												actionClose={props.closeFunc}
											></SaveAction>
										</form>
									</BsCol>
								</BsRow>
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};
export default Parameters;
