// import _ from 'lodash';
import {
	Accordion,
	AccordionSummary,
	Avatar,
	Button,
	Card,
	CardContent,
	Divider,
	Grid,
	Hidden,
	makeStyles,
	Modal,
	Paper,
	Typography,
	useTheme,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import {
	LoadingWidget,
	SaveAction,
	TextFieldWidgetSelectControllerStyled,
} from '../../components';
import { FiltersContext } from '../../context';
import clsx from 'clsx';
import { numberFormat, numberFormatDecimals } from 'helpers';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
// import { Context } from '../../context/Context';

// import { ButtonBox } from '../../components/Dashboard';

const useStyles = makeStyles((theme) => ({
	tableAreaStyle: {
		maxHeight: '370px',
		overflow: 'auto',
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paperContainerStyle: {
		padding: 20,
		marginBottom: 15,
	},
	dashCard: {
		minHeight: '100%',
	},
	dividerRow: {
		marginTop: 30,
	},
	theadLight: {
		'& th': {
			// color: '#495057',
			backgroundColor: theme.palette.grey[200],
			borderColor: theme.palette.grey[300],
		},
	},
}));

const Dashboard = () => {
	// let history = useHistory();

	const theme = useTheme();

	console.log(theme);

	const classes = useStyles();

	const {
		state: { userOrchard_filter, orchard_filter, orchard_id_filter },
		addFilter,
	} = useContext(FiltersContext);

	const [loadingDashDataState, setLoadingDashDataState] = useState(false);
	const [dashDataState, setDashDataState] = useState(null);

	return (
		<div className="form-style">
			<Redirect to="/user-list" />
			<BsContainer fluid class="px-0 px-md-3">
				<BsRow class="no-gutters">
					<BsCol class="col-12 mb-md-3">
						<Card
							elevation={0}
							// classes={{
							// 	root: 'MainCardRoot',
							// }}
						>
							<CardContent>
								<BsRow class="mb-1 mb-md-4">
									<BsCol class="col-md-6">
										<Typography
											gutterBottom
											variant="h3"
											component="h2"
										>
											Panel Administración
										</Typography>
									</BsCol>
									{!loadingDashDataState && (
										<BsCol class="col-md-6 align-self-end text-right">
											<Typography
												variant="h6"
												gutterBottom
											>
												{new Date().toLocaleDateString(
													'es-ES',
													{
														weekday: 'long',
														year: 'numeric',
														month: 'short',
														day: 'numeric',
													}
												)}
											</Typography>
											{/* <Typography
												variant="body2"
												gutterBottom
											>
												Última actualización 17:45 hrs.
											</Typography> */}
										</BsCol>
									)}
								</BsRow>
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
				{!loadingDashDataState ? (
					<>
						{/* 2 row */}
						<BsRow class="no-gutters">
							<BsCol class="col-md-12">
								<BsRow class="gutter_10">
									<BsCol class="col-md-6 col-lg-5">
										<Card
											elevation={0}
											className={classes.dashCard}
										>
											<CardContent></CardContent>
										</Card>
									</BsCol>
									<BsCol class="col-md-6 col-lg-7">
										<Card
											elevation={0}
											className={classes.dashCard}
										>
											<CardContent>
												<Grid
													container
													direction="row"
													justifyContent="space-between"
													alignItems="center"
												>
													<Grid item></Grid>
													<Grid item></Grid>
												</Grid>
											</CardContent>
										</Card>
									</BsCol>
								</BsRow>
							</BsCol>
						</BsRow>
						{/* 3 row */}
						<BsRow class="no-gutters mt-3">
							<BsCol class="col-md-12"></BsCol>
						</BsRow>
						{/* 4 row */}
						<BsRow class="no-gutters mt-3">
							<BsCol class="col-md-12"></BsCol>
						</BsRow>
						{/* 5 row */}
						<BsRow class="no-gutters mt-3">
							<BsCol class="col-md-12">
								<BsRow class="gutter_10"></BsRow>
							</BsCol>
						</BsRow>
					</>
				) : (
					<LoadingWidget />
				)}
			</BsContainer>
		</div>
	);
};

export default Dashboard;
