import React, { useContext } from 'react';
import { Switch, Redirect } from 'react-router-dom';
// import { hotjar } from 'react-hotjar';
// import gtag from 'ga-gtag';
// import TagManager from 'react-gtm-module';
import { Context } from './context/Context';
import { RouteWithLayout } from './components';
import {
	Main as MainLayout,
	Minimal as MinimalLayout,
	MinimalNoTopbar as MinimalNoTopbarLayout,
	// MainNoFooter as MainNoFooterLayout,
} from './layouts';

import {
	SignUp as SignUpView,
	SignIn as SignInView,
	RecoveryPassword as RecoveryPasswordView,
	ValidateAccount as ValidateAccountView,
	NotFound as NotFoundView,
	UserAdd as UserAddView,
	UserList as UserListView,
	UserPrivilege as UserPrivilegeView,
	Dashboard as DashboardView,
	DataAdd as DataAddView,
	DataList as DataListView,
	Report as ReportView,
	PuAdd as PuAddView,
	PuList as PuListView,
	HdAdd as HdAddView,
	HdList as HdListView,
	HdEdit as HdEditView,
	CmAdd as ClientAddView,
	CmEdit as ClientEditView,
	CmList as ClientListView,
	LoadExcel as LoadExcelView,
	AmAdd as AmAddView,
	AmList as AmListView,
	AmEdit as AmEditView,
	OmAdd as OmAddView,
	OmList as OmListView,
	OmEdit as OmEditView,
	MeList as MeListView,
	MeExamine as MeExamineView,
	InitialUpload as InitialUploadView,

} from './views';

const Routes = () => {
	const { token } = useContext(Context);

	// const location = useLocation();

	// let siteIdHjStaging = '3215663';
	// let siteIdHj = '3215659';
	// let hjsv = '6';

	// useEffect(() => {
	// 	hotjar.initialize(siteIdHj, hjsv);
	// }, []);

	// useEffect(() => {
	// 	if (hotjar.initialized()) {
	// 		hotjar.identify('USER_ID', { userProperty: userId });
	// 	}
	// }, [userId]);

	// useEffect(() => {
	// if (userId) {
	// const tagManagerArgs = {

	// const tagManagerArgs = {
	// 	gtmId: 'GTM-M2XNPBM',
	// 	dataLayer: {
	// 		userId: userId,
	// 		clientName: 'Garces',
	// 	},
	// };
	// TagManager.initialize(tagManagerArgs);
	// 	}
	// }, [userId]);

	return (
		<Switch>
			{!token && <Redirect from="/" to="/sign-in" exact />}
			<Redirect from="/" to="/dashboard" exact />

			{token && <Redirect from="/sign-in-rol" to="/dashboard" exact />}
			{token && <Redirect from="/sign-in" to="/dashboard" exact />}
			{/* -----DASHBOARD----- */}
			{!token && <Redirect from="/dashboard" to="/sign-in" exact />}

			{/* -----ACCOUNT----- */}
			{/* {!token && <Redirect from="/account" to="/sign-in" exact />} */}
			{!token && <Redirect from="/settings" to="/sign-in" exact />}

			{/* -----PARAMETERS----- */}
			{!token && <Redirect from="/parameters-edit" to="/sign-in" exact />}

			{/* -----PRODUCTIVE_UNIT----- */}
			{!token && <Redirect from="/pu-add" to="/sign-in" exact />}
			{!token && <Redirect from="/pu-list" to="/sign-in" exact />}

			{/* -----CLIENT_MODULE----- */}
			{!token && <Redirect from="/client-add" to="/sign-in" exact />}
			{!token && <Redirect from="/client-edit" to="/sign-in" exact />}
			{!token && <Redirect from="/client-list" to="/sign-in" exact />}

			{/* -----LOAD_EXCEL----- */}
			{!token && <Redirect from="/load-excel" to="/sign-in" exact />}

			{/* -----MASSIVE_EDITION----- */}
			{!token && <Redirect from="/me-list" to="/sign-in" exact />}
			{!token && <Redirect from="/me-examine" to="/sign-in" exact />}

			{/* -----INITIAL_UPLOAD----- */}
			{!token && <Redirect from="/initial-upload" to="/sign-in" exact />}


			{/* -----DATA_HISTORICA----- */}
			{!token && <Redirect from="/hd-add" to="/sign-in" exact />}
			{!token && <Redirect from="/hd-list" to="/sign-in" exact />}
			{!token && <Redirect from="/hd-edit" to="/sign-in" exact />}
			{/* -----AGRO_BUSINESS----- */}
			{!token && <Redirect from="/am-add" to="/sign-in" exact />}
			{!token && <Redirect from="/am-list" to="/sign-in" exact />}
			{!token && <Redirect from="/am-edit" to="/sign-in" exact />}

			{/* -----ORCHARD_MODULE----- */}
			{!token && <Redirect from="/om-add" to="/sign-in" exact />}
			{!token && <Redirect from="/om-list" to="/sign-in" exact />}
			{!token && <Redirect from="/om-edit" to="/sign-in" exact />}

			{/* -----user----- */}
			{!token && <Redirect from="/user-add" to="/sign-in" exact />}
			{!token && <Redirect from="/user-list" to="/sign-in" exact />}
			{!token && <Redirect from="/user-privilege" to="/sign-in" exact />}

			{/* -----data----- */}
			{!token && <Redirect from="/data-add" to="/sign-in" exact />}
			{!token && <Redirect from="/data-list" to="/sign-in" exact />}

			{/* -----report----- */}
			{!token && <Redirect from="/report" to="/sign-in" exact />}

			{/* -----PRODUCTIVE_UNIT----- */}
			<RouteWithLayout
				component={PuAddView}
				exact
				layout={MainLayout}
				path="/pu-add"
			/>

			<RouteWithLayout
				component={PuListView}
				exact
				layout={MainLayout}
				path="/pu-list"
			/>	

			{/* -----DATA_HISTORICA----- */}
			<RouteWithLayout
				component={HdAddView}
				exact
				layout={MainLayout}
				path="/hd-add"
			/>

			<RouteWithLayout
				component={HdListView}
				exact
				layout={MainLayout}
				path="/hd-list"
			/>	
			<RouteWithLayout
				component={HdEditView}	
				exact
				layout={MainLayout}
				path="/hd-edit"
			/>	

			{/* -----CLIENT_MODULE----- */}
			<RouteWithLayout
				component={ClientAddView}
				exact
				layout={MainLayout}
				path="/client-add"
			/>

			<RouteWithLayout
				component={ClientEditView}
				exact
				layout={MainLayout}
				path="/client-edit"
			/>

			<RouteWithLayout
				component={ClientListView}
				exact
				layout={MainLayout}
				path="/client-list"
			/>

			{/* -----LOAD_EXCEL----- */}
			<RouteWithLayout
				component={LoadExcelView}
				exact
				layout={MainLayout}
				path="/load-excel"
				
			/>

			{/* -----MASSIVE_EDITION----- */}

			<RouteWithLayout
				component={MeListView}
				exact
				layout={MainLayout}
				path="/me-list"
			/>

			<RouteWithLayout
				component={MeExamineView}
				exact
				layout={MainLayout}
				path="/me-examine"
			/>
			
			{/* -----INITIAL_UPLOAD----- */}
			<RouteWithLayout
				component={InitialUploadView}
				exact
				layout={MainLayout}
				path="/initial-upload"
			/>
			
			
			{/* -----AGRO_BUSINESS----- */}	
			<RouteWithLayout
				component={AmAddView}
				exact
				layout={MainLayout}	
				path="/am-add"
			/>
			<RouteWithLayout
				component={AmListView}
				exact	
				layout={MainLayout}
				path="/am-list"
			/>	
			<RouteWithLayout
				component={AmEditView}
				exact	
				layout={MainLayout}	
				path="/am-edit"		
			/>	
			{/* -----ORCHARD_MODULE----- */}
			<RouteWithLayout
				component={OmAddView}
				exact
				layout={MainLayout}
				path="/om-add"
			/>

			<RouteWithLayout
				component={OmListView}
				exact
				layout={MainLayout}
				path="/om-list"
			/>	

			<RouteWithLayout
				component={OmEditView}
				exact
				layout={MainLayout}
				path="/om-edit"
			/>

			{/* -----USER----- */}
			<RouteWithLayout
				component={UserAddView}
				exact
				layout={MainLayout}
				path="/user-add"
			/>

			<RouteWithLayout
				component={UserListView}
				exact
				layout={MainLayout}
				path="/user-list"
			/>

			<RouteWithLayout
				component={UserPrivilegeView}
				exact
				layout={MainLayout}
				path="/user-privilege"
			/>
			{/* -----USER----- */}

			<RouteWithLayout
				component={DashboardView}
				exact
				layout={MainLayout}
				path="/dashboard"
			/>

			{/* -----DATA----- */}
			<RouteWithLayout
				component={DataAddView}
				exact
				layout={MainLayout}
				path="/data-add"
			/>

			<RouteWithLayout
				component={DataListView}
				exact
				layout={MainLayout}
				path="/data-list"
			/>

			{/* -----REPORT----- */}
			<RouteWithLayout
				component={ReportView}
				exact
				layout={MainLayout}
				path="/report"
			/>
			{/* <RouteWithLayout
				component={UserAddView}
				exact
				layout={MainLayout}
				path="/user-add"
			/>
			<RouteWithLayout
				component={UserListView}
				exact
				layout={MainLayout}
				path="/user-list"
			/>

			<RouteWithLayout
				component={UserPrivilegeView}
				exact
				layout={MainLayout}
				path="/user-privilege"
			/> */}

			{/* <RouteWithLayout
				component={AccountView}
				exact
				layout={MainLayout}
				path="/account"
			/> */}

			{/* <RouteWithLayout
				component={ParametersView}
				exact
				layout={MainLayout}
				path="/parameters-edit"
			/> */}

			<RouteWithLayout
				component={SignUpView}
				exact
				layout={MinimalLayout}
				path="/sign-up"
			/>
			<RouteWithLayout
				component={SignInView}
				exact
				layout={MinimalNoTopbarLayout}
				path="/sign-in"
			/>

			<RouteWithLayout
				component={RecoveryPasswordView}
				exact
				layout={MinimalNoTopbarLayout}
				path="/recovery-password"
			/>

			<RouteWithLayout
				component={ValidateAccountView}
				exact
				layout={MinimalLayout}
				path="/validate-account"
			/>

			<RouteWithLayout
				component={NotFoundView}
				exact
				layout={MainLayout}
				path="/not-found"
			/>
			<Redirect to="/not-found" />
		</Switch>
	);
};

export default Routes;
